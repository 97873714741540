<template>
  <div id="simple-info-block">
    <v-card class="simple-info-block">
      <v-card-title class="info-title">{{info.title}}</v-card-title>
      <v-card-text>
        <div v-html="info.data"
             style="white-space: pre-wrap;"
             class="info-text"
             @click="startEdit"
             v-if="!isEdit">
        </div>
        <div v-if="isEdit">
          <v-textarea v-model="notes" :auto-grow="true" @blur="stopEdit"></v-textarea>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="action-edit">
          <v-icon @click="openDialog">mdi-square-edit-outline</v-icon>
        </div>
      </v-card-actions>
    </v-card>
    <v-dialog :value="isOpenDialog"
              width="400">

      <v-card style="padding: 16px;border-radius: 16px">
        <v-card-title class="px-0 pt-0 d-flex justify-space-between">
          <span>
            {{info.title}}
          </span>
          <v-icon @click.native="close">mdi-close</v-icon>
        </v-card-title>

        <v-card-text class="py-1">
          <v-textarea v-model="notes" :auto-grow="true" @blur="stopEdit"></v-textarea>
        </v-card-text>

        <v-card-actions class="justify-end">
          <Button dark
                  btn-bg="dark-green"
                  height="45"
                  :styles="{ 'border-radius': '8px' }"
                  @click.native="save">
            Save
          </Button>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import Button from '@/components/elements/Button'
import { mapMutations } from 'vuex'

export default {
  props: {
    info: {
      type: Object
    },
    index: Number
  },
  name: 'simple-info-block',
  components: { Button },
  data: () => ({
    isEdit: false,
    notes: null,
    isOpenDialog: false
  }),
  mounted () {
    this.notes = this.info.data
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    startEdit () {
      this.notes = this.info.data
      this.isEdit = true
    },
    stopEdit () {
      this.isEdit = false
      this.$emit('updateNote', { notes: this.notes, index: this.index, data: this.info })
    },
    openDialog () {
      this.isOpenDialog = true
      this.notes = this.info.data
    },
    close () {
      this.isOpenDialog = false
    },
    save () {
      this.close()
      this.stopEdit()
    }
  }
}
</script>

<style scoped lang="scss">
#simple-info-block {
  width: 90%;
  ::v-deep .v-card {
    .v-card__text {
      padding: 0 16px 14px!important;
    }
  }
  ::v-deep .v-card {
      &__title {
        background: #edf2f7;
        padding: 10px 16px!important;
        justify-content: center!important;
        margin-bottom: 10px!important;
      }
  }
}
.simple-info-block {
  //padding: 7px!important;
  border-radius: 16px!important;
  width: 90%;
  .info-title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .info-text {
    font-weight: 400;
    font-size: 14px;
    color: #718096;
  }
  .action-edit {
    position: relative;
    width: 100%;
    ::v-deep .v-icon {
      right: 0;
      bottom: 0;
      position: absolute;
      cursor: pointer;
      color: #A0AEC0;
    }
  }
}
</style>
