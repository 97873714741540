<template>
  <div class="result">
<!--    <div class="pb-4">
      <Button @click.native="TOGGLE_DIALOG({ name: 'addResult', isOpen: true, title: 'Add result' })" color="primary">Add result</Button>
    </div>-->
    <Table
      v-if="results.headers"
      :headers="headers"
      :actions="actions"
      hideDefaultFooter>

      <template v-slot:body>
        <template v-for="(item, index) in items">
          <tr
            :key="item.id"
            class="custom-result-table__dynamic"
            :class="{ 'expand-row': expandedRows.includes(item.id) }"
          >
            <td class="totalTableSupWidth"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-icon @click="toggleRow(item.id)">mdi-chevron-down</v-icon>
                  <checkbox color="#008C89" :inputValue="item.selected" @change="updateSupplementUse(item, $event)"></checkbox>
                  <span
                    class="mr-2 font-weight-bold supplement-width"
                    :style="{'max-width': 145 - dynamicSupplementNameMaxWidth([item.manuallyEntered, item.fromProtocol, item.fromBlood, item.fromBloodPattern, item.fromBloodMarkerLab, item.fromBloodMarkerOptimal, item.onlyOneBottle]) + 'px!important'}"
                    @click="editResult(item.id)">{{item.supplementName}}</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#A0AEC0" class="mr-1" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{item.supplementName}}</span>
                  </v-tooltip>
                </div>
                <div class="d-flex">
                  <v-tooltip top color="#75DC98" v-if="item.manuallyEntered">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot light-green" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Manually entered</span>
                  </v-tooltip>
                  <v-tooltip top color="#F7936F" v-if="item.fromProtocol">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot light-orange" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Protocol</span>
                  </v-tooltip>
                  <v-tooltip top color="#28605A" v-if="item.fromBlood">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot dark-green" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Blood</span>
                  </v-tooltip>
                  <v-tooltip top color="#499D66" v-if="item.fromBloodPattern">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot green" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Blood pattern</span>
                  </v-tooltip>
                  <v-tooltip top color="#F16063" v-if="item.fromBloodMarkerLab">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot dark-orange" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Blood marker lab</span>
                  </v-tooltip>
                  <v-tooltip top color="#00E0FF" v-if="item.fromBloodMarkerOptimal">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot light-blue" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>Blood marker optimal</span>
                  </v-tooltip>
                  <v-tooltip top color="#537CFF" v-if="item.onlyOneBottle">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="circle-dot blue" v-bind="attrs" v-on="on"></div>
                    </template>
                    <span>One bottle</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td class="pa-0 dosageUponArisingWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosageUponArising ? item.dosageUponArising : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosageUponArising'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosageBreakfastWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosageBreakfast ? item.dosageBreakfast : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosageBreakfast'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosage10amWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosage10am ? item.dosage10am : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosage10am'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosageLunchWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosageLunch ? item.dosageLunch : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosageLunch'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosage3pmWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosage3pm ? item.dosage3pm : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosage3pm'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosageDinnerWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosageDinner ? item.dosageDinner : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosageDinner'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosage7pmWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosage7pm ? item.dosage7pm : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosage7pm'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 dosageBeforeSleepWidth">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.dosageBeforeSleep ? item.dosageBeforeSleep : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosages"
                  :itemRow="item"
                  :propertyKey="'dosageBeforeSleep'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="pa-0 daysPerWeekWidth" :style="item.customDayPerWeek ? 'background: #f9ffd1' : ''">
              <v-menu
                :close-on-content-click="true"
                offset-x
                max-width="50px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-center width100 height100"
                       v-bind="attrs"
                       v-on="on">
                    <span class="font-weight-bold">{{item.daysPerWeek ? item.daysPerWeek : ''}}</span>
                  </div>
                </template>
                <dosage-control
                  :dosages="supplementDosagesDaysPerWeek"
                  :itemRow="item"
                  :propertyKey="'daysPerWeek'"
                  @update="updateSupplementDosage"
                />
              </v-menu>
            </td>
            <td class="patientNotesWidth" :style="item.patientNotes || item.patientNotesSpanish || item.clinicianNotes ? 'background: #f4f49b' : ''">
              <div class="d-flex align-center justify-center">
                <v-tooltip right v-if="item.patientNotes || item.patientNotesSpanish || item.clinicianNotes">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="#008C89"
                      v-on="on"
                      @click.native="editPatientNotes(item)"
                      class="mx-2">
                      mdi-square-edit-outline
                    </v-icon>
                  </template>
                  <div>
                    <div v-show="item.patientNotes">English notes: {{item.patientNotes}}</div>
                    <div v-show="item.patientNotesSpanish">Spanish notes: {{item.patientNotesSpanish}}</div>
                    <div v-show="item.clinicianNotes">clinician notes: {{item.clinicianNotes}}</div>
                  </div>
                </v-tooltip>
                <v-icon
                  v-else
                  color="#A0AEC0"
                  @click.native="editPatientNotes(item)"
                  class="mx-2">
                  mdi-square-edit-outline
                </v-icon>
              </div>
            </td>
            <td class="totalScoreWidth">
              <div class="d-flex align-center justify-center">
                <span class="font-weight-bold">{{item.totalScore ? item.totalScore : ''}}</span>
              </div>
            </td>
            <td class="daysSupplyWidth">
              <div class="d-flex align-center justify-center">
                <span class="font-weight-bold">{{item.daysSupply ? item.daysSupply : ''}}</span>
              </div>
            </td>
            <td class="bottlesWidth">
              <div class="d-flex align-center justify-center">
                <span class="font-weight-bold">{{item.bottles ? item.bottles : ''}}</span>
              </div>
            </td>
            <td class="totalCostWidth">
              <div class="d-flex align-center justify-center">
                <span class="font-weight-bold">{{item.totalCost ? item.totalCost.toFixed(2) : ''}}$</span>
              </div>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <actions :items="actions"
                         @action="runAction"
                         :item="item"
                /><!-- v-on="actionListener"-->
              </div>
            </td>
          </tr>
          <td colspan="100%"
              class="product-supplement"
              :key="index + item.id"
              v-if="expandedRows.includes(item.id)">
            <v-card elevation="2">
              <Table hideDefaultFooter>
                <template v-slot:body>
                  <tr :key="index + item.id" class="sub-row">
                    <th style="width: 60%; text-align: left; padding-left: 20px">
                      <span class="sub-row__title">Supplement Bottles and Prices</span>
                    </th>
                    <th style="width: 90px">
                      <span class="sub-row__title">Product</span>
                    </th>
                    <th style="width: 37px">
                      <span class="sub-row__title">Size</span>
                    </th>
                    <th style="width: 100px">
                      <span class="sub-row__title">Unit Price</span>
                    </th>
                    <th style="width: 49px">
                      <span class="sub-row__title">Q-TY</span>
                    </th>
                    <th style="padding: 0 5px;">
                      <span class="sub-row__title">Total Price</span>
                    </th>
                    <th></th>
                  </tr>
                  <tr :key="index" class="sub-row">
                    <td style="display: flex;flex-direction: column;border-bottom: unset;padding-top: 10px;">
                      <span class="font-weight-bold">{{item.supplementName}}</span>
                      <div class="product-bottles">
                        <div class="main-row">
                          <div class="bottle"
                               v-for="bottle in item.supplementProducts.$values"
                               :class="{ 'discontinued': bottle.discontinued }"
                               :key="bottle.id"
                               :id="`bottle-${bottle.id}`"
                               @click="createNewSupplementProduct(item.id, bottle)"
                          >
                            <div class="btn-bottle">
                              <v-badge
                                :color="bottle.discontinued ? '#A0AEC0' : '#008C89'"
                                :content="getQuantityBySupplementProductId(item.resultProducts.$values, bottle.id)"
                                overlap
                              >
                                <v-icon :class="{ 'discontinued': bottle.discontinued }">mdi-bottle-tonic-outline</v-icon>
                              </v-badge>
                            </div>
                            <div class="vitamins"></div>
                            <div class="bottle-info" :class="{ 'discontinued': bottle.discontinued }">
                              <p>{{bottle.productNumber}}</p>
                              <p style="font-size: 17px">{{bottle.dosesPerBottle}}</p>
                            </div>
                            <div class="bottle-plus" :class="{ 'discontinued': bottle.discontinued }">
                              <v-icon>mdi-plus</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td colspan="6">
                      <Table hideDefaultFooter>
                        <template v-slot:body>
                          <tr class="inner-product-bottles-row"
                              v-for="product in item.resultProducts.$values"
                              :key="product.id"
                          >
                            <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'" style="width: 89px">
                              <span class="font-weight-bold">{{product.number}}</span>
                              <!--<v-icon style="margin-top: -5px">mdi-chevron-down</v-icon>-->
                            </td>
                            <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'" style="width: 37px">
                              <span class="font-weight-bold">{{product.doses}}</span>
                            </td>
                            <td style="padding: 0 5px;width: 100px" :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'">
                              <span class="font-weight-bold">{{product.price}}$</span>
                            </td>
                            <td :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'"
                                style="width: 49px" :class="{ 'adding-in-process': isStartCreateNewSupProduct === product.supplementProductId }">
                              <div class="d-flex align-center justify-center">
                                <span class="font-weight-bold">{{product.quantity}}</span>
                                <div class="d-flex flex-column">
                                  <v-icon @click="updateQuantityBottles(1, product, item.id)">mdi-chevron-up</v-icon>
                                  <v-icon v-show="product.quantity > 1" @click="updateQuantityBottles(-1, product, item.id)">mdi-chevron-down</v-icon>
                                </div>
                              </div>
                            </td>
                            <td style="padding: 0 5px;" :style="item.resultProducts.$values.length > 1 ? '': 'height: 111px'">
                              <div class="remove-supplement-product">
                                <span class="font-weight-bold">{{product.amount}}$</span>
                                <v-icon v-if="item.resultProducts.$values.length > 1" @click="deleteSupplementProduct(item.id, product.id)">mdi-close</v-icon>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </Table>
                    </td>
                  </tr>
                  <!--<tr :key="item.id.substr(0, item.id.length - 5)" class="sub-row" v-if="newSupplementProductRowID === item.id">
                    <td>
                      <span class="font-weight-bold"></span>
                    </td>
                    <td>
                      <Select outlined
                              itemText="value"
                              itemValue="id"
                              :items="productsSupplement"
                              :value="productsSupplement[0]"
                              @change="product.id=$event.id"
                      />
                    </td>
                    <td>
                      <Input outlined
                             tabindex="1"
                             background-color="#EDF2F7"
                             placeholder="Size"
                             @input="size=$event"
                      />
                    </td>
                    <td>
                      <Input outlined
                             tabindex="1"
                             background-color="#EDF2F7"
                             placeholder="Unit price"
                             @input="unitPrice=$event"
                      />
                    </td>
                    <td>
                      <Input outlined
                             tabindex="1"
                             background-color="#EDF2F7"
                             placeholder="Quantity"
                             @input="quantity=$event"
                      />
                    </td>
                    <td>
                      <Input outlined
                             tabindex="1"
                             background-color="#EDF2F7"
                             placeholder="Amount"
                             @input="amount=$event"
                      />
                    </td>
                    <td>
                      <v-icon style="color: #4faa6e">mdi-content-save-outline</v-icon>
                    </td>
                  </tr>-->
                  <!--Variant 2-->
                  <!--            <tr :key="index" class="custom-result-table__dynamic">
                                <td colspan="10">
                                  <span>{{item.supplementName}}</span>
                                </td>
                                <td colspan="5" style="padding: 0">
                                  <table>
                                    <tbody>
                                      <tr class="custom-result-table__dynamic">
                                        <th>Number</th>
                                        <th>Size</th>
                                        <th>Cost</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                      </tr>
                                      <tr class="custom-result-table__dynamic">
                                        <td>14120</td>
                                        <td>150</td>
                                        <td>10.00$</td>
                                        <td>2</td>
                                        <td>20.00</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td></td>
                              </tr>-->
                </template>
              </Table>
            </v-card>
          </td>
        </template>
        <tr class="custom-result-table__no-dynamic">
          <td>
            <span class="font-weight-black" style="color: #8492A6;font-size: 14px;font-weight: bold">
              Total
            </span>
          </td>
          <td v-for="(item, i) in headers.filter(el => el.value !== 'supplementName')" :key="i" class="total-row"><!--v-show="item.type === 'Number'"-->
            <span class="font-weight-bold" v-if="item.value === 'daysSupply'">
              {{results.record[item.value] !== 0 ? results.record[item.value] : ''}}
              <!--              {{results.record['total'+_.upperFirst(item.value)]}}-->
            </span>
            <span class="font-weight-bold" v-if="item.value === 'totalCost'">
              {{results.record['totalPrice'] !== 0 ? results.record['totalPrice'] + '$' : ''}}
            </span>
            <span class="font-weight-bold" v-if="item.value === 'daysPerWeek'"></span>
            <span class="font-weight-bold" v-if="item.value === 'patientNotes'"></span>
            <span class="font-weight-bold" v-if="item.value === 'totalScore'"></span>
            <span class="font-weight-bold" v-else>
              {{results.record['total'+_.upperFirst(item.value)] !== 0 ? results.record['total'+_.upperFirst(item.value)] : ''}}
              <!--{{'total'+_.upperFirst(item.value)}}-->
            </span>
          </td>
        </tr>
      </template>

    </Table>
<!--    <Table
      class="total-table"
      hideDefaultFooter>
      <template v-slot:body>
        <tr class="custom-result-table__no-dynamic">
          <td :style="`width: ${totalTableSupWidth + 1}px`" style="padding: 0 4px;">
            <span class="font-weight-black" style="color: #8492A6;font-size: 12px;">
              Total
            </span>
          </td>
          <td v-for="(item, i) in headers.filter(el => el.value !== 'supplementName')" :key="i" class="total-row"
              :style="`width: ${$data[item.value + 'Width']}px`">&lt;!&ndash;v-show="item.type === 'Number'"&ndash;&gt;
            <span class="font-weight-bold" v-if="item.value === 'daysSupply'">
              {{results.record[item.value]}}
&lt;!&ndash;              {{results.record['total'+_.upperFirst(item.value)]}}&ndash;&gt;
            </span>
            <span class="font-weight-bold" v-if="item.value === 'totalCost'">
              {{results.record['totalPrice']}}
            </span>
            <span class="font-weight-bold" v-if="item.value === 'daysPerWeek'">
              -
            </span>
            <span class="font-weight-bold" v-if="item.value === 'patientNotes'">
              -
            </span>
            <span class="font-weight-bold" v-if="item.value === 'totalScore'">
              -
            </span>
            <span class="font-weight-bold" v-else>
              {{results.record['total'+_.upperFirst(item.value)]}}
              &lt;!&ndash;{{'total'+_.upperFirst(item.value)}}&ndash;&gt;
            </span>
          </td>
        </tr>
      </template>
    </Table>-->
    <add-result v-if="dialogs.editResult.isOpen || dialogs.addResult.isOpen" />
    <confirm v-if="dialogs.confirm.isOpen" />
    <notes-dialog v-if="dialogs.notesDialog.isOpen" @showPopup="showPopup" />
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
// import Button from '@/components/elements/Button'
import Table from '@/components/elements/Table'
import checkbox from '@/components/elements/checkbox'

import addResult from '@/components/layouts/dialogs/add-result'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
// import SpinInput from '@/components/elements/spin-input'
import actions from '@/components/layouts/actions'
import DosageControl from '@/components/elements/dosage-control'
// import Select from '@/components/elements/Select'
// import Input from '@/components/elements/Input'
import Number from '@/mixins/Number'
import NotesDialog from '@/components/layouts/dialogs/notes-dialog'

/* const IS_SLOTABLE = [
  // 'selected',
  'actions',
  'bottles',
  'clinicianNotes',
  'daysPerWeek',
  'daysSupply',
  'dosage3pm',
  'dosage7pm',
  'dosage10am',
  'dosageBeforeSleep',
  'dosageBreakfast',
  'dosageDinner',
  'dosageLunch',
  'dosageUponArising',
  'fromBlood',
  'fromBloodMarkerLab',
  'fromBloodMarkerOptimal',
  'fromBloodPattern',
  'fromProtocol',
  'manuallyEntered',
  'onlyOneBottle',
  'patientNotes',
  'patientNotesSpanish',
  'selected',
  'supplementId',
  'supplementName',
  'surveyGroupId',
  'totalCost',
  'totalScore',
  'warnings'
] */
const IS_CUSTOM_WIDTH = [
  'dosageUponArising',
  'dosageBreakfast',
  'dosage10am',
  'dosageLunch',
  'dosage3pm',
  'dosageDinner',
  'dosage7pm',
  'dosageBeforeSleep',
  'daysPerWeek',
  'patientNotes',
  'totalScore',
  'daysSupply',
  'bottles',
  'totalCost',
  'actions'
]

export default {
  name: 'results',
  mixins: [Number],
  components: { NotesDialog, Table, checkbox, addResult, confirm, actions, DosageControl }, // , Select, Input, SpinInput, Button,
  data: () => ({
    supplementDosages: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 0,
        text: 'x'
      }
    ],
    supplementDosagesDaysPerWeek: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 6,
        text: '6'
      },
      {
        id: 7,
        active: false,
        value: 7,
        text: '7'
      }
    ],
    expandedRows: [],
    newSupplementProductRowID: null,
    product: null,
    productsSupplement: [
      {
        id: 1,
        value: 'Product 1'
      },
      {
        id: 2,
        value: 'Product 2'
      },
      {
        id: 3,
        value: 'Product 3'
      }
    ],
    totalTableSupWidth: 0,
    dosageUponArisingWidth: 0,
    dosageBreakfastWidth: 0,
    dosage10amWidth: 0,
    dosageLunchWidth: 0,
    dosage3pmWidth: 0,
    dosageDinnerWidth: 0,
    dosage7pmWidth: 0,
    dosageBeforeSleepWidth: 0,
    daysPerWeekWidth: 0,
    patientNotesWidth: 0,
    totalScoreWidth: 0,
    daysSupplyWidth: 0,
    bottlesWidth: 0,
    totalCostWidth: 0,
    isStartCreateNewSupProduct: false,
    alertIsOpen: false,
    popupMessage: null
  }),
  methods: {
    ...mapActions('results', ['getResults', 'updateResult', 'createSupplementProduct', 'removeSupplementProduct', 'updateSupplementProduct']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('results', ['UPDATE_DATA', 'UPDATE_STORE_DATA']),
    editResult (id) {
      this.TOGGLE_DIALOG({ id, name: 'editResult', isOpen: true, title: 'Edit supplement' })
    },
    async deleteResult (id) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        action: 'results/deleteResult',
        afterAction: {
          id: this.$route.params.id,
          name: 'results/getResults'
        }
      })
    },
    editPatientNotes (item) {
      this.TOGGLE_DIALOG({ name: 'notesDialog', title: 'Edit notes', isOpen: true, data: item, action: 'ResultSupplements/ClinicianNotes' })
    },
    showPopup ({ res, supplementName }) {
      this.alertIsOpen = true
      this.popupMessage = `Notes for ${supplementName} was updated successfully`
      this.TOGGLE_DIALOG({ name: 'notesDialog', isOpen: false })
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 1300)
    },
    async updateSupplementUse (item, val) {
      const result = await this.updateResult({ id: item.id, form: { ...item, selected: val } })
      this.updateDataStore(result, 'selected')
    },
    async updateSupplementDosage ({ data, keyProp }) {
      const result = await this.updateResult(data)
      this.updateDataStore(result, keyProp)
    },
    updateDataStore (result, key) {
      const payload = {
        dailyPrice: result.record.dailyPrice,
        daysSupply: result.record.daysSupply,
        monthlyPrice: result.record.monthlyPrice,
        totalBottles: result.record.totalBottles,
        totalDosage: result.record.totalDosage,
        totalDosage10am: result.record.totalDosage10am,
        totalDosage3pm: result.record.totalDosage3pm,
        totalDosage7pm: result.record.totalDosage7pm,
        totalDosageBeforeSleep: result.record.totalDosageBeforeSleep,
        totalDosageBreakfast: result.record.totalDosageBreakfast,
        totalDosageDinner: result.record.totalDosageDinner,
        totalDosageLunch: result.record.totalDosageLunch,
        totalDosageUponArising: result.record.totalDosageUponArising,
        totalPrice: result.record.totalPrice,
        weeklyPrice: result.record.weeklyPrice
      }
      this.UPDATE_DATA(payload)
      this.items.map(item => {
        if (item.id === result.record.resultSupplements.$values[0].id) {
          return Object.assign(item, { [key]: result.record.resultSupplements.$values[0][key] })
        }
      })
    },
    /* actionListener: function () {
      console.log('actionListener')
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          action: function (event) {
            vm.$emit('click:action', { action: event.params, id: event.id })
          }
        }
      )
    }, */
    runAction (val) {
      val.params.action(val.id)
      // this.editResult(val.id)
    },
    toggleRow (id) {
      const index = this.expandedRows.indexOf(id)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = []
        this.expandedRows.push(id)
      }
    },
    dynamicSupplementNameMaxWidth (array) {
      let countPixel = 0
      for (let i = 0; i < array.length; i++) {
        countPixel += array[i] ? 13 : 0
      }
      return countPixel
    },
    addNewSupplementProduct (id) {
      this.newSupplementProductRowID = id
    },
    async createNewSupplementProduct (resultSupplementId, data) {
      this.isStartCreateNewSupProduct = data.id
      const bottle = document.getElementById(`bottle-${data.id}`)
      const vitamin = bottle.querySelector('.vitamins')
      if (!vitamin.classList.contains('active-animation')) {
        const obj = {
          resultSupplementId: resultSupplementId,
          supplementProductId: data.id,
          numberOfBottles: 1, // data.quantity,
          price: data.listPrice
        }
        const res = await this.createSupplementProduct(obj)
        this.UPDATE_STORE_DATA(res)
        vitamin.classList.add('active-animation')
        const self = this
        setTimeout(function () {
          vitamin.classList.remove('active-animation')
          self.isStartCreateNewSupProduct = false
        }, 1000)
      }
    },
    async deleteSupplementProduct (resultSupplementId, supplementProductId) {
      const res = await this.removeSupplementProduct({ resultSupplementId, supplementProductId })
      this.UPDATE_STORE_DATA(res)
    },
    async updateQuantityBottles (val, product, resultSupplementId) {
      const res = product.quantity + val
      if (res >= 1) {
        const response = await this.updateSupplementProduct({ resultSupplementId, supplementProductId: product.id, numberOfBottles: res })
        this.UPDATE_STORE_DATA(response)
      }
    },
    getQuantityBySupplementProductId (resultProducts, productId) {
      const product = resultProducts.find(item => item.supplementProductId === productId)
      return product && product.quantity ? product.quantity : '0'
    }
  },
  created () {
    const { id } = this.$route.params
    this.getResults(id)
  },
  computed: {
    ...mapState('results', {
      headers (state) {
        const headers = Object.assign([], state?.results?.headers?.$values)
        headers.push({ text: 'mdi-cog', value: 'actions', isIcon: true, sortable: false })
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id', 'width']),
          text: el.value !== 'actions' ? el.text.toUpperCase().replace(/ /gi, '<br/>') : el.text,
          isReplacingSpace: el.value !== 'actions' ? 1 : 0,
          // isSlotable: IS_SLOTABLE.includes(el.value),
          width: (IS_CUSTOM_WIDTH.includes(el.value)) ? 40 + 'px' : 'auto'
        })).filter(el => {
          return el.text !== 'USE'
        })
      },
      items (state) {
        const updatedValues = state?.results?.record?.resultSupplements?.$values.map(item => {
          const newItem = Object.assign(item, { supplementWidth: '200px' }) // temporary code
          return newItem
        })
        const items = Object.assign([], updatedValues)
        return items
      }
    }),
    actions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editResult },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteResult }
      ]
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    results () {
      return this.$store.state?.results?.results
    }
  },
  watch: {
    items (val) {
      if (val && val.length > 0) {
        this.$nextTick(() => {
          this.totalTableSupWidth = document.querySelector('.totalTableSupWidth').getBoundingClientRect().width
          this.dosageUponArisingWidth = document.querySelector('.dosageUponArisingWidth').getBoundingClientRect().width
          this.dosageBreakfastWidth = document.querySelector('.dosageBreakfastWidth').getBoundingClientRect().width
          this.dosage10amWidth = document.querySelector('.dosage10amWidth').getBoundingClientRect().width
          this.dosageLunchWidth = document.querySelector('.dosageLunchWidth').getBoundingClientRect().width
          this.dosage3pmWidth = document.querySelector('.dosage3pmWidth').getBoundingClientRect().width
          this.dosageDinnerWidth = document.querySelector('.dosageDinnerWidth').getBoundingClientRect().width
          this.dosage7pmWidth = document.querySelector('.dosage7pmWidth').getBoundingClientRect().width
          this.dosageBeforeSleepWidth = document.querySelector('.dosageBeforeSleepWidth').getBoundingClientRect().width
          this.daysPerWeekWidth = document.querySelector('.daysPerWeekWidth').getBoundingClientRect().width
          this.patientNotesWidth = document.querySelector('.patientNotesWidth').getBoundingClientRect().width
          this.totalScoreWidth = document.querySelector('.totalScoreWidth').getBoundingClientRect().width
          this.daysSupplyWidth = document.querySelector('.daysSupplyWidth').getBoundingClientRect().width
          this.bottlesWidth = document.querySelector('.bottlesWidth').getBoundingClientRect().width
          this.totalCostWidth = document.querySelector('.totalCostWidth').getBoundingClientRect().width
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .result {
    .table {
      padding: 16px 16px 0px!important; // 16px!important;
      ::v-deep .v-menu {
        .v-menu__content {
          z-index: 100!important;
        }
      }
    }
    .table.total-table {
      background: #ededed; // #FAFAFB
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding-left: 16px!important;
      padding-right: 16px!important;
      padding-top: 0!important;
    }
    .v-icon {
      cursor: pointer;
    }
    .table {
      ::v-deep .v-data-table .v-data-table__wrapper .v-data-table-header th {
        padding: 0 4px!important;
      }
      ::v-deep .v-data-table {
        background: transparent!important;
      }
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
  }
  .circle-dot {
    height: 8px;
    width: 8px;
    background-color: grey;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
  }
  .circle-dot.light-green {
    background-color: #75DC98!important;
  }
  .circle-dot.light-orange {
    background-color: #F7936F!important;
  }
  .circle-dot:first-child {
    margin-left: 10px;
  }
  .circle-dot:last-child {
    margin: 0 0 0 2px;
  }
  .circle-dot.dark-green {
    background-color: #28605A!important;
  }
  .circle-dot.green {
    background-color: #499D66!important;
  }
  .circle-dot.dark-orange {
    background-color: #F16063!important;
  }
  .circle-dot.light-blue {
    background-color: #00E0FF!important;
  }
  .circle-dot.blue {
    background-color: #537CFF!important;
  }
  .total-row {
    text-align: center;
  }
  .supplement-width {
    //max-width: 425px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
  ::v-deep .v-tooltip__content {
    padding: 3px 3px;
  }
  .dinner-tooltip {
    padding: 3px!important;
  }
  ::v-deep table .v-data-table-header th + th { border-left:thin solid #0000001f !important; }
  ::v-deep table td + td:not(.total-row) { border-left:thin solid #0000001f !important; }
  .days-per-week {
    ::v-deep .v-text-field__details {
      display: none !important;
    }
  }
  .custom-result-table {
    &__dynamic {
      border-bottom:thin solid #0000001f;
      td {
        padding: 0 4px; // 0 16px;
        border-bottom:thin solid #0000001f;
        height: 52px;
        .width100 {
          width: 100% !important;
        }
        .height100 {
          height: 100% !important;
        }
        div[aria-expanded=true] {
          background-color: #c3e5c399;
        }
        div[aria-expanded=false] {
          background-color: unset;
        }
      }
    }
    &__dynamic:last-child {
      td {
        border-bottom: unset;
      }
    }
    &__dynamic.expand-row {
      background-color: #e0fff4;
      .table {
        padding: 0px!important;
      }
    }
    &__no-dynamic {
      border-bottom:thin solid #0000001f;
      td {
        //border-bottom:thin solid #0000001f;
        height: 52px;
        border-right: thin solid transparent;
      }
      td:last-child {
        border-right: unset;
      }
      //td:last-child {
      //  border-bottom: unset;
      //}
    }
  }
  .product-supplement {
    padding: 10px 10px;
    background: #EDF2F7;
    border-bottom: unset;
    ::v-deep .v-card {
      .table {
        padding: 0px!important;
        .v-data-table .v-data-table__wrapper {
          table {
            .sub-row {
              .table {
                padding: 0!important;
              }
              th {
                padding-top: 15px;
                padding-bottom: 15px;
              }
              tr:last-child {
                td {
                  border-bottom: unset;
                }
              }
              td {
                //border-bottom: unset;
              }
            }
          }
        }
      }
    }
    .product-bottles {
      .main-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        column-gap: 1rem;
        row-gap: 1rem;
        padding: 5px 10px;
        align-items: center;
        .bottle {
          position: relative;
          display: flex;
          width: 139px;
          align-items: center;
          column-gap: 1rem;
          cursor: pointer;
          background: #008c8933;
          border-radius: 10px;
          padding: 14px 3px;
          justify-content: center;
          .btn-bottle {
            //width: 50px;
            //height: 50px;
            //background: #56c8472e;
            //border-radius: 10px;
            .v-icon {
              margin-top: 10px;
              color: #008C89;
            }
            .v-icon.discontinued {
              margin-top: 10px;
              color: #A0AEC0;
            }
          }
          .bottle-info {
            p {
              margin: 0;
            }
            font-weight: bold;
            color: #008C89;
          }
          .bottle-info.discontinued {
            color: #A0AEC0;
          }
          .bottle-plus {
            ::v-deep .v-icon {
              color: #008C89;
            }
          }
          .bottle-plus.discontinued {
            ::v-deep .v-icon {
              color: #A0AEC0;
            }
          }
        }
        .bottle.discontinued {
          background: #CBD5E0;
          color: #A0AEC0;
        }
      }
    }
    .inner-product-bottles-row {}
    .remove-supplement-product {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
  .sub-row {
    &__title {
      font-size: 10px;
      font-weight: 600;
      color: #8492A6;
      white-space: nowrap;
      text-transform: uppercase;
    }
    th:first-child {
      border-left: unset;
      //border-bottom: unset;
    }
    th {
      border-bottom: 1px solid #0000001f;
      border-left: 1px solid #0000001f;
    }
    th:last-child {
      border-bottom: unset;
      border-left: unset;
    }
    tr:last-child {
      td {
        border-bottom: unset;
      }
    }
    td {
      border-bottom: 1px solid #0000001f;
      text-align: center;
    }
  }
  .adding-in-process {
    background-color: #e0fff4;
  }
  .vitamins {
    width: 30px;
    height: 30px;
    position: absolute;
    visibility: hidden;
    background-image: url("../../../assets/icons/vitamins.png");
    background-size: 25px;
    bottom: 20px;
  }
  .vitamins.active-animation {
    visibility: visible;
    animation: moveXandSpin 1s none;
  }
  @keyframes moveXandSpin {
    from {
      transform: translateX(0px) rotate(0);
    }
    to {
      transform: translateX(570px) rotate(720deg);
    }
  }
</style>
