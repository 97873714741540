<template>
  <div class="complete-visit">
    <Dialog width="600">
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <div style="padding: 10px 24px 0">
          Please confirm Completing the Evaluation.
          Once Evaluation will be completed you will be no longer able to edit the Results.
          Patient will receive email with Patient Schedule and Patient Receipt reports.
          If Patient is registered on the Portal he will get notification and will be available to download the proper Reports.
        </div>
        <v-form ref="form" v-model="valid" lazy-validation id="addVisit" class="px-6">
          <v-row class="mt-0">
            <v-col class="pb-0 pt-0">
              <v-checkbox
                color="success"
                v-model="form.addVisit"
                label="Add Next Evaluation Date"/>
            </v-col>
            <v-col class="pb-0">
              <datepicker @date:update="form.date = $event"
                          :value="$moment(form.date).format('MM/DD/YYYY')"
                          :rules="notEmtpy"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="">
              <div class="label">Notes</div>
              <Textarea outlined
                        counter
                        color="#fff"
                        radius="8"
                        height="100"
                        background-color="#EDF2F7"
                        placeholder="Notes"
                        :value="form.notes"
                        @input:text-area="form.notes=$event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex justify-start">
                <Button
                  dark
                  width="150"
                  color="red"
                  @click.native="closeModal"
                  :styles="{ 'border-radius': '8px' }"
                >
                  <span style="color: #FFFFFF">Cancel</span>
                </Button>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-end">
                <Button
                  dark
                  width="150"
                  color="#4FAA6E"
                  @click.native="complete"
                  :styles="{ 'border-radius': '8px' }"
                >
                  <span style="color: #FFFFFF">Complete</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import datepicker from '@/components/elements/datepicker'
import Textarea from '@/components/elements/Textarea'
import Validation from '@/mixins/Validation'

export default {
  name: 'complete-visit',
  mixins: [Validation],
  props: {
    query: {
      type: Object
    }
  },
  components: {
    Dialog,
    Button,
    datepicker,
    // timepicker,
    Textarea
  },
  data: () => ({
    form: {
      addVisit: true,
      date: new Date(new Date().setDate(new Date().getDate() + 60)),
      notes: ''
    }
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    complete () {
      this.form.date = new Date(this.form.date)
      this.$emit('complete', this.form)
    },
    closeModal () {
      this.TOGGLE_DIALOG({ name: 'completeVisit', isOpen: false })
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  })
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  font-weight: 500;
  color: #425466;
}
.complete-visit {
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        background: #edf2f7;
        padding: 25px 30px 17px 46px !important;
        justify-content: center!important;
        position: relative;
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
}
</style>
