<template>
  <v-app>
    <router-view />
<!--    <alert v-if="isAlert" v-bind="messages" />-->
  </v-app>
</template>

<script>
/* import { mapState } from 'vuex' */
/* import alert from '@/components/layouts/alert' */
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    /* alert */
  },
  computed: {
    ...mapState({
      trialSubscriptionDialog: state => state.dialogs.trialSubscriptionDialog
    })
  }
  /* computed: {
    ...mapState({
      messages: state => state.messages
    }),
    isAlert () {
      return !this._.isEmpty(this.messages)
    }
  } */
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .v-application {
    &--wrap {
      .v-toolbar {
        box-shadow: unset !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        .profile {
          &__logout {
            cursor: pointer;
          }
        }
      }
    }
  }

  .grecaptcha-badge { visibility: hidden; }
</style>
