<template>
  <div>
<!--    <v-app-bar app height="95" :color="appColor">
      <profile />
    </v-app-bar>-->
    <app-bar-desktop class="desktop-version"
                     :leftMenuIsOpen="drawerState"
                     @toggleMenu="drawerMenu=$event"
                     :value="drawerMenu"
    />

    <drawer :items="menu" @toggleDrawer="checkToggleDrawerState"/>

    <v-navigation-drawer
      v-model="drawerMenu"
      app
      right
      temporary
      style="top: 57px"
      height
    ><!--absolute height :style="{'top':'50px'}"-->
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title @click="gotTo('/settings')">Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main app :style="styles">
      <router-view />
    </v-main>

<!--    <v-footer app>Footer app</v-footer>-->
  </div>
</template>

<script>
import fakeData from '@/assets/fakeData'

import drawer from '@/components/layouts/drawer'
// import profile from '@/components/layouts/profile'
import AppBarDesktop from '@/components/layouts/App-bar-desktop'
import { mapActions } from 'vuex'

export default {
  name: 'Index',
  components: { drawer, AppBarDesktop }, // profile
  data: () => ({
    ...fakeData,
    drawerState: true,
    drawerMenu: false
  }),
  mounted () {
    this.getCustomers({
      PageIndex: 1,
      PageSize: 10,
      order: [{ column: 'name', direction: 'desc' }]
    })
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('customers', ['getCustomers']),
    checkToggleDrawerState (value) {
      this.drawerState = value
    },
    gotTo (path) {
      this.$router.push({ path })
    }
  },
  computed: {
    appColor () {
      return (this.$route?.params?.id) ? '#F7FAFC' : '#FFF'
    },
    styles () {
      return {
        padding: this.drawerState ? '57px 0px 36px 180px' : '57px 0px 36px 56px' // 95 0 36 180/56
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/fonts.scss';

  .v-application {
    &--wrap {
      header {
        border: none !important;
      }
      .v-main {
        height: 100vh;
      }
      .v-toolbar {
        box-shadow: unset !important;
        .profile {
          &__title {
            font-weight: 600;
            font-size: 28px;
            color: #27272E;
          }
          &__logout {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
