<template>
  <div class="confirm-dialog">
    <Dialog width="450">
      <template slot="title">
        <div class="image">
          <Img :src="getImgWithExt(['icons', 'confirm-logo-title', 'svg'])" width="50" />
        </div>
        <div class="title">
          {{confirmState.title}}
        </div>
      </template>

      <template slot="body">
        <div class="description" v-html="confirmState.description">
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            btn-bg="dark-green"
            @click.native="confirm"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Confirm</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'

export default {
  name: 'confirm-dialog',
  props: {
    query: {
      type: Object
    }
  },
  components: { Dialog, Button },
  mixins: [Image],
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async confirm () {
      const { id, action, afterAction } = this.confirmState
      await this.$store.dispatch(action, id)
      if (this._.isObject(afterAction)) {
        if (this.query) {
          await this.$store.dispatch(afterAction.name, this.query)
        } else {
          await this.$store.dispatch(afterAction.name, afterAction.id)
        }
      }
      this.TOGGLE_DIALOG({ name: 'confirm', isOpen: false })
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'confirm', isOpen: false })
    }
  },
  computed: {
    ...mapState({
      confirmState: state => state.dialogs.confirm
    })
  }
}
</script>

<style lang="scss" scoped>
  .w-100 {
    width: 100%;
  }
  .confirm-dialog {
    //::v-deep {
    //  .v-card__title {
    //    position: relative!important;
    //    .v-icon {
    //      position: absolute!important;
    //      right: 15px;
    //      top: 0;
    //    }
    //  }
    //}
    //.title {
    //  width: 100%;
    //  text-align: center;
    //}
    //.description {
    //  padding: 0 16px;
    //}
    ::v-deep .v-dialog__container {
      .v-card {
        &__title {
          //background: #edf2f7;
          padding: 25px 35px 17px 26px !important;
          justify-content: center!important;
          position: relative;
          .image {
            width: 100%;
            text-align: center;
          }
          .title {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: #27272E;
          }
          .v-icon {
            display: none;
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__text {
          .description {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #425466;
          }
          .v-input {
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
