<template>
  <div class="updates">
    UPDATES PAGE
  </div>
</template>

<script>

export default {
  name: 'migrations'
}
</script>

<style lang="scss" scoped>
.updates {
  padding: 16px!important;
}
</style>
