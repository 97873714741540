<template>
  <div class="btn-group">
    <v-row class="px-2 pb-2 ma-0">
      <v-btn-toggle v-model="toggle" mandatory group>
        <v-btn v-for="(item, key) in btns" :key="key" small :ripple="false">{{item.name}}</v-btn>
      </v-btn-toggle>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'btn-group',
  props: {
    btns: {
      type: Array
    }
  },
  data: () => ({
    toggle: +localStorage.getItem('quickFilterIndex') || undefined
  }),
  watch: {
    toggle (val) {
      localStorage.setItem('quickFilterIndex', val)
      this.$emit('update:btn', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-group {
    // ..
  }
</style>
