<template>
  <div class="add-supplement-dialog">
    <Dialog width="1000" scrollable>
      <template slot="title">
        <div>{{dialog.title}}</div>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addSupplement" class="py-2 pl-3 pr-3">
          <v-row>
            <v-col cols="6" :style="{'margin-top': componentFrom === 'tab-supplement' ? '10px' : '14px;'}">
<!--              <v-row class="d-flex align-center" v-if="componentFrom === 'protocol'">
                <v-col cols="12">
                  <div class="d-flex align-center justify-end">
                    <span class="supply-days__title">Supply days</span>
                    <span class="supply-days__count ml-1">60</span>
                  </div>
                </v-col>
              </v-row>-->
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div v-if="dialog.name !== 'editSupplementDialog'"  class="add-supplement-dialog__label mb-1" style="margin-top: -22px">Type new supplemen</div>
                  <div v-if="dialog.name === 'editSupplementDialog'" class="ml-2 mt-2 mb-2">
                    <span style="font-style: normal;font-weight: 600;font-size: 20px;">{{searchValue}}</span>
                  </div>
                  <Input outlined
                         radius="8"
                         placeholder="New supplement name"
                         v-if="dialog.name === 'addSupplementDialog' && componentFrom !== 'protocol'"
                         @input="form.name=$event"
                         :value="form.name"
                         :rules="notEmtpy"/>
                  <Input outlined
                         radius="8"
                         :value="form.name"
                         placeholder="New supplement name"
                         v-if="dialog.name === 'editSupplementDialog' && componentFrom !== 'protocol'"
                         @input="form.name=$event"
                         :rules="notEmtpy"/>
                  <Input outlined
                         radius="8"
                         placeholder="New supplement name"
                         v-if="dialog.name === 'editSupplementDialog' && componentFrom === 'protocol'"
                         @input="form.supplementName=$event"
                         :value="form.supplementName"
                         :rules="notEmtpy"/>
                  <search-select @update:search="searchValue=$event"
                                 @change:object="form.supplementId=$event.id, form.name=$event.name"
                                 placeholder="Enter supplement name"
                                 itemText="name"
                                 itemValue="name"
                                 :value="form.name"
                                 :items="supplements"
                                 returnObject
                                 :rules="notEmtpy"
                                 v-if="dialog.name === 'addSupplementDialog' && componentFrom === 'protocol'"
                                 :background-color="'#EDF2F7'"
                  />
                </v-col>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :title="'Days Per Week'"
                  :countBtns="7"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.daysPerWeek"
                  @setData="form.daysPerWeek = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2 d-flex align-center justify-space-around select-brand" v-if="((dialog.name === 'addSupplementDialog' && componentFrom !== 'protocol') || (dialog.name === 'editSupplementDialog' && componentFrom !== 'protocol'))">
                <div class="add-supplement-dialog__label mr-1">Select Brand </div>
                <Select
                  outlined
                  itemText="name"
                  itemValue="id"
                  radius="8"
                  :items="brands"
                  :value="form.brandId"
                  @change="form.brandId = $event"
                />
                <Button btn-bg="dark-green"
                        dark
                        width="30"
                        height="30"
                        :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                        @click.native="addNewBrand">
                    <span style="color: #FFFFFF">
                      <v-icon>mdi-plus</v-icon>
                    </span>
                </Button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'upon-arising'"
                  :title="'Upon Arising'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageUponArising"
                  @setData="form.dosageUponArising = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'breakfast'"
                  :title="'Breakfast'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageBreakfast"
                  @setData="form.dosageBreakfast = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'10am'"
                  :title="'10 am'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage10am"
                  @setData="form.dosage10am = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'lunch'"
                  :title="'Lunch'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageLunch"
                  @setData="form.dosageLunch = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'3pm'"
                  :title="'3 pm'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage3pm"
                  @setData="form.dosage3pm = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'dinner'"
                  :title="'Dinner'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageDinner"
                  @setData="form.dosageDinner = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'7pm'"
                  :title="'7 pm'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage7pm"
                  @setData="form.dosage7pm = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'before-sleep'"
                  :title="'Before Sleep'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageBeforeSleep"
                  @setData="form.dosageBeforeSleep = $event"
                ></custom-radio-button>
              </v-row>
<!--              <v-row class="pl-6 pr-3 mb-2" v-if="componentFrom === 'tab-supplement'">
                <v-switch class="py-0 my-0" color="#008C89" inset label="Default Supplement" :value="form.isDefault" @change="changeIsDefaultSupplement" />
              </v-row>-->
            </v-col>
            <v-col cols="6">
              <v-row class="add-supplement-dialog__label justify-center mt-3">Supplement Details</v-row>
              <v-row class="pl-2 pr-3 mb-2" style="margin-top: -22px">
                <v-col class="pb-0 mb-2 pt-1">
                  <div class="add-supplement-dialog__bp">
                    <v-checkbox
                      color="#008C89"
                      v-model="form.vegetarian"
                      label="Vegetarian"/>
                    <v-checkbox
                      style="margin-top: -10px"
                      color="#008C89"
                      v-model="form.glutenFree"
                      label="Gluten free"/>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2" style="margin-top: -45px;">
                <v-col class="pb-0 mb-2 pt-1">
                  <div class="add-supplement-dialog__bp">
                    <v-checkbox
                      color="#008C89"
                      v-model="form.warningLowBP"
                      label="Warning low BP"/>
                    <v-checkbox
                      style="margin-top: -10px"
                      color="#008C89"
                      v-model="form.warningHighBP"
                      label="Warning high BP"/>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2" style="margin-top: -15px">
                <v-col class="pb-0 pt-1" cols="6">
                  <div class="add-supplement-dialog__label">Quantity Type</div>
                  <Select
                    outlined
                    itemText="text"
                    itemValue="id"
                    radius="8"
                    :items="quantityTypes"
                    :value="form.quantityType"
                    @change="form.quantityType = $event"
                  /><!--:value="form.quantityType || quantityTypes[0].code"-->
                </v-col>
                <v-col v-if="['OZ', 'Gram', 'ML', 'O'].includes(form.quantityType)" class="pb-0 pt-1" cols="6">
                  <div class="add-supplement-dialog__label">Dosage Type</div>
                  <Select
                    outlined
                    itemText="text"
                    itemValue="id"
                    radius="8"
                    :items="dosageTypes"
                    :value="form.dosageType"
                    @change="form.dosageType = $event"
                  /><!--:value="form.dosageType || dosageTypes[0].code"-->
                </v-col>
              </v-row>
              <!--<v-row class="pl-2 pr-3 mb-2" style="margin-top: -25px">
                <v-col class="pb-0 mb-2 pt-1">
                  <div class="d-flex add-supplement-dialog__bp">
                    <v-checkbox
                      color="#008C89"
                      class="pt-1 pb-1"
                      v-model="form.printShortDescriptionReports"
                      label="Print my Short Description on Reports"/>
                  </div>
                </v-col>
              </v-row>-->
              <v-row class="add-supplement-dialog__label justify-center" style="margin-top: 0px">Supplement Notes</v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <v-expansion-panels flat v-model="computedPanel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                      Short description (English)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="2" rows-height="4" outlined @input:text-area="form.shortDesc=$event" :value="form.shortDesc" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                      Short description (Spanish)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="2" rows-height="4" outlined @input:text-area="form.shortDescSpanish=$event" :value="form.shortDescSpanish" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Special Notes
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="2" rows-height="4" outlined @input:text-area="form.specialNotes=$event" :value="form.specialNotes" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--<v-expansion-panel>
                    <v-expansion-panel-header>
                      Patient Note (English)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="2" rows-height="4" outlined @input:text-area="form.patientNotes=$event" :value="form.patientNotes" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>-->
                  <!--<v-expansion-panel>
                    <v-expansion-panel-header>
                      Patient Note (Spanish)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="2" rows-height="4" outlined @input:text-area="form.patientNotesSpanish=$event" :value="form.patientNotesSpanish" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>-->
                  <!--<v-expansion-panel>
                    <v-expansion-panel-header>
                      My internal Notes
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Textarea rows="2" rows-height="4" dense auto-grow outlined @input:text-area="form.clinicianNotes=$event" :value="form.clinicianNotes" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>-->
                  <v-expansion-panel v-if="componentFrom === 'protocol'">
                    <v-expansion-panel-header>
                      Warnings
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#ffede3b3" border-radius="8">
                      <Textarea rows="2" rows-height="4" dense auto-grow outlined color="'red'" @input:text-area="form.warnings=$event" :value="form.warnings" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
              <v-row style="margin-top: 15px;">
                <v-col class="py-0">
                  <div class="d-flex justify-space-between">
                    <Button :styles="{ 'border-radius': '8px', 'border': '1px solid #EDF2F7', 'background': 'white' }"
                            width="99"
                            height="53"
                            @click.native="close">
                      <span style="color: #425466;font-weight: 600;font-size: 14px;">Cancel</span>
                    </Button>
                    <Button btn-bg="dark-green"
                            dark
                            width="180"
                            height="53"
                            :disabled="!form.name || !form.quantityType || (['OZ', 'Gram', 'ML', 'O'].includes(form.quantityType) && !form.dosageType)"
                            :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                            v-if="dialog.name == 'addSupplementDialog'"
                            @click.native="add">
                      <span>Save</span>
                    </Button>
                    <Button btn-bg="dark-green"
                            dark
                            width="180"
                            height="53"
                            :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                            v-else
                            @click.native="update">
                      <span>Update</span>
                    </Button>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <!--<template slot="actions">
        <v-row class="d-flex justify-end" style="margin-top: -325px">
          <v-col cols="6" class="pl-0 pr-6">
            <v-row>
              <v-col class="py-0">
                <div class="d-flex justify-space-between">
                  <Button :styles="{ 'border-radius': '8px', 'border': '1px solid #EDF2F7', 'background': 'white' }"
                          width="99"
                          height="53"
                          @click.native="close">
                    <span style="color: #425466;font-weight: 600;font-size: 14px;">Cancel</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          dark
                          width="180"
                          height="53"
                          :disabled="!form.name || !form.quantityType || (['OZ', 'Gram', 'ML', 'O'].includes(form.quantityType) && !form.dosageType)"
                          :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                          v-if="dialog.name == 'addSupplementDialog'"
                          @click.native="add">
                    <span>Save</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          dark
                          width="180"
                          height="53"
                          :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                          v-else
                          @click.native="update">
                    <span>Update</span>
                  </Button>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>-->
    </Dialog>
    <modal-add-brand v-if="modalAddNewBrand.isOpen" @update="handlerEvent"/>
    <div class="custom-alert">
      <v-alert
        :color="alertColor"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{message}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Validation from '@/mixins/Validation'

import SearchSelect from '@/components/elements/search-select'
import Button from '@/components/elements/Button'
import Textarea from '@/components/elements/Textarea'
import CustomRadioButton from '@/components/elements/custom-radio-button'

import debounce from 'lodash/debounce'
import Input from '@/components/elements/Input'
import Select from '@/components/elements/Select'
import ModalAddBrand from '@/components/layouts/dialogs/modal-add-brand.vue'

export default {
  name: 'add-supplement-dialog',
  props: {
    query: Object,
    componentFrom: String
  },
  mixins: [Validation],
  components: { ModalAddBrand, Dialog, Button, CustomRadioButton, Textarea, SearchSelect, Input, Select }, // SearchSelect, Input, SpinInput, Textarea, Input
  data: () => ({
    // panel: [0, 1],
    alertColor: '#7ac1fb',
    alertIsOpen: false,
    message: null,
    form: {
      name: '',
      supplementName: '',
      discontinued: false,
      dosageUponArising: 0,
      dosageBreakfast: 0,
      dosage10am: 0,
      dosageLunch: 0,
      dosage3pm: 0,
      dosageDinner: 0,
      dosage7pm: 0,
      dosageBeforeSleep: 0,
      daysPerWeek: 7,
      id: '',
      warnings: '',
      // patientNotes: '',
      // patientNotesSpanish: '',
      // clinicianNotes: '',
      shortDesc: '',
      shortDescSpanish: '',
      specialNotes: '',
      supplementId: '',
      warningLowBP: false,
      warningHighBP: false,
      vegetarian: false,
      glutenFree: false,
      // printShortDescriptionReports: false,
      quantityType: null,
      dosageType: null,
      brandId: null,
      // isDefault: false,
    },
    supplements: [],
    searchValue: '',
    mockDataQuantityType: [
      {
        id: 1,
        text: 'Test 1'
      },
      {
        id: 2,
        text: 'Test 2'
      },
      {
        id: 3,
        text: 'Test 3'
      },
      {
        id: 4,
        text: 'Test 4'
      }
    ]
  }),
  async mounted () {
    await this.getBrands()
    if (this.dialog.name === 'editSupplementDialog') {
      if (this.dialog.type === 'protocol') {
        await this.getSupplementById({ id: this.dialog.id })
        this.form = Object.assign({}, this.protocolSupplementById)
      } else {
        await this.getDefaultDosagesBySupplement(this.dialog.id)
        this.form = Object.assign({}, this.supplementById)
      }
    }
  },
  methods: {
    ...mapActions('supplements', ['getSupplements', 'addSupplement', 'supplementsDropdown', 'updateSupplement', 'getDefaultDosagesBySupplement']),
    ...mapActions('protocols', ['getProtocols', 'getSupplementById', 'updateProtocolSupplement', 'addProtocolSupplement']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('brands', ['getBrands']),
    async add () {
      console.log('ADD NEW', this.searchValue, this.form)
      if (this.validate()) {
        if (this.dialog.type === 'protocol') {
          await this.addProtocolSupplement({ protocolId: this.dialog.protocolId, ...this.form })
        } else {
          await this.addSupplement({ ...this.form })
        }
        const { afterAction } = this.dialog
        await this.$store.dispatch(afterAction.name, afterAction.protocol)
        this.close()
      }
    },
    async update () {
      if (this.validate()) {
        let res = null
        if (this.dialog.type === 'protocol') {
          console.log(this.form, this.dialog)
          res = await this.updateProtocolSupplement({ ...this.form })
          if (res) {
            this.getProtocols(this.query)
          }
        } else {
          // this.form.isDefault = this.form.isDefault === null ? false : this.form.isDefault
          res = await this.updateSupplement({ ...this.form })
        }
        if (res) {
          this.getSupplements(this.query)
        }
        this.close()
      }
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, isOpen: false })
    },
    changeIsDefaultSupplement($event) {
      this.form.isDefault = $event
    },
    addNewBrand () {
      this.TOGGLE_DIALOG({ name: 'modalAddNewBrand', isOpen: true, title: 'Add new Brand' })
    },
    async handlerEvent (value) {
      if (value) {
        await this.getBrands()
        this.alertIsOpen = true
        this.alertColor = '#7ac1fb'
        this.message = `New Brand name was created`
      } else {
        this.alertIsOpen = true
        this.alertColor = '#CF4655'
        this.message = `Occurred some error`
      }
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 3000)
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit,
      dialog (state) {
        const modalNameAdd = this._.findKey(state.dialogs, {'isOpen': true, name: 'addSupplementDialog'})
        const modalNameEdit = this._.findKey(state.dialogs, {'isOpen': true, name: 'editSupplementDialog'})
        return state.dialogs[modalNameAdd || modalNameEdit]
      },
      modalAddNewBrand (state) {
        return state.dialogs.modalAddNewBrand
      },
      supplementById: state => state.supplements?.supplementById,
      protocolSupplementById: state => state.protocols?.supplementById,
      quantityTypes: state => state.settings.patients.quantityTypes.$values.map(item => {
        return {
          ...item,
          text: item.name,
          value: item.code,
          id: item.code
        }
      }),
      dosageTypes: state => state.settings.patients.dosagesTypes.$values.map(item => {
        return {
          ...item,
          text: item.name,
          value: item.code,
          id: item.code
        }
      }),
      brands: state => state.brands.brands || []
    }),
    computedPanel () {
      if (this.componentFrom === 'tab-supplement') {
        return [0]
      } else {
        return [0]
      }
    }
  },
  watch: {
    searchValue: debounce(async function (val) {
      if (val) {
        const result = await this.supplementsDropdown(val)
        this.supplements = result?.$values
      } else {
        this.supplements = []
      }
    }, 1000),
    'form.id': async function (val) {
      console.log(val, this.form)
      if (this.dialog.name !== 'editSupplementDialog') {
        if (this.dialog.type === 'protocol') {
          const result = await this.getSupplementById(val)
          Object.assign(this.form, {
            dosageUponArising: result.dosageUponArising,
            dosageBreakfast: result.dosageBreakfast,
            dosage10am: result.dosageUponArising,
            dosageLunch: result.dosage10am,
            dosage3pm: result.dosage3pm,
            dosageDinner: result.dosageDinner,
            dosage7pm: result.dosage7pm,
            dosageBeforeSleep: result.dosageBeforeSleep
          })
        } else {
          const result = await this.getDefaultDosagesBySupplement(this.dialog.id)
          this.searchValue = result.name
        }
      }
    },
    'form.supplementId': async function (val) {
      if (this.dialog.name === 'addSupplementDialog' && this.componentFrom === 'protocol') {
        const result = await this.getDefaultDosagesBySupplement(val)
        Object.assign(this.form, {
          dosageUponArising: result.record.dosageUponArising,
          dosageBreakfast: result.record.dosageBreakfast,
          dosage10am: result.record.dosageUponArising,
          dosageLunch: result.record.dosage10am,
          dosage3pm: result.record.dosage3pm,
          dosageDinner: result.record.dosageDinner,
          dosage7pm: result.record.dosage7pm,
          dosageBeforeSleep: result.record.dosageBeforeSleep
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-supplement-dialog {
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 999;
  }
  .select-brand {
    ::v-deep .v-input {
      width: 200px;
    }
  }
  ::v-deep .v-dialog {
    overflow-y: hidden!important;
    max-height: unset!important;
    &__container {
      .v-card {
        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__text {
          overflow-y: scroll;
          max-height: 605px; // 595px;
          padding: 16px!important;
          background-color: white!important;
        }
      }
    }
  }
  &____bp {
    ::v-deep .v-input {
      padding: 10px 13px 10px 13px;
      label {
        font-size: 14px;
        font-weight: 500;
      }
      &:hover {
        background: #F7FAFC;
        border-radius: 8px;
      }
    }
      &__control {
        .v-messages {
          display: none;
        }
      }
    }
  .patient-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .supply-days {
    font-style: normal;
    &__title {
      font-weight: normal;
      font-size: 14px;
      line-height: 23px;
      color: #718096;
    }
    &__count {
      font-weight: 600;
      font-size: 14px;
      line-height: 23px;
      color: #425466;
    }
  }
  &__label {
    font-weight: bold;
    line-height: 21px;
    font-size: 16px;
    color: #425466;
  }
  ::v-deep .search-select {
    .v-input {
      .v-input__control {
        .v-input__slot {
          border-radius: 8px !important;
          input {
            padding-left: 8px;
          }
        }
        .v-input__slot:before {
          width: 0 !important;
        }
      }
    }
  }
  ::v-deep .v-expansion-panels {
    .v-expansion-panel:not(.v-item--active) {
      //border-bottom: 1px solid #E2E8F0;
      .v-expansion-panel-header {
        padding: 16px 10px !important;
      }
      v-expansion-panel-header--active {
        padding: 16px 10px !important;
      }
      .v-expansion-panel-content {
        border-radius: 8px;
      }
    }
    .v-expansion-panel {
      //border-bottom: 1px solid #E2E8F0;
      .v-expansion-panel-header {
        padding: 16px 10px !important;
      }
      v-expansion-panel-header--active {
        padding: 16px 10px !important;
      }
      .v-expansion-panel-content {
        border-radius: 8px;
      }
    }
  }
  &__spin-input {
    ::v-deep .v-text-field__details {
      display: none !important;
    }
  }
  ::v-deep .select .v-text-field {
    &__details {
      display: none;
    }
  }
}
</style>
