<template>
  <div class="report-view">
    <!--    <Dialog :scrollable="false">
          <template slot="title">
            <span>
              {{dialog.title}}
            </span>
          </template>
          <template slot="body">
            <WebViewer
              :initialDoc="initialDoc"
            />
          </template>
        </Dialog>-->
    <v-row no-gutters class="justify-space-between align-center">
      <v-col cols="9">
        <div class="title">
          {{dialog.title}}
        </div>
      </v-col>
      <v-col cols="3">
        <div class="language-switcher">
          <v-row class="d-flex">
            <v-col cols="6">
              <Button
                block
                dark
                :class="{ 'active-language': activeLanguage === 'es' }"
                @click.native="switchLanguage('es')"
                tabindex="3" height="36">
                Spanish
              </Button>
            </v-col>
            <v-col cols="6">
              <Button
                block
                dark
                :class="{ 'active-language': activeLanguage === 'en' }"
                @click.native="switchLanguage('en')"
                tabindex="3" height="36">
                English
              </Button>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <WebViewer
      :initialDoc="initialDoc"
      @renderWebViewer="renderWebViewer"
    />
    <v-row no-gutters class="mt-4">
      <v-col cols="12" class="d-flex justify-end report-buttons">
        <Button color="#d2d2d2"
                outlined
                dark>
          <v-icon>mdi-send-outline</v-icon>
          <span class="btn-text">Send to Patient</span>
        </Button>
        <Button color="#d2d2d2"
                outlined
                dark>
          <span class="btn-text">Send to Portal</span>
        </Button>
        <Button color="#d2d2d2"
                outlined
                dark>
          <span class="btn-text">Send to Patient Email</span>
        </Button>
        <Button id="test-custom-btn" color="#d2d2d2"
                @click.native="print"
                outlined
                dark>
          <span class="btn-text">Print</span>
        </Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WebViewer from '@/components/elements/WebViewer'
import Button from '@/components/elements/Button'
// import Dialog from '@/components/layouts/dialog'

export default {
  props: {
    activeLanguage: String,
    initialDoc: {
      type: String,
      default: null
    }
  },
  components: { WebViewer, Button },
  name: 'reportView',
  data: () => ({
    localInstance: null
  }),
  methods: {
    switchLanguage (val) {
      this.$emit('switchLanguage', val)
    },
    renderWebViewer (instance) {
      this.localInstance = instance
    },
    print () {
      console.log('print', this.localInstance)
      const { UI } = this.localInstance // Core
      // const { documentViewer } = Core
      UI.enableElements('printModal')
      this.localInstance.UI.print()
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    model: state => state.visits?.model
  })
}
</script>

<style scoped lang="scss">
.report-view {
  .language-switcher {
    border: 1px solid #f1f1f1;
    border-radius: 18px;
    padding: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    .button {
      ::v-deep .v-btn {
        background-color: rgba(255, 255, 255, 0.64) !important;
        color: black!important;
        font-weight: 600!important;
      }
    }
    .button.active-language {
      ::v-deep .v-btn {
        background-color: $dark-green !important;
        color: white!important;
        font-weight: 600!important;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .btn-text {
    font-weight: 600;
    font-size: 12px;
    color: #16192C;
    padding-left: 6px;
  }
  .report-buttons {
    column-gap: 1rem;
    ::v-deep .v-btn {
      border-radius: 8px!important;
      .v-icon {
        font-size: 15px!important;
        transform: rotateZ(-45deg) !important;
      }
    }
  }
}
</style>
