<template>
  <div class="filter-box d-flex">
    <v-menu right buttom :close-on-content-click="false" ref="filterMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="v-data-table-header__filter-icon" size="15" v-on="on" v-bind="attrs">mdi-filter</v-icon>
      </template>
      <v-list max-width="300">
        <div style="padding: 0 15px;">Show items with value that:</div>
        <div v-if="type !== 'Date' && type !== 'FixedEnum'" style="padding: 0 15px;">
          <Select outlined :items="items" :value="items[0]" @change="setType"  />
        </div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-form class="py-4" ref="form" v-model="valid" lazy-validation :id="`filter-box-${fieldName}`">
                <template v-if="type === 'Number' && form.search.searchType == 4">
                  <div class="d-flex flex-column">
                    <Input class="mr-2" outlined  placeholder="from" radius="8" :rules="[notEmtpy]" @input="setFrom" />
                    <Input outlined placeholder="to" radius="8" :rules="[notEmtpy]" @input="setTo" />
                  </div>
                </template>
                <template v-if="type === 'Date'">
                  <div class="d-flex flex-column">
                    <div class="label">From</div>
                    <datepicker @date:update="form.fromDate = $event"
                                :value="form.fromDate"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <div class="label">To</div>
                    <datepicker @date:update="form.toDate = $event"
                                :value="form.toDate"
                    />
                  </div>
                </template>
                <template v-if="type === 'FixedEnum'">
                  <v-checkbox
                    v-model="form.selectedEnum"
                    v-for="status in getStatuses"
                    :key="status.statusCode"
                    :label="status.statusName"
                    :value="status.statusCode"
                  ></v-checkbox>
                </template>
                <template v-if="type !== 'Date' && type !== 'FixedEnum'">
                  <Input outlined placeholder="Some value" radius="8" :rules="[notEmtpy]" @input="form.search.value=$event" />
                </template>
              </v-form>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex">
              <Button dark class="mr-2" btn-bg="dark-green" :styles="{ 'border-radius': '8px' }" @click.native="filter">Filter</Button>
              <Button
                dark
                outlined
                color="#008C89"
                :styles="{ 'border-radius': '8px' }" @click.native="reset">Clear</Button>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Select from '@/components/elements/Select'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import datepicker from '@/components/elements/datepicker'
import Validate from '@/mixins/Validation'
import Mapping from '@/mixins/Mapping'

const searchTypeEnum = ['StartsWith', 'Contains', 'EndsWith', 'Equals', 'MultipleRange']

export default {
  name: 'filter-box',
  props: {
    headers: {
      type: Array
    },
    fieldName: {
      type: String
    },
    type: {
      type: String
    }
  },
  mixins: [Validate, Mapping],
  components: { Select, Input, Button, datepicker },
  data () {
    return {
      testItems: ['Foo', 'Bar', 'Fizz', 'Buzz', 'Buzz1', 'Buzz2', 'Buzz3', 'Buzz4 '],
      form: {
        type: this.type,
        name: this.fieldName, // "string",
        search: {
          searchType: '', // "StartsWith",
          value: ''
        },
        searchable: true,
        fromDate: '',
        toDate: '',
        selectedEnum: []
      }
    }
  },
  methods: {
    filter () {
      if (this.validate()) {
        this.$emit('update:filter-box', this.form)
        this.$refs.filterMenu.save()
      }
    },
    reset () {
      this.$emit('reset:filter-box', this.fieldName)
      this.$refs.form.reset()
    },
    setType ($event) {
      const searchType = this._.upperFirst(this._.camelCase($event))
      const serchIndex = searchTypeEnum.findIndex(el => el === searchType)
      this.form.search.searchType = serchIndex
      if (this.$refs?.form?.resetValidation) {
        this.$refs.form.resetValidation()
      }
    },
    setFrom ($event) {
      const value = this.form.search.value
      let tmpVal = ''
      if (value.includes('|')) {
        tmpVal = value.split('|').unshift()
      }
      this.form.search.value = `${$event}|${tmpVal}`
    },
    setTo ($event) {
      const value = this.form.search.value
      let tmpVal = ''

      if (value.includes('|')) {
        tmpVal = value.split('|').pop()
      }
      this.form.search.value = `${tmpVal}|${$event}`
    }
  },
  computed: {
    items () {
      let slctItems = []
      switch (this.type) {
        case 'String':
          slctItems = ['Starts with', 'Contains', 'Ends With', 'Equals']
          break
        case 'Number':
          slctItems = ['Contains', 'Equals', 'Multiple Range']
          break
      }

      return slctItems
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-box {}
  ::v-deep .v-input {
    .v-input__control {
      .v-text-field__details {
        display: none!important;
      }
    }
  }
</style>
