<template>
  <div class="invite-patient">
    <Dialog width="568" scrollable>
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addForm" class="py-4">
          <div class="main-row">
            <div class="section__avatar">
              <v-avatar size="80">
                <v-img :src="dialog.data.icon"></v-img>
              </v-avatar>
            </div>
            <div class="section__info">
              <div class="main-row">
                <div class="label">Joined {{ dialog.data.createdOn ? $moment(dialog.data.createdOn).format('LL') : '-' }}</div>
                <div class="name">{{ dialog.data.name }}</div>
              </div>
              <div class="main-row mt-2">
                <div class="phone">
                  <div class="label">Phone Number</div>
                  <div class="phone-number">{{dialog.data.number}}</div>
                </div>
                <div class="email">
                  <div class="label">E-mail</div>
                  <div class="email-value">{{dialog.data.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <v-row class="mt-2">
            <v-col class="pb-0">
              <div class="label mb-1">Short Invitation Message</div>
              <Textarea
                dense
                auto-grow
                outlined
                @input:text-area="patientMessage=$event"
                :value="patientMessage"
                :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
              />
              <div class="mt-1 short-message">
                <span>Patient will recieve email with <span class="bold">instruction to register on Patient Portal</span></span>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template slot="actions">
        <div class="w100 d-flex justify-end py-4 pl-5 pr-5 align-center">
          <Button
            dark
            btn-bg="dark-green"
            @click.native="invite">
            Invite
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'
import Validation from '@/mixins/Validation'
import Textarea from '@/components/elements/Textarea'

export default {
  name: 'invite-patient',
  components: {
    Dialog,
    Textarea,
    Button
  },
  mixins: [Validation],
  data: () => ({
    patientMessage: ''
  }),
  mounted () {
    this.patientMessage = `Hi, ${this.dialog.data.name}\nPlease join out Patient Portal to manage your surveys`
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  }),
  methods: {
    ...mapActions('organizations', ['invitePatientToThePortal']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async invite () {
      const result = await this.invitePatientToThePortal({ patientId: this.dialog.data.id, notes: this.patientMessage })
      if (result && result.validationResults.isValid) {
        this.$emit('showPopup', { type: 'invite-patient', data: result })
      }
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, isOpen: false })
    }
  }
}
</script>

<style scoped lang="scss">
.invite-patient {
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        background: #edf2f7;
        padding: 25px 30px 17px 46px !important;
        justify-content: center!important;
        position: relative;
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
  #addForm {
    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 0.5rem;
      .section__avatar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1rem;
        width: fit-content;
      }
      .section__info {
        width: calc(100% - 125px);
      }
      .label {
        font-weight: 600;
        color: #afafaf;
      }
      .name {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
      }
      .phone-number {
        font-weight: 600;
      }
      .email-value {
        font-weight: 600;
      }
    }
    .short-message {
      .bold {
        font-weight: 600;
      }
    }
  }
  ::v-deep .v-dialog__container {
    .v-card__actions {
      justify-content: end;
    }
  }
}
</style>
