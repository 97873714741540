const Validation = {
  computed: {
    getStatusCodeName () {
      return {
        InActive: {
          name: 'Inactive',
          class: 'in-active',
          description: 'Patient was set inactive'
        },
        'In Active': {
          name: 'Inactive',
          class: 'in-active',
          description: 'Patient was set inactive'
        },
        'In active': {
          name: 'Inactive',
          class: 'in-active',
          description: 'Patient was set inactive'
        },
        NeedSurvey: {
          name: 'Need survey',
          class: 'need-survey',
          description: 'Patient has no evaluation date'
        },
        'Need Survey': {
          name: 'Need survey',
          class: 'need-survey',
          description: 'Patient has no evaluation date'
        },
        'Need survey': {
          name: 'Need survey',
          class: 'need-survey',
          description: 'Patient has no evaluation date'
        },
        NeedReeval: {
          name: 'Need reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days without protocol`
          }
        },
        'Need Reeval': {
          name: 'Need reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days without protocol`
          }
        },
        'Need reeval': {
          name: 'Need reeval',
          class: 'need-reeval',
          description: (param) => {
            return `${param} days without protocol`
          }
        },
        SurveySent: {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: 'Survey has been sent to patient'
        },
        'Survey Sent': {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: 'Survey has been sent to patient'
        },
        'Survey sent': {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: 'Survey has been sent to patient'
        },
        SurveyDue: {
          name: 'Survey due',
          class: 'survey-due',
          description: 'Patient has not completed the survey on a due date'
        },
        'Survey Due': {
          name: 'Survey due',
          class: 'survey-due',
          description: 'Patient has not completed the survey on a due date'
        },
        'Survey due': {
          name: 'Survey due',
          class: 'survey-due',
          description: 'Patient has not completed the survey on a due date'
        },
        NeedReview: {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return `Patient has completed the survey ${param} days ago`
          }
        },
        'Need Review': {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return `Patient has completed the survey ${param} days ago`
          }
        },
        'Need review': {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return `Patient has completed the survey ${param} days ago`
          }
        },
        Reviewed: {
          name: 'Reviewed',
          class: 'reviewed',
          description: 'Survey reviewed. The initial results may be shared with the patient'
        },
        InProtocol: {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `The protocol is assigned to the patient for the next ${param} days`
          }
        },
        'In Protocol': {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `The protocol is assigned to the patient for the next ${param} days`
          }
        },
        'In protocol': {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `The protocol is assigned to the patient for the next ${param} days`
          }
        },
        Completed: {
          name: 'Completed',
          class: 'completed',
          description: 'The visit is finalized. All activities were completed'
        },
        NeedProduct: {
          name: 'Need Product',
          class: 'need-product',
          description: 'Need Product Need Product Need Product Need Product Need Product'
        },
        'Need Product': {
          name: 'Need Product',
          class: 'need-product',
          description: 'Need Product Need Product Need Product Need Product Need Product'
        },
        'Need product': {
          name: 'Need Product',
          class: 'need-product',
          description: 'Need Product Need Product Need Product Need Product Need Product'
        }
      }
    },
    getStatuses () {
      return [
        {
          statusCode: 'Licensed',
          statusName: 'Licensed'
        },
        {
          statusCode: 'Trials',
          statusName: 'Trials'
        },
        {
          statusCode: 'Expired',
          statusName: 'Expired'
        },
        {
          statusCode: 'ExpireSoon',
          statusName: 'Expire soon'
        }
      ]
    },
    getEventType () {
      return {
        0: {
          name: 'Create'
         },
        1: {
          name: 'Read'
        },
        2: {
          name: 'Update'
        },
        3: {
          name: 'Delete'
        }
      }
    }
  }
}

export default Validation
