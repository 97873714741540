<template>
  <div class="add-visit">
    <Dialog width="600">
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid"  v-if="model" lazy-validation id="addVisit" class="py-4 px-6">
          <div class="main-row">
            <div class="section__avatar">
              <v-avatar size="80">
                <v-img :src="dialog.data.profileAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="section__info">
              <div class="main-row">
                <div class="label">Maestro Patient Since {{ dialog.data.createdOn ? $moment(dialog.data.createdOn).format('MM/DD/YYYY') : '-' }}</div>
                <div class="name">{{ dialog.data.firstName + ' ' + dialog.data.lastName }}</div>
              </div>
              <div class="main-row mt-2">
                <div class="phone">
                  <div class="label">Phone Number</div>
                  <div class="phone-number">{{dialog.data.number || '-'}}</div>
                </div>
                <div class="email">
                  <div class="label">E-mail</div>
                  <div class="email-value">{{dialog.data.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <v-row>
            <v-col class="pb-0" style="position:relative;">
              <div class="label">Survey Due Date</div>
              <PhoneInput
                radius="8"
                :mask="'##/##/####'"
                :placeholder="'03/05/2022'"
                :value="form.date"
                @input="form.date=$event"
              />
              <v-icon color="#008C89" style="position: absolute;right: 20px;top: 40px;">mdi-calendar-blank</v-icon>
              <datepicker
                style="position: absolute;top: 34px;right: 0;opacity: 0;width: 50%"
                @date:update="form.date=$moment($event).format('MM/DD/YYYY')"
                :value="model.date ? $moment(model.date).format('MM/DD/YYYY') : ''" :rules="[notEmtpy]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <div class="label">Doctors</div>
              <Select outlined
                v-if="doctors"
                radius="8"
                itemText="name"
                itemValue="id"
                :items="doctors"
                :value="doctors[0]"
                @change="form.doctorId=$event.id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="">
               <div class="label">Notes</div>
              <Textarea outlined
                counter
                color="#fff"
                radius="8"
                height="100"
                background-color="#EDF2F7"
                placeholder="Initial notes to Patients"
                :value="model.notes"
                @input:text-area="form.notes=$event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-checkbox
                style="margin-top: 0"
                color="#008C89"
                v-model="model.addSurveyForm"
                label="Add Survey Pdf form to the Invitation Email"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex justify-end">
                <Button color="#008C89" @click.native="save">
                  <span style="color: white">Schedule</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Select from '@/components/elements/Select'
import datepicker from '@/components/elements/datepicker'
// import timepicker from '@/components/elements/timepicker'
import Textarea from '@/components/elements/Textarea'

import Validation from '@/mixins/Validation'
// import Input from '@/components/elements/Input'
import PhoneInput from '@/components/elements/phone-input'

export default {
  name: 'add-visit',
  mixins: [Validation],
  props: {
    query: {
      type: Object
    }
  },
  components: {
    Dialog,
    Button,
    Select,
    datepicker,
    // timepicker,
    // Input,
    PhoneInput,
    Textarea
  },
  data: () => ({
    time: null,
    form: {
      // patientName: '',
      date: null,
      doctorId: null,
      patientId: null,
      notes: '',
      addSurveyForm: null
    }
  }),
  mounted () {
    this.getDoctors()
    const { id } = this.dialog
    this.getModel(id)
    console.log('dialog.data', this.dialog.data)
  },
  methods: {
    ...mapActions('patients', ['getDoctors', 'getPatients']),
    ...mapActions('visits', ['addVisit', 'getModel', 'getVisit']),
    async save () {
      if (this.validate()) {
        const form = Object.assign({}, this.form)
        // form.patientName = this.model.patientName
        form.patientId = this.model.patientId
        form.doctorId = this.model.doctorId
        // form.doctorName = this.model.doctorName
        form.notes = this.model.notes
        form.date = this.$moment(this.form.date).format('YYYY-MM-DDTHH:mm:ss')
        form.addSurveyForm = this.model.addSurveyForm
        const res = await this.addVisit(form)
        this.$emit('showPopup', { type: 'add-visit', data: res })
        await this.getPatients(this.query)
      }
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    model: state => state.visits?.model
  })
}
</script>

<style lang="scss" scoped>
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .add-visit {
    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 0.5rem;
      .section__avatar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1rem;
        width: fit-content;
      }
      .section__info {
        width: calc(100% - 125px);
      }
      .label {
        font-weight: 600;
        color: #afafaf;
      }
      .name {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
      }
      .phone-number {
        font-weight: 600;
      }
      .email-value {
        font-weight: 600;
      }
    }
    ::v-deep .v-dialog__container {
      .v-card {
        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__text {
          .v-input {
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
