<template>
  <div class="confirmation-delete-visit">
    <Dialog width="500px">
      <template slot="title">
        <div class="title">
          {{modalDesktopDownloadLink.title}}
        </div>
      </template>

      <template slot="body">
        <div class="description" v-html="modalDesktopDownloadLink.data"></div>
        <Input outlined
               radius="8"
               appendIcon="mdi-link"
               placeholder="Link"
               v-model="downloadLinkValue"
        />
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Close</span>
          </Button>
          <Button
            dark
            color="#008C89"
            @click.native="downloadLink"
            :styles="{ 'border-radius': '8px' }"
          >
            <span>Confirm</span>
          </Button>
        </div>
      </template>
    </Dialog>
<!--    <confirm v-if="dialogs.confirm.isOpen" />-->
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
// import confirm from '@/components/layouts/dialogs/confirm-dialog'
import Input from '@/components/elements/Input'

export default {
  name: 'desktop-download-link',
  components: { Dialog, Button, Input }, // confirm
  data: () => ({
    downloadLinkValue: null
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('organizations', ['sendDesktopLink']),
    close () {
      this.TOGGLE_DIALOG({ name: 'modalDesktopDownloadLink', isOpen: false })
    },
    async downloadLink () {
      const res = this.sendDesktopLink({ url: this.downloadLinkValue, organizations: this.modalDesktopDownloadLink.customers || [this.$route.params.id] })
      if (res) {
        this.$emit('showPopup', { type: 'desktop-download-link', data: res })
      }
    }
  },
  computed: {
    ...mapState({
      modalDesktopDownloadLink: state => state.dialogs.modalDesktopDownloadLink
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.confirmation-delete-visit {
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
      }
    }
  }
}
</style>
