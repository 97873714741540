<template>
  <div class="setup">
    <template v-if="$route.name === 'Setup'">
      <Tabs :items="tabs" @update:tab="tab" :tab-index="tabIndex" left>
        <template v-slot:sliderColor>
          <v-tabs-slider color="#008C89"></v-tabs-slider>
        </template>
        <template v-slot:[`${tabs[componentInd].component}`]>
          <component :elevation="2" :is="tabs[componentInd].component"></component>
        </template>
      </Tabs>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import Tabs from '@/components/layouts/Tabs'
import stripe from '@/views/setup/tabs/stripe'
import customers from '@/views/setup/tabs/customers'
import supplements from '@/views/setup/tabs/supplements'
import products from '@/views/setup/tabs/products'
import imports from '@/views/setup/tabs/import-tab.vue'
import settings from '@/views/setup/tabs/settings'

export default {
  name: 'setup',
  components: { Tabs, stripe, customers, supplements, products, imports, settings },
  data: () => ({
    componentInd: 0,
    tabIndex: 0
  }),
  methods: {
    tab ($event) {
      this.componentInd = $event
    }
  },
  computed: {
    tabs () {
      const tabs = [
        { title: 'Settings', component: 'settings' },
        { title: 'Stripe', component: 'stripe' },
        { title: 'Customers', component: 'customers' },
        { title: 'Supplements', component: 'supplements' },
        { title: 'Products', component: 'products' },
        { title: 'Import', component: 'imports' }
      ]
      return tabs
    }
  }
}
</script>

<style scoped lang="scss">
.setup {
  //background-color: #F7FAFC;
  //height: 100vh;
  //padding: 16px;
  .tabs {
    ::v-deep .v-window {
      .v-card {
        &__text {
          padding: 0!important;
        }
      }
    }
    ::v-deep .wrapper {
      .item-setting {
        .v-card {
          &__text {
            padding: 16px!important;
          }
        }
      }
    }
  }
}
</style>
