import Vue from 'vue'

const state = () => ({
  reports: {},
  reportsSurvey: {}
})

const getters = {}

const actions = {
  async gerReportByCode ({ commit }, params) {
    const result = await Vue.$http.post('Reports/GetByCode', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      commit('SET_DATA', ['reports', result?.data.record])
      return result?.data
    }
  },
  async gerReportSurveyFormByCode ({ commit }, params) {
    const result = await Vue.$http.post('Reports/GetSurveyForm', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      commit('SET_DATA', ['reportsSurvey', result?.data.record])
      return result?.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
