import Vue from 'vue'

const state = () => ({
  visit: null,
  results: [],
  result: null,
  groupTotals: null,
  supplementTotals: null
})

const getters = {}

const actions = {
  async getResults ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['results', result.data])
    }
  },
  async addResult ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.post(`Results/${id}/ResultSupplements/Add`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'addResult', isOpen: false }, { root: true })
    }
  },
  async getResult ({ commit }, id) {
    const result = await Vue.$http.get(`Results/ResultSupplements/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['result', result.data?.record])
    }
  },
  async updateResult ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.put(`Results/ResultSupplements/${id}/Update`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'editResult', isOpen: false }, { root: true })
    }
    return result?.data
  },
  deleteResult ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.delete(`/Results/ResultSupplements/${id}/Delete`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The result was delete; Status: ${result?.status}`)
        }
      })
    })
  },
  async createSupplementProduct ({ commit }, data) {
    const result = await Vue.$http.post('ResultSupplementProducts/Create', { ...data })
    if (result?.status === 201) {
      return result.data?.record
    }
  },
  async removeSupplementProduct ({ commit }, { resultSupplementId, supplementProductId }) {
    const result = await Vue.$http.delete(`ResultSupplementProducts/${supplementProductId}/Delete`)
    if (result?.status === 200) {
      return result.data?.record
      // commit('REMOVE_SUPPLEMENT_PRODUCT', { resultSupplementId, supplementProductId })
    }
  },
  async updateSupplementProduct ({ commit }, { resultSupplementId, supplementProductId, numberOfBottles }) {
    const result = await Vue.$http.put('ResultSupplementProducts/Update', { id: supplementProductId, numberOfBottles })
    if (result?.status === 200) {
      return result.data?.record
      // commit('UPDATE_SUPPLEMENT_PRODUCT', { resultSupplementId, supplementProductId, numberOfBottles })
    }
  },
  async updateNotes ({ commit }, params) {
    const { apiUrl, field, dataForSend } = params
    const result = await Vue.$http.put(`Results/${apiUrl}/Update`, { ...dataForSend })
    if (result?.status === 204 || result?.status === 200) {
      if (field) {
        commit('UPDATE_DATA_POINT', { key: field, value: dataForSend.notes })
        return result?.data.record
      } else {
        commit('UPDATE_STORE_DATA', result?.data.record)
        return result?.data.record
      }
    }
  },
  async getGroupTotals ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/GroupTotals`)
    if (result?.status === 200) {
      commit('SET_DATA', ['groupTotals', result.data?.record])
    }
  },
  async getSupplementTotals ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/SupplementTotals`)
    if (result?.status === 200) {
      commit('SET_DATA', ['supplementTotals', result.data?.record])
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_DATA: (state, payload) => {
    for (const key in payload) {
      state.results.record[key] = payload[key]
    }
  },
  UPDATE_DATA_POINT: (state, payload) => {
    state.results.record[payload.key] = payload.value
  },
  UPDATE_STORE_DATA: (state, payload) => {
    console.log(payload)
    state.results.record.resultSupplements.$values.map((item, index) => {
      if (item.id === payload.resultSupplements.$values[0].id) {
        console.log('current res', item, 'newRes', payload)
        Object.assign(item, { ...payload.resultSupplements.$values[0] })
      }
    })
    state.results.record.dailyPrice = payload.dailyPrice
    state.results.record.daysSupply = payload.daysSupply
    state.results.record.monthlyPrice = payload.monthlyPrice
    state.results.record.totalBottles = payload.totalBottles
    state.results.record.totalDosage = payload.totalDosage
    state.results.record.totalDosage3pm = payload.totalDosage3pm
    state.results.record.totalDosage7pm = payload.totalDosage7pm
    state.results.record.totalDosage10am = payload.totalDosage10am
    state.results.record.totalDosageBeforeSleep = payload.totalDosageBeforeSleep
    state.results.record.totalDosageBreakfast = payload.totalDosageBreakfast
    state.results.record.totalDosageDinner = payload.totalDosageDinner
    state.results.record.totalDosageLunch = payload.totalDosageLunch
    state.results.record.totalDosageUponArising = payload.totalDosageUponArising
    state.results.record.totalPrice = payload.totalPrice
    state.results.record.weeklyPrice = payload.weeklyPrice
  },
  REMOVE_SUPPLEMENT_PRODUCT: (state, payload) => {
    state.results.record.resultSupplements.$values.map(item => {
      if (item.id === payload.resultSupplementId) {
        item.resultProducts.$values = item.resultProducts.$values.filter(product => {
          return product.id !== payload.supplementProductId
        })
      }
    })
  },
  UPDATE_SUPPLEMENT_PRODUCT: (state, payload) => {
    state.results.record.resultSupplements.$values.map(item => {
      if (item.id === payload.resultSupplementId) {
        item.resultProducts.$values.map(product => {
          if (product.id === payload.supplementProductId) {
            product.quantity = payload.numberOfBottles
          }
        })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
