import Vue from 'vue'

const state = () => ({
  protocols: {},
  supplementById: {},
  protocolListIsLoaded: false
})

const getters = {}

const actions = {
  async getProtocols ({ commit }, params) {
    const result = await Vue.$http.post('protocols/getprotocols', { ...params })
    commit('SET_DATA', ['protocolListIsLoaded', true])
    if (result?.status === 200) {
      commit('SET_DATA', ['protocols', result?.data])
      return result?.data
    }
  },
  async updateProtocolSelect ({ commit }, params) {
    const { id, value } = params
    const result = await Vue.$http.put(`protocols/ProtocolSupplement/${id}/Select?selected=${value}`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async deleteProtocol ({ commit }, id) {
    const result = await Vue.$http.delete(`protocols/${id}/Delete`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async addProtocol ({ commit }, params) {
    const { name, notes, active, brandsCode } = params
    const result = await Vue.$http.post('protocols/Create', { name, notes, active, brandsCode })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async updateProtocol ({ commit }, params) {
    const { id, name, notes, active, brandsCode } = params
    const result = await Vue.$http.put(`protocols/${id}/Update`, { name, notes, active, brandsCode })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async deleteSupplement ({ commit }, id) {
    const result = await Vue.$http.delete(`protocols/ProtocolSupplement/${id}/Delete`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async getSupplementById ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.get(`protocols/ProtocolSupplement/${id}/Get`, { ...params })
    if (result?.status === 200) {
      commit('SET_DATA', ['supplementById', result?.data?.record])
      return result?.data
    }
  },
  async addSupplement ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.post(`protocols/${id}/Products/Add`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async addProtocolSupplement ({ commit }, params) {
    const { protocolId } = params
    const result = await Vue.$http.post(`protocols/${protocolId}/ProtocolSupplements/Add`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async updateProtocolSupplement ({ commit }, params) {
    const { id } = params
    delete params.$id
    const result = await Vue.$http.put(`protocols/ProtocolSupplement/${id}/Update`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
