<template>
  <div class="table">
    <v-data-table v-bind="$props" :options.sync="options">
      <template v-for="(iter, i) in headers" :slot="`header.${iter.value}`" slot-scope="{item}">
        <div v-if="iter.filterable" :key="i" class="d-flex float-left">
          <span :key="i">{{iter.text}}</span>
          <slot :name="`header:${iter.value}`" :item="item" />
        </div>
        <div v-else-if="iter.isReplacingSpace" :key="i" class="d-flex justify-center">
          <div v-html="iter.text" style="text-align: center"></div>
        </div>
        <div v-else-if="iter.isIcon" :key="i" class="d-flex justify-center">
          <v-icon style="font-size: 20px">{{iter.text}}</v-icon>
        </div>
        <div v-else :key="i" class="d-flex float-left">
          <span>{{iter.text}}</span>
          <slot :name="`header:${iter.value}`" :item="item" />
        </div>
      </template>
      <template v-for="(iter, i) in headers" :slot="`item.${iter.value}`" slot-scope="{item}">
        <slot v-if="iter.isSlotable" :name="`item:${iter.value}`" :item="item"></slot>
        <span v-else :key="i">{{item[iter.value]}}</span>
      </template>

      <template slot="body.append">
        <slot name="body:append"></slot>
      </template>

      <template slot="body">
        <slot name="body"></slot>
      </template>

      <template slot="item.actions" slot-scope="{ item }">
        <actions :items="item.portal && item.active ? actions : item.portal && !item.active ? actionsWithActivate : !item.portal && !item.active ? extendedActionsWithActivate : extendedActions"
          v-on="actionListener"
          :item="item"
        />
      </template>

      <template v-slot:footer="{ item }">
        <slot name="footer" :item="item"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'
import actions from '@/components/layouts/actions'

export default {
  name: 'Table',
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    actions: { type: Array },
    actionsWithActivate: { type: Array },
    extendedActions: { type: Array },
    extendedActionsWithActivate: { type: Array },
    deactivatePatient: Object,
    activatePatient: Object,
    serverItemsLength: {
      type: Number
    },
    itemsPerPage: {
      type: Number
    },
    hideDefaultFooter: {
      type: Boolean
    }
  },
  mixins: [Validation],
  components: { actions },
  computed: {
    options: {
      get () {
        return {}
      },
      set (val) {
        this.$emit('update:option', val)
      }
    },
    actionListener: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          action: function (event) {
            vm.$emit('click:action', { action: event.params, id: event.id, data: event.data })
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .table {
    ::v-deep .v-data-table {
       filter: drop-shadow(0px 10px 20px rgba(113, 128, 150, 0.06));
      &__wrapper {
        .v-data-table-header {
          tr {
            th {
              font-size: 10px;
              font-weight: 600;
              color: #8492A6;
              white-space: nowrap;
            }
          }
        }
        tbody {
          tr {
            &:hover { background-color: transparent !important; cursor: pointer; }
            &:last-child {
              td {
                 border-bottom: thin solid rgba(0, 0, 0, 0.12);
              }
            }
          }
        }
      }
    }
  }
</style>
