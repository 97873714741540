import Vue from 'vue'

const state = () => ({
  status: null,
  surveyGroups: null,
  detailsComplaints: null,
  surveyQuestionEntries: null
})

const getters = {}

const actions = {
  async updateStatus ({ commit }, params) {
    const { type, visitId, surveyId } = params
    const result = await Vue.$http.put(`https://dev.kliksoft.services/${type}`, { visitId, surveyId })
    if (result?.status === 200) {
      commit('SET_DATA', ['status', result?.data?.record])
    }
  },
  async getSurveyGroups ({ commit }, params) {
    const { surveyID, additionalParam } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetSchema${additionalParam}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['surveyGroups', result?.data.record])
      return result?.data.record
    }
  },
  async getSurveyGroupByID ({ commit }, params) {
    const { surveyID, groupID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetGroup${groupID}`)
    if (result?.status === 200) {
      commit('UPDATE_SURVEY_GROUPS_QUESTIONS_DATA', result?.data.record)
      return result?.data.record
    }
  },
  async updateSurveyAnswer ({ commit }, params) {
    const { surveyID, ...data } = params
    const result = await Vue.$http.put(`/Surveys/${surveyID}/UpdateGroup`, { ...data })
    if (result?.status === 200) {
      commit('UPDATE_SURVEY_GROUPS_QUESTIONS_DATA', result?.data.record)
    }
  },
  async approveSurvey ({ commit }, params) {
    const result = await Vue.$http.put('Surveys/Approve', { ...params })
    if (result?.status === 200) {
      console.log(`Response: The patient was added; Status: ${result?.status}`)
      return true
    }
  },
  async finishSurvey ({ commit }, params) {
    const result = await Vue.$http.put('Visits/Complete', { ...params })
    if (result?.status === 200) {
      console.log(`Response: The patient was added; Status: ${result?.status}`)
      return true
    }
  },
  async getDetailsTestComplaints ({ commit }, params) {
    const { surveyID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetDetails`)
    if (result?.status === 200) {
      commit('SET_DATA', ['detailsComplaints', result?.data.record])
      return result?.data.record
    }
  },
  async updateTestComplaints ({ commit }, params) {
    const { data, surveyId } = params
    const result = await Vue.$http.put(`/Surveys/${surveyId}/UpdateDetails`, { ...data })
    if (result?.status === 200) {
      commit('SET_DATA', ['detailsComplaints', result?.data?.record])
    }
  },
  async getQuestionEntries ({ commit }, params) {
    const { surveyID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/QuestionEntries`)
    if (result?.status === 200) {
      commit('SET_DATA', ['surveyQuestionEntries', result?.data.record])
      return true
    }
  },
  async updateQuestionEntries ({ commit }, params) {
    const result = await Vue.$http.post('/Surveys/QuickEntries', { ...params })
    if (result?.status === 200) {
      commit('SET_DATA', ['surveyQuestionEntries', result?.data.record])
      return result?.data.record
    } else {
      return false
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_SURVEY_GROUPS_QUESTIONS_DATA: (state, payload) => {
    state.surveyGroups.progress = payload.progress
    state.surveyGroups.groups.$values.map((group, index) => {
      if (payload.id === group.id) {
        Vue.set(state.surveyGroups.groups.$values, index, payload)
      }
    })
  },
  UPDATE_FIELD_DATA: (state, payload) => {
    state.surveyGroups[payload.key] = payload.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
