<template>
  <div id="webviewer" ref="viewer"></div>
</template>

<script>
import WebViewer from '@pdftron/webviewer'
export default {
  name: 'WebViewer',
  props: {
    initialDoc: { type: String }
  },
  data: () => ({
    locInstance: null
  }),
  mounted () {
    const path = `${process.env.BASE_URL}webviewer`
    const self = this
    WebViewer({ path, initialDoc: this.initialDoc }, this.$refs.viewer).then(
      (instance) => {
        // console.log(instance)
        const { Core, UI } = instance // , UI
        self.locInstance = instance
        const FitMode = instance.UI.FitMode
        UI.setFitMode(FitMode.FitWidth)
        UI.setToolbarGroup('toolbarGroup-Print')
        UI.disableElements(['Annotations', 'Comments'])
        // UI.setFitMode(FitMode.Zoom)
        // UI.disableFeatures(['Download'])
        // UI.enableFeatures(['Download'])
        UI.disableElements(['toolbarGroup-Edit', 'leftPanel'])
        UI.disableElements('printModal')
        // UI.disableElements(['toolbarGroup-Shapes'])
        // UI.disableElements(['toolbarGroup-Edit'])
        // UI.disableElements(['toolbarGroup-Insert'])
        // UI.disableElements(['toolbarGroup-Annotate'])
        // UI.disableElements(['toolbarGroup-Forms'])
        // UI.disableElements(['toolbarGroup-FillAndSign'])
        UI.disableElements(['ribbons'])
        const { documentViewer, annotationManager, Annotations } = Core
        documentViewer.addEventListener('documentLoaded', () => {
          const rectangleAnnot = new Annotations.RectangleAnnotation({
            PageNumber: 1,
            X: 100,
            Y: 150,
            Width: 200,
            Height: 50,
            Zoom: 130,
            Author: annotationManager.getCurrentUser()
          })
          // annotationManager.addAnnotation(rectangleAnnot)
          annotationManager.redrawAnnotation(rectangleAnnot)
        })
      }
    )
  },
  watch: {
    locInstance (val) {
      this.$emit('renderWebViewer', val)
    }
  }
}
</script>

<style scoped lang="scss">
#webviewer {
  height: 75vh;
}
</style>
