<template>
  <v-card color="#425466" max-width="50px" style="padding: 6px;" class="dosage-control">
    <div class="dinner-supplements-count">
      <ul>
        <li
          v-for="dosageItem in dosages"
          :key="dosageItem.id"
          @click="updateDosage(dosageItem.value)"
        >
          <div
            class="square"
            :class="{ 'active': dosageItem.value ===  itemRow[propertyKey]}"
          >
            {{dosageItem.text}}
          </div>
        </li>
      </ul>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'dosage-control',
  props: {
    dosages: {
      type: Array
    },
    itemRow: {
      type: Object
    },
    propertyKey: {
      type: String
    }
  },
  methods: {
    updateDosage (value) {
      const data = {
        form: {
          ...this.itemRow,
          [this.propertyKey]: value
        },
        id: this.itemRow.id
      }
      this.$emit('update', { data: data, keyProp: this.propertyKey })
    }
  }
}
</script>

<style scoped lang="scss">
.dosage-control {
  .dinner-supplements-count ul {
    padding: 0;
    li {
      list-style-type: none;
      text-align: center;
      margin-bottom: 2px;
    }
    li:last-child {
      margin-bottom: 0;
    }
    .square {
      color: white;
      width: 38px;
      height: 38px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .square:hover {
      background-color: $light-dark-green;
    }
    .square.active {
      background-color: $dark-green;
    }
  }
}
</style>
