import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index'
import customers from '@/views/customers/customers'
import supplements from '@/views/supplements/supplements'
import protocols from '@/views/protocols/protocols'
import setup from '@/views/setup/setup'
import viewCustomer from '@/views/customers/view-customer'
import viewVisit from '@/views/visits/view-visit'
import confirmPassword from '@/views/confirm-password'

import unauth from '@/views/unauth'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  { path: '*', component: Index },
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '/customers',
        name: 'Customers',
        component: customers,
        meta: {
          headerTitle: 'Customers'
        },
        children: [
          {
            path: '/customers/:id',
            name: 'View customer',
            component: viewCustomer,
            meta: {
              headerTitle: 'Customer'
            }
          },
          {
            path: '/customers/visit/:id',
            name: 'View visit',
            component: viewVisit,
            meta: {
              headerTitle: 'Evaluation details'
            }
          }
        ]
      },
      {
        path: '/supplements',
        name: 'Supplements',
        component: supplements,
        meta: {
          headerTitle: 'Supplements'
        },
        children: [
          {
            path: '/supplements/:id',
            name: 'View supplements',
            component: viewCustomer,
            meta: {
              headerTitle: 'Supplements'
            }
          },
          {
            path: '/supplements/visit/:id',
            name: 'View visit',
            component: viewVisit,
            meta: {
              headerTitle: 'Evaluation details'
            }
          }
        ]
      },
      {
        path: '/protocols',
        name: 'Protocols',
        component: protocols,
        meta: {
          headerTitle: 'Protocols'
        }
      },
      {
        path: '/setup',
        name: 'Setup',
        component: setup,
        meta: {
          headerTitle: 'Setup'
        }
      }
    ]
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: unauth
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: unauth
  },
  {
    path: '/confirm-password/:id/:token/:type',
    name: 'ConfirmPassword',
    component: confirmPassword
  },
  {
    path: '/confirm/:id/:token',
    name: 'ConfirmAccount',
    component: unauth
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state?.isAuth && (to.name !== 'SignIn' && to.name !== 'SignUp' && to.name !== 'ConfirmAccount' && to.name !== 'ConfirmPassword')) {
    next({ name: 'SignIn' })
  } else {
    next()
  }
})

export default router
