<template>
  <div class="reports">
    <v-row no-gutters>
      <v-col cols="4">
        <v-row no-gutters>
          <div class="report-label">Perliminary Reports</div>
        </v-row>
        <div class="main-row mt-4">
          <div ref="clinicReport" class="btn-report" @click="getReport('clinicReport', 'test')">
            <span class="report-title">Clinician Report</span>
          </div>
          <div ref="patientSurveyFormDetailed" class="btn-report" @click="getReport('patientSurveyFormDetailed', 'test')">
            <span class="report-title">Patient Survey Form</span>
            <span class="bold">Detailed</span>
          </div>
          <div ref="patientSurveyFormSummary" class="btn-report" @click="getReport('patientSurveyFormSummary', 'test')">
            <span class="report-title">Patient Survey Form</span>
            <span class="bold">Summary</span>
          </div>
          <div ref="questionBasedSupportAnalysis" class="btn-report" @click="getReport('questionBasedSupportAnalysis', 'test')">
            <span class="report-title">Question Based Support Analysis</span>
          </div>
        </div>
      </v-col>
      <v-col cols="8">
        <v-row no-gutters>
          <div class="report-label">Final Reports</div>
        </v-row>
        <div class="main-row mt-4" style="justify-content: unset">
          <div class="test" style="width: 350px;display: flex;column-gap: 1rem;">
            <div ref="patientSchedule" class="btn-report" @click="getReport('patientSchedule', 'patient_schedule')">
              <span class="report-title">Patient Schedule</span>
            </div>
            <div class="btn-report" style="position: relative">
              <span class="select-label">Group Descriptions</span>
              <v-select
                :items="items"
                class="custom-select"
                :value="items[0]"
                solo
              ></v-select>
            </div>
          </div>
          <div ref="patientSurveyScoreSheet" class="btn-report d-flex align-center" @click="getReport('patientSurveyScoreSheet', 'patient_schedule')">
            <span class="report-title">Patient Survey Score Sheet</span>
            <div class="check-box-additional">
              <v-checkbox
                style="margin-top: 0;padding-top: 0"
                color="#008C89"
                v-model="includeCosts"
                :disabled="disabledIncludeCosts"
                label="Include Costs"
              ></v-checkbox>
            </div>
          </div>
          <div ref="surveyComparisonChart" class="btn-report align-center" @click="getReport('surveyComparisonChart', 'patient_schedule')">
            <div style="width: 345px;display: flex;">
              <span class="report-title">Survey Comparison Chart</span>
              <div class="check-box-additional">
                <v-checkbox
                  style="margin-top: 0;padding-top: 0"
                  color="#008C89"
                  v-model="printInColors"
                  :disabled="disabledPrintInColors"
                  label="Print in colors"
                ></v-checkbox>
              </div>
            </div>
            <div class="additional-filters mt-4">
              <v-checkbox
                style="margin-top: 0;padding-top: 0"
                color="#008C89"
                v-model="printInColors"
                :disabled="disabledPrintInColors"
                label="3/26/220"
              ></v-checkbox>
              <v-checkbox
                style="margin-top: 0;padding-top: 0"
                color="#008C89"
                v-model="printInColors"
                :disabled="disabledPrintInColors"
                label="12/30/2021"
              ></v-checkbox>
              <v-checkbox
                style="margin-top: 0;padding-top: 0"
                color="#008C89"
                v-model="printInColors"
                :disabled="disabledPrintInColors"
                label="12/30/2021"
              ></v-checkbox>
              <v-checkbox
                style="margin-top: 0;padding-top: 0"
                color="#008C89"
                v-model="printInColors"
                :disabled="disabledPrintInColors"
                label="12/30/2021"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <br>
    <ReportView
      :activeLanguage="activeLanguage"
      @switchLanguage="switchLanguage"
      v-if="dialogs.openReportPdfView && dialogs.openReportPdfView.isOpen && initialDoc"
      :initialDoc="initialDoc"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ReportView from '@/components/layouts/dialogs/reportView'

export default {
  name: 'reports',
  components: { ReportView },
  data: () => ({
    initialDoc: null,
    activeLanguage: 'en',
    items: [1, 2, 3, 4, 5],
    patientSchedule: null,
    includeCosts: false,
    disabledIncludeCosts: true,
    printInColors: false,
    disabledPrintInColors: true,
    fileUrl: null,
    file: null
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('reports', ['gerReportByCode']),
    openReport () {
      this.initialDoc = 'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: 'Report' })
    },
    switchLanguage (lang) {
      this.activeLanguage = lang
    },
    async getReport (ref, reportCode) {
      if (!this.$refs[ref].classList.contains('report-active')) {
        Array.from(document.getElementsByClassName('btn-report')).forEach(item => {
          item.classList.remove('report-active')
        })
        this.$refs[ref].classList.add('report-active')
        if (ref === 'patientSurveyScoreSheet') {
          this.disabledIncludeCosts = false
        }
        if (ref === 'surveyComparisonChart') {
          this.disabledPrintInColors = false
        }
        const payload = {
          appUserId: this.userProfile.userId,
          reportCode,
          entityId: this.$route.params.id
        }
        const res = await this.gerReportByCode(payload)
        this.file = res
        this.parseFile(this.file)
        // this.initialDoc = 'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'
        this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `Report ${this.upperCaseFirst(this.splitByUpperCase(ref))}` })
      } else {
        this.$refs[ref].classList.remove('report-active')
        this.disabledIncludeCosts = true
        this.disabledPrintInColors = true
      }
    },
    parseFile (file) {
      this.initialDoc = null
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.fileUrl = URL.createObjectURL(file)
          this.initialDoc = this.fileUrl
        }
        reader.readAsDataURL(file)
      } else {
        this.fileUrl = null
      }
    },
    splitByUpperCase (str) {
      return str.split(/(?=[A-Z])/).join(' ')
    },
    upperCaseFirst (str) {
      return str[0].toUpperCase() + str.slice(1)
    }
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
  .reports {
    padding: 16px!important;
    .language-switcher {
      border: 1px solid #f1f1f1;
      border-radius: 18px;
      padding: 4px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

      .button {
        ::v-deep .v-btn {
          background-color: rgba(255, 255, 255, 0.64) !important;
          color: black!important;
          font-weight: 600!important;
        }
      }
      .button.active-language {
        ::v-deep .v-btn {
          background-color: #4faa6e !important;
          color: white!important;
          font-weight: 600!important;
        }
      }
    }
    .report-label {
      font-weight: 600;
      font-size: 20px;
      color: #16192C;
    }

    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      justify-content: space-between;
      row-gap: 1rem;
      .btn-report {
        width: fit-content;
        height: fit-content;
        background: #F7FAFC;
        border-radius: 8px;
        padding: 7px;
        border: 2px solid transparent;
        cursor: pointer;
        .custom-select {
          width: 50px;
          height: 21px;
          ::v-deep .v-input__control {
            min-height: unset!important;
            .v-text-field__details {
              display: none!important;
            }
            .v-input__slot {
              height: 21px!important;
              background: transparent!important;
              box-shadow: unset!important;
              padding: 0!important;
            }
          }
        }
        .select-label {
          position: absolute;
          top: -27px;
          right: -50px;
          font-weight: 500;
          font-size: 12px;
          color: #718096;
          width: max-content;
        }
        .report-title {
          font-weight: 500;
          font-size: 14px;
          color: #425466;
        }
        .bold {
          font-weight: 600;
          padding-left: 5px;
        }
        .check-box-additional {
          margin-left: 20px;
          ::v-deep .v-input {
            .v-label {
              font-size: 14px!important;
              color: #425466!important;
            }
            .v-label.v-label--is-disabled {
              font-size: 14px!important;
              color: grey!important;
            }
          }
        }
        .additional-filters {
          display: flex;
          column-gap: 1rem;
        }
      }
      .btn-report.report-active {
        border: 2px solid $dark-green;
        background: $light-dark-green;
      }
    }
  }
</style>
