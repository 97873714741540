<template>
  <div class="login d-flex justify-center">
    <div class="login-area d-flex justify-center">
      <div class="login-area__form-wrapper d-flex flex-column">
        <div class="logo flex-grow-1 d-flex align-center">
          <Img :src="getImgWithExt(['images', 'logo_drawer', 'svg'])" width="172" />
        </div>
        <div class="login-area__form flex-grow-1">
          <login-form v-if="$route.name == 'SignIn'" />
          <comfirm-account v-if="$route.name == 'ConfirmAccount'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import loginForm from '@/components/layouts/forms/login'
import comfirmAccount from '@/views/confirm-account'
import Img from '@/components/elements/Img'

export default {
  name: 'login',
  mixins: [Image],
  components: { loginForm, comfirmAccount, Img },
  computed: {
    items () {
      return [
        {
          src: this.getImgWithExt(['images', 'slider', 'slide1', 'jpeg'])
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide2', 'jpeg'])
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide3', 'jpeg'])
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    .login-area,
    .slider-area {
      width: 50%;
      height: 100vh;
    }
    .login-area {
      background-color: #FFFFFF;
      @media (max-width: 960px) {
        width: 100%;
        padding: 0 30px;
      }
      &__form-wrapper {
        width: 439px;
      }
      &__form {
        width: 100%;
      }
    }
  }
</style>
