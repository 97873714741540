import Vue from 'vue'

const state = () => ({})

const getters = {}

const actions = {
  async importCSV ({ commit }, params) {
    const { data, url } = params
    const result = await Vue.$http.post(url, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async importData ({ commit }, params) {
    const { url } = params
    const result = await Vue.$http.post(`stripe/${url}`)
    if (result?.status === 200) {
      return result?.status
    }
  },
  async importFile ({ commit }, params) {
    const { data } = params
    const result = await Vue.$http.post('organization/import', data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    if (result?.status === 200) {
      return result?.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
