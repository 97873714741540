// import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import * as signalR from '@microsoft/signalr'

class ChatHub {
  constructor (client, token) {
    this.client = new signalR.HubConnectionBuilder().withUrl(process.env.VUE_APP_BACK_CHAT_HUB, // 'https://dev.kliksoft.services/hubs/chat',
      {
        transport: signalR.HttpTransportType.LongPolling,
        'content-type': 'application/json',
        accessTokenFactory: () => this.token
      })
      .withAutomaticReconnect([500, 1000, 1500, 2000, 3000])
      .configureLogging(signalR.LogLevel.Trace).build()
  }

  async start (token) {
    console.log('TRY CONNECTION...')
    this.token = token
    try {
      await this.client.start()
      console.log('SignalR CONNECTED.')
      // window.customControlHub = this.client
    } catch (err) {
      console.log('SignalR DISCONNECTED.')
      console.log(err)
      // setTimeout(() => this.client.start(), 2000)
    }
  }
}

export default new ChatHub()
