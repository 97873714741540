<template>
  <div class="add-patient">
    <Dialog width="568"><!--scrollable-->
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addForm">
          <Tabs fixed-tabs color="#008C89" :items="tabs" @update:tab="tabIndex = $event" style="margin-top: -4px"></Tabs>
          <template v-if="tabIndex == 0">
            <v-row style="margin-top: -20px!important;" class="mt-0">
              <v-col class="pb-0 pt-0">
                <div class="label">First name<span class="required-field">*</span></div>
                <Input outlined
                  placeholder="John"
                  radius="8"
                  @input="form.firstName=$event"
                  :value="form.firstName"
                  :rules="notEmtpy.concat([nameRulesWithType('First name')])"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Last name<span class="required-field">*</span></div>
                <Input outlined
                  placeholder="Walker"
                  radius="8"
                  @input="form.lastName=$event"
                  :value="form.lastName"
                  :rules="notEmtpy.concat([nameRulesWithType('Last name')])"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Email<span class="required-field">*</span></div>
                <Input outlined
                  placeholder="john.walker@gmail.com"
                  radius="8"
                  :disabled="form.registerPortal"
                  @blur="checkEmail"
                  @input="form.email=$event"
                  :value="form.email"
                  :rules="emailRules"
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-1 d-flex align-center" v-if="!isValidEmail">
                <div v-if="!isValidEmail">
<!--                  <v-progress-circular
                    class="mr-2"
                    indeterminate
                    color="error"
                  ></v-progress-circular>-->
                  <span class="red--text">Patient with this email already exists</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Assign to practitioner</div>
                <Select
                  v-if="doctors"
                  outlined
                  itemText="name"
                  itemValue="id"
                  :items="doctors"
                  :value="doctors[0]"
                  :disabled="doctors.length === 1 || userProfile.UserType === 'Doctor'"
                  @change="form.doctorAssigne = $event"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="mt-4 pb-0 pt-1">
                <div class="label">Gender<span class="required-field">*</span></div>
<!--                <custom-switch :btns="genderBtns" :value="radioGroup" @update:switch="radioGroup=$event" />-->
                <div class="d-flex add-patient__gender">
                  <v-checkbox
                    class="pt-1 pb-1"
                    color="#008C89"
                    v-model="form.sex"
                    value="Male"
                    @change="genderIsTouched = true"
                    label="Male"/>
                  <v-checkbox
                    color="#008C89"
                    class="pt-1 pb-1"
                    v-model="form.sex"
                    value="Female"
                    @change="genderIsTouched = true"
                    label="Female"/>
                </div>
                <div v-if="!form.sex && genderIsTouched" style="color: #ff5252;font-size: 12px;hyphens: auto;word-wrap: break-word;word-break: break-word;line-height: 12px">
                  This field is required
                </div>
              </v-col>
              <v-col class="mt-4 pb-0 pt-1">
                <div class="label">Birthdate<span class="required-field">*</span></div>
                <datepicker v-if="form.birthDate || dialog.name == 'addPatient'"
                  @date:update="form.birthDate = $event"
                  :value="form.birthDate && form.birthDate.substring(0, 10)"
                  :rules="notEmtpy"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 mb-2 pt-1">
                <div class="label">Dietary</div>
                <div class="d-flex add-patient__dietary">
                  <v-checkbox
                    class="pt-1 pb-1"
                    color="#008C89"
                    v-model="form.vegetarian"
                    label="Vegetarian"/>
                  <v-checkbox
                    color="#008C89"
                    class="pt-1 pb-1"
                    v-model="form.glutenFree"
                    label="Gluten free"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0" v-if="false">
              <v-col>
                <v-switch v-if="dialog.name == 'editPatient'"
                  label="Active"
                  :input-value="toggleActivate"
                  @change="toggleActivate=$event"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="tabIndex == 1">
            <v-row style="margin-top: -20px!important;" class="mt-0">
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Cell Phone</div>
                <PhoneInput
                  radius="8"
                  :value="form.cellPhone"
                  @input="form.cellPhone=$event"
                />
              </v-col>
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Home Phone</div>
                <PhoneInput
                  radius="8"
                  :value="form.homePhone"
                  @input="form.homePhone=$event"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Work Phone</div>
                <PhoneInput
                  radius="8"
                  :value="form.workPhone"
                  @input="form.workPhone=$event"
                />
              </v-col>
              <v-col cols="6" class="pt-1 add-patient__language">
                <div class="label">Language</div>
                <v-checkbox
                  class="pt-1 pb-1"
                  color="#008C89"
                  v-model="form.spanish"
                  label="Spanish"/>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label pb-0">Address</div>
                <Input outlined
                  radius="8"
                  placeholder="Vicoria Road, 34"
                  :value="form.line1"
                  @input="form.line1=$event"
                  />
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 d-flex align-center">Line 2</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line2=$event" :value="form.line2" />
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 Sd-flex align-center">Line 3</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line3=$event" :value="form.line3" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">City</div>
                <Input outlined
                  radius="8"
                  :value="form.city"
                  @input="form.city=$event"
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">State</div>
                <Input outlined
                  radius="8"
                  :value="form.state"
                  @input="form.state=$event"
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">Zip</div>
                <Input outlined
                       radius="8"
                       placeholder="XXXXX"
                       :value="form.zip"
                       @input="form.zip=$event"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <template v-if="dialog.name === 'addPatient'">
              <v-row class="ml-2 mt-0" style="margin-top: -20px!important;">
                <v-col class="pb-0 mt-2 pt-1">
                  <div class="d-flex align-center add-patient__visit-invitation">
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.visitInvitation"
                      label="Invite to Evaluation"/>
<!--                    <v-icon color="#BCB3E2">mdi-help-circle</v-icon>-->
                  </div>
                </v-col>
              </v-row>
              <v-row class="align-center ml-2 mt-0">
                <v-col class="pb-0 pt pt-1">
                  <div class="add-patient__evaliation-date">
                    <div class="label">Add Evaluation Date</div>
                  </div>
                </v-col>
                <v-col class="pb-0 pt-1 d-flex align-end">
                  <datepicker
                    :min="nowDate"
                    v-model="form.plannedVisitDate"
                    @date:update="form.plannedVisitDate = $event" />
                </v-col>
              </v-row>
              <v-row class="ml-2 mt-0">
                <v-col class="pb-0 pt-1">
                  <div class="label mb-1">Short Invitation Message</div>
                  <Textarea
                    dense
                    auto-grow
                    :rows="3"
                    outlined
                    @input:text-area="form.visitInvitationMessage=$event"
                    :value="form.visitInvitationMessage"
                    :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                  />
                  <div class="mt-1 short-message">
                    <span>Patient will recieve email with <span class="bold">instruction</span></span>
                  </div>
                </v-col>
              </v-row>
<!--              -->
              <v-row class="ml-2 mt-0">
                <v-col class="pb-0 mt-2">
                  <div class="d-flex align-center add-patient__register-portal">
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.registerPortal"
                      label="Invite to the portal"/>
<!--                    <v-icon color="#BCB3E2">mdi-help-circle</v-icon>-->
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-3 ml-2 mt-0">
                <v-col class="pb-0 pt-1">
                  <div class="label mb-1">Short Invitation Message</div>
                  <Textarea
                    dense
                    auto-grow
                    outlined
                    :rows="3"
                    @input:text-area="form.registerPortalMessage=$event"
                    :value="form.registerPortalMessage"
                    :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                  />
                  <div class="mt-1 short-message">
                    <span>Patient will recieve email with <span class="bold">instruction to register on Patient Portal</span></span>
                  </div>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-form>
      </template>
      <template slot="actions" class="pt-1">
        <div class="w100 d-flex justify-space-between pt-0 py-4 pl-3 pr-3 align-center">
          <div class="legend-errors">
            <span class="required-field">*</span> - <span class="info-text">Required fields</span>
          </div>
          <Button
            btn-bg="dark-green"
            @click.native="add"
            :disabled="!form.firstName || !form.firstName || !form.email || !form.birthDate || !form.sex"
            v-if="dialog.name == 'addPatient'">
            <span style="color: white">Add Patient</span>
          </Button>
          <Button btn-bg="dark-green" @click.native="update" v-if="dialog.name == 'editPatient'">
            <span style="color: white">Update</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Validation from '@/mixins/Validation'
import Dialog from '@/components/layouts/dialog'
import Input from '@/components/elements/Input'
import Select from '@/components/elements/Select'
import PhoneInput from '@/components/elements/phone-input'
import datepicker from '@/components/elements/datepicker'
import Button from '@/components/elements/Button'
import Tabs from '@/components/layouts/Tabs'
// import customSwitch from '@/components/elements/custom-switch'
import Textarea from '@/components/elements/Textarea'

const GENDER = [{ name: 'Male' }, { name: 'Female' }]

export default {
  name: 'add-patient',
  props: {
    query: {
      type: Object
    }
  },
  mixins: [Validation],
  components: {
    Dialog,
    Input,
    Select,
    datepicker,
    PhoneInput,
    Button,
    Tabs,
    Textarea
  },
  data: () => ({
    radioGroup: null,
    toggleActivate: true,
    isValidEmail: true,
    genderIsTouched: false,
    form: {
      firstName: '',
      lastName: '',
      email: null,
      doctorAssigne: null,
      sex: null,
      vegetarian: false,
      glutenFree: false,
      birthDate: null,
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      city: null,
      state: null,
      zip: null,
      homePhone: null,
      workPhone: null,
      cellPhone: null,
      number: null,
      spanish: false,
      visitInvitation: false,
      plannedVisitDate: new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString('en-US'),
      registerPortal: false,
      visitInvitationMessage: null,
      registerPortalMessage: null
    },
    tabIndex: 0,
    nowDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10)
    // endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()).toISOString().slice(0, 10)
  }),
  async created () {
    this.getDoctors()
    if (this.dialog.name === 'editPatient') {
      await this.getPatient({ id: this.dialog.id })
      this.$set(this.$data, 'radioGroup', this.patient.sex === 'Female' ? 1 : 0)
      this.form = Object.assign({}, this.patient)
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', [
      'getDoctors',
      'addPatient',
      'vailidateEmail',
      'getPatients',
      'getPatient',
      'updatePatient'
    ]),
    async add () {
      if (this.validate()) {
        const res = await this.addPatient(Object.assign(this.form, { plannedVisitDate: this.$moment(this.form.plannedVisitDate).format('MM/DD/YYYY') }))
        this.$emit('showPopup', { type: 'add-patient', data: res })
        await this.getPatients(this.query)
      }
    },
    async update () {
      if (this.validate()) {
        const res = await this.updatePatient({ id: this.dialog.id, form: this.form })
        this.$emit('showPopup', { type: 'edit-patient', data: res })
        await this.getPatients(this.query)
      }
    },
    async checkEmail () {
      const { email } = this.form
      const params = { email }
      if (this.dialog.name === 'addPatient') {
        params.patientId = ''
      } else {
        params.patientId = this.dialog.id
      }
      const res = await this.vailidateEmail(params)
      this.setDynamicValidationRules({
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          res.data.validationResults.isValid || res.data.message
        ]
      })
      // this.$set(this.$data, 'isValidEmail', res.data.validationResults.isValid)
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    patient: state => state.patients?.patient,
    genderBtns: () => GENDER,
    tabs () {
      return (this.dialog.name === 'addPatient') ? [
        { title: 'Personal Detais', component: 'Personal Detais' },
        { title: 'Contact Details', component: 'Contact Details' },
        { title: 'Add Evaluation', component: 'Add Evaluation' }
      ] : [
        { title: 'Personal Detais', component: 'Personal Detais' },
        { title: 'Contact Details', component: 'Contact Details' }
      ]
    },
    userProfile: state => state.userProfile
  }),
  watch: {
    radioGroup (val) {
      this.form.sex = GENDER[val].name
    },
    tabIndex (val) {
      if (val !== 0 && val !== 1) {
        this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
        this.form.registerPortalMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease join out Patient Portal to manage your surveys`
      }
    },
    'form.plannedVisitDate' (val) {
      this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .add-patient {
    ::v-deep .v-dialog__container {
      .v-card {
        min-height: 630px;
        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
      }
    }
    ::v-deep .tabs {
      .v-window {
        display: none;
      }
    }
    ::v-deep .checkbox {
      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    &__gender {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__dietary {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__language {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__gender,
    &__dietary,
    &__language,
    &__visit-invitation,
    &__register-portal,
    &__evaliation-date {
      ::v-deep .v-input {
        padding: 10px 13px 10px 13px;
        label {
          font-size: 14px;
          font-weight: 500;
        }
        &:hover {
          background: #F7FAFC;
          border-radius: 8px;
        }
      }
    }
    ::v-deep .select .v-text-field {
      &__details {
        display: none;
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0;
      }
      .v-messages {
        //display: none;
      }
     &--selection-controls {
       margin: 0;
     }
    }
    .short-message {
      .bold {
        font-weight: 600;
      }
    }
    .required-field {
      color: #CF4655;
    }
    .legend-errors {
      .info-text {
        font-size: 16px;
        font-weight: 500;
        color: #425466;
      }
    }
  }
</style>
