<template>
  <div class="stripe">
    <v-container fluid>
      <template v-if="!isLoading">
        <v-row>
          <v-col class="px-10 pb-0" cols="12">
            <div class="d-flex">
              <div class="d-flex align-center flex-grow-1 justify-start">
                <Button class="mr-6"
                        @click.native="importFile('Data','synchronize')"
                        btn-bg="dark-green"
                >
                <span style="color: #FFFFFF">
                  <v-icon>mdi-file-import</v-icon>
                  Synchronize Products
                </span>
                </Button>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-10 pb-10 pr-10">
            <Table v-if="products" class="stripe-table"
                   hide-default-footer
                   :headers="headerAvailableProducts"
                   @update:option="actionTableData"><!--:items="items"-->

<!--              <template v-slot:item:logoUrl={item}>
                <div class="d-flex">
                  <div style="width: 200px">
                    <v-img :src="item.logoUrl"></v-img>
                  </div>
                </div>
              </template>

              <template v-slot:item:name={item}>
                <div class="d-flex">
                  <span>{{item.name}}</span>
                </div>
              </template>

              <template v-slot:item:description={item}>
                <div class="d-flex">
                  <span style="font-size: 13px">{{item.description}}</span>
                </div>
              </template>

              <template v-slot:item:features={item}>
                <div class="d-flex">
                  <span>{{item.features || '-'}}</span>
                </div>
              </template>

              <template v-slot:item:monthPriceAmount={item}>
                <div class="d-flex">
                  <span>${{item.monthPriceAmount}}</span>
                </div>
              </template>

              <template v-slot:item:yearPriceAmount={item}>
                <div class="d-flex">
                  <span>${{item.yearPriceAmount}}</span>
                </div>
              </template>

              <template v-slot:footer>
                <div class="footer pl-5 d-flex align-center">
                  <div class="row-per-page d-flex align-center flex-grow-1">
                    <span class="row-per-page__lable mr-4">Rows per page: </span>
                    <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[1]" @change="changeItermPerPage($event, products)" />
                  </div>
                  <div class="flex-grow-2">
                    <pagination v-model="page" :value="page" :length="pageCount" :totalVisible="7" @input="changePagination" />
                  </div>
                  <div class="d-flex align-center justify-end flex-grow-1 mr-6">
                  </div>
                </div>
              </template>-->

              <template v-slot:body>
                <template v-for="item in products"><!--items-->
                  <tr
                    :key="item.id"
                    class="stripe-row-table"
                    :class="{ 'is-legacy': item.isLegacy }"
                  >
                    <td>
                      <div class="d-flex justify-space-between align-center px-2 py-1">
                        <div style="width: 200px;margin-right: 10px">
                          <v-img :src="item.logoUrl"></v-img>
                        </div>
                        <v-tooltip top v-if="item.isLegacy">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="success" v-bind="attrs" v-on="on">mdi-scale-balance</v-icon>
                          </template>
                          <span>Legacy</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.isCloud" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on">mdi-apple-icloud</v-icon>
                          </template>
                          <span>Cloud</span>
                        </v-tooltip>
                        <v-tooltip top v-if="item.isTrial" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="warning" v-bind="attrs" v-on="on">mdi-factory</v-icon>
                          </template>
                          <span>Trial</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-center">
                        <span>{{item.name}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-center">
                        <span style="font-size: 13px">{{item.description}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-center">
                        <span>{{item.features || '-'}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-center">
                        <span>${{item.monthPriceAmount}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-center">
                        <span>${{item.yearPriceAmount}}</span>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <!--<template v-slot:footer>
                <div class="footer pl-5 d-flex align-center">
                  <div class="row-per-page d-flex align-center flex-grow-1">
                    <span class="row-per-page__lable mr-4">Rows per page: </span>
                    <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[1]" @change="changeItermPerPage($event, products)" />
                  </div>
                  <div class="flex-grow-2">
                    <pagination v-model="page" :value="page" :length="pageCount" :totalVisible="7" @input="changePagination" />
                  </div>
                  <div class="d-flex align-center justify-end flex-grow-1 mr-6">
                  </div>
                </div>
              </template>-->
            </Table>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
      <div class="custom-alert">
        <v-alert
          color="#7ac1fb"
          dismissible
          type="info"
          v-model="alertIsOpen"
        >
          <span style="font-size: 14px;">{{popupMessage}}</span>
        </v-alert>
      </div>
    </v-container>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'
import { mapActions, mapState } from 'vuex'
import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
// import pagination from '@/components/layouts/pagination'
// import Select from '@/components/elements/Select'
import Table from '@/components/elements/Table'

export default {
  name: 'stripe',
  mixins: [Pagination, Mapping],
  components: { Button, Table }, // pagination, Select
  data: () => ({
    popupMessage: null,
    alertIsOpen: false,
    isLoading: false
  }),
  async mounted () {
    const res = await this.getProducts()
    if (res) {
      // this.setupPagination(this.products)
    }
  },
  methods: {
    ...mapActions('imports', ['importData']),
    ...mapActions('subscriptions', ['getProducts']),
    actionTableData ($event) {
      if ($event.sortBy.length) {
        const key = $event.sortBy[0]
        this.items.sort((a, b) => {
          if ($event.sortDesc[0]) {
            return b[key] - a[key] ? 1 : -1
          } else {
            return a[key] - b[key] ? 1 : -1
          }
        })
      }
    },
    async importFile (title, url) {
      this.isLoading = true
      const res = await this.importData({ url })
      if (res === 200) {
        this.showPopup(title)
      } else {
        this.isLoading = false
      }
    },
    showPopup (title) {
      this.alertIsOpen = true
      this.popupMessage = `${title} was synchronized successfully.`
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      products: state => state.subscriptions.products
    }),
    headerAvailableProducts () {
      return [
        {
          text: 'Logo',
          value: 'logoUrl',
          sortable: false,
          isSlotable: true,
          width: '16.6%'
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          isSlotable: true,
          width: '16.6%'
        },
        {
          text: 'Description',
          value: 'description',
          sortable: true,
          isSlotable: true,
          width: '16.6%'
        },
        {
          text: 'Features',
          value: 'features',
          sortable: true,
          isSlotable: true,
          width: '16.6%'
        },
        {
          text: 'Month price amount',
          value: 'monthPriceAmount',
          sortable: true,
          isSlotable: true,
          width: '16.6%'
        },
        {
          text: 'Year price amount',
          value: 'yearPriceAmount',
          sortable: true,
          isSlotable: true,
          width: '16.6%'
        }
      ]
    },
    rowPerPageItems: () => ([5, 10, 15, 20])
  }
}
</script>

<style scoped lang="scss">
.stripe {
  width: 100%;
  background-color: #F7FAFC;
  height: 100%;
  ::v-deep .stripe-table {
    .v-data-table .v-data-table__wrapper {
      .v-data-table-header {
        tr {
          th {
            div {
              //justify-content: center!important;
              //float: unset!important;
            }
          }
        }
      }
      .stripe-row-table.is-legacy {
        background-color: #f1f1f1;
      }
    }
  }
  ::v-deep .v-btn {
    border-radius: 8px;
  }
  .loading {
    height: inherit;
  }
  ::v-deep .v-text-field {
    margin: 0;
    padding: 0;
    &__details {
      //display: none;
      margin-bottom: 4px;
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        // display: none;
      }
      &__control {
        .v-text-field__details {
          display: none!important;
        }
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
}
</style>
