import Vue from 'vue'

const state = () => ({
  organization: null,
  doctors: null,
  patientsFollowUpReminders: null,
  patientsRecentlyViewed: null,
  settingFilters: null,
  auditEvents: null
})

const getters = {}

const actions = {
  async getCurrentOrganizationProfile ({ commit }) {
    const result = await Vue.$http.get('/Organization/Profile')
    if (result?.status === 200) {
      commit('SET_DATA', ['organization', result.data])
    }
  },
  async updateCurrentOrganizationProfile ({ commit }, params) {
    const { doctor, organization } = params
    const result = await Vue.$http.put('/Organization/Profile/Update', { doctor, organization })
    if (result?.status === 200) {
      commit('SET_DATA', ['organization', result.data])
      return result?.data
    }
  },
  async updateOrganization ({ _ }, params) {
    const { id } = params
    const result = await Vue.$http.put(`/Organization/${id}/Update`, { ...params })
    if (result?.staus === 200) {
      console.log(params)
      console.log(result)
    }
  },
  async inviteDoctor ({ commit }, params) {
    const result = await Vue.$http.post('/organization/InviteDoctor', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return result?.data
  },
  async invitePatientToThePortal ({ _ }, params) {
    const result = await Vue.$http.post(`/Organization/InvitePatient?id=${params.patientId}&notes=${params.notes}`)
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return result?.data
  },
  async getListOfDoctors ({ commit }) {
    const result = await Vue.$http.get('/Organization/Doctors')
    if (result?.status === 200) {
      commit('SET_DATA', ['doctors', result.data])
    }
  },
  async onActivateDoctor ({ _ }, params) {
    const { id } = params
    const result = await Vue.$http.post(`/Doctors/${id}/Activate`)
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return result?.data
  },
  async onDeactivateDoctor ({ _ }, params) {
    const { id } = params
    const result = await Vue.$http.post(`/Doctors/${id}/Deactivate`)
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      return true
      // router.push('/').catch(() => {})
    }
    return result?.data
  },
  async onResendInvitationDoctor ({ _ }, params) {
    const { id } = params
    const result = await Vue.$http.post(`/Doctors/${id}/ResendInvitation`)
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return result?.data
  },
  async getPatientsFollowUpReminders ({ commit }) {
    const result = await Vue.$http.get('/Doctors/dashboard/follow-up-reminders')
    if (result?.status === 200) {
      commit('SET_DATA', ['patientsFollowUpReminders', result.data])
    }
  },
  async getPatientsRecentlyViewed ({ commit }) {
    const result = await Vue.$http.get('/Doctors/dashboard/recently-viewed')
    if (result?.status === 200) {
      commit('SET_DATA', ['patientsRecentlyViewed', result.data])
    }
  },
  async getSettingFilters ({ commit }) {
    const result = await Vue.$http.get('/Organization/Settings')
    if (result?.status === 200) {
      commit('SET_DATA', ['settingFilters', result.data])
      return true
    }
  },
  async updateSettingFilters ({ commit }, params) {
    const result = await Vue.$http.put('/Organization/Settings', { ...params })
    if (result?.status === 200) {
      return true
    }
  },
  async getAuditEvents ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.get(`/Organization/${id}/audit-events`)
    if (result?.status === 200) {
      commit('SET_DATA', ['auditEvents', result.data.data.$values])
      return true
    }
  },
  async sendDesktopLink ({ commit }, params) {
    const result = await Vue.$http.post('/organization/send-link', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return true
  },
  async sendConfirmEmail ({ commit }, params) {
    const result = await Vue.$http.post('/organization/confirm-email', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
      return result.data
    } else {
      return false
    }
  },
  async sendPasswordUpdate ({ commit }, params) {
    const result = await Vue.$http.post('/organization/password-update', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
      return true
    } else {
      return false
    }
  },
  async sendPasswordReset ({ commit }, params) {
    const result = await Vue.$http.post('/organization/password-reset', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
      return true
    } else {
      return false
    }
  },
  async sendChangeEmail ({ commit }, params) {
    const result = await Vue.$http.post('/organization/email-change', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The email has been changed; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
      return result.data
    } else {
      return false
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
