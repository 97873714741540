import { render, staticRenderFns } from "./notes-dialog.vue?vue&type=template&id=205a4892&scoped=true&"
import script from "./notes-dialog.vue?vue&type=script&lang=js&"
export * from "./notes-dialog.vue?vue&type=script&lang=js&"
import style0 from "./notes-dialog.vue?vue&type=style&index=0&id=205a4892&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205a4892",
  null
  
)

export default component.exports