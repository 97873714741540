<template>
  <div class="edit-protocol-dialog">
    <Dialog width="450">
      <template slot="title">
        <span>{{editProtocolDialog.isOpen ? editProtocolDialog.title : addProtocolDialog.title}}</span>
      </template>

      <template slot="body">
        <div class="edit-protocol-dialog__form">
          <v-form ref="form" v-model="valid" lazy-validation id="edit-protocol" class="pt-4 pb-4 px-4">
            <v-row>
              <v-col class="py-0">
                <div class="label">Name</div>
                <Input outlined
                       radius="8"
                       :value="!addProtocolDialog.isOpen ? editProtocolDialog.data.name : form.name"
                       @input="form.name=$event"
                       :rules="notEmtpy"
                />
              </v-col>
            </v-row>
            <v-row class="flex align-center pl-3">
              <div class="mr-4">Select Brand </div>
              <Select
                outlined
                itemText="name"
                itemValue="code"
                radius="8"
                :items="brands"
                :value="form.brandsCode"
                @change="form.brandsCode = $event"
              />
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="label">Notes</div>
<!--                <Input outlined
                       radius="8"
                       :value="!addProtocolDialog.isOpen ? editProtocolDialog.data.notes : form.notes"
                       @input="form.notes=$event"
                       :rules="[notEmtpy]"
                />-->
                <Textarea dense
                          rows="3"
                          rows-height="3"
                          no-resize
                          outlined
                          :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                          @input:text-area="form.notes=$event"
                          :value="!addProtocolDialog.isOpen ? editProtocolDialog.data.notes : form.notes" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="label">Status</div>
                <v-switch class="py-0 my-0" v-if="!addProtocolDialog.isOpen" v-model="form.active" @input="form.active = $event" color="#008C89" inset label="Inactive/Active" />
                <v-switch class="py-0 my-0" v-else v-model="form.active" color="#008C89" inset label="Active" />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            v-if="!addProtocolDialog.isOpen"
            btn-bg="dark-green"
            @click.native="update"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Update</span>
          </Button>
          <Button
            v-else
            btn-bg="dark-green"
            @click.native="create"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Create</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Validation from '@/mixins/Validation'
import Input from '@/components/elements/Input'
import Textarea from '@/components/elements/Textarea'
import Select from "@/components/elements/Select.vue";

export default {
  name: 'edit-protocol-dialog',
  components: { Dialog, Button, Input, Textarea, Select },
  mixins: [Validation],
  data: () => ({
    form: {
      name: '',
      notes: '',
      active: true,
      brandsCode: null,
      id: ''
    }
  }),
  async mounted () {
    await this.getBrands()
    if (this.editProtocolDialog.isOpen) {
      this.form = Object.assign(this.form, this.editProtocolDialog.data)
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('protocols', ['updateProtocol', 'addProtocol']),
    ...mapActions('brands', ['getBrands']),
    async update () {
      this.form.id = this.editProtocolDialog.id
      Object.keys(this.form).map(key => {
        if (this.form[key] === '') {
          delete this.form[key]
        }
      })
      const res = await this.updateProtocol(this.form)
      if (res.record) {
        const { afterAction } = this.editProtocolDialog
        await this.$store.dispatch(afterAction.name, afterAction.protocol)
        this.TOGGLE_DIALOG({ name: 'editProtocolDialog', isOpen: false })
      }
    },
    async create () {
      const res = await this.addProtocol(this.form)
      if (res.record) {
        const { afterAction } = this.addProtocolDialog
        await this.$store.dispatch(afterAction.name, afterAction.protocol)
        this.TOGGLE_DIALOG({ name: 'addProtocolDialog', isOpen: false })
      }
    },
    close () {
      console.log('CLOSE DIA')
      this.TOGGLE_DIALOG({ name: this.editProtocolDialog.isOpen ? this.editProtocolDialog.name : this.addProtocolDialog.name, isOpen: false })
    }
  },
  computed: {
    ...mapState({
      editProtocolDialog: state => state.dialogs.editProtocolDialog,
      addProtocolDialog: state => state.dialogs.addProtocolDialog,
      brands: state => state.brands.brands || []
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.edit-protocol-dialog {
  &__form {
    display: flex;
    justify-content: center;
    width: 91%;
    margin: 0 auto;
    #edit-protocol {
      width: 100%;
    }
    .select {
      width: 60%!important;
      ::v-deep .v-input {
        .v-input__control {
          height: 38px!important;
        }
      }
    }
  }
  //::v-deep {
  //  .v-card__title {
  //    position: relative!important;
  //    .v-icon {
  //      position: absolute!important;
  //      right: 15px;
  //      top: 0;
  //    }
  //  }
  //}
  //.title {
  //  width: 100%;
  //  text-align: center;
  //}
  //.description {
  //  padding: 0 16px;
  //}
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        background: #edf2f7;
        padding: 25px 30px 17px 46px !important;
        justify-content: center!important;
        position: relative;
        font-size: 25px !important;
        font-weight: 600 !important;
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
        .v-input {
          .v-text-field__details {
            display: block!important;
            margin-bottom: 0!important;
            .v-messages {
              display: block!important;
            }
          }
        }
      }
    }
  }
}
</style>
