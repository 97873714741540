<template>
  <v-card width="458"
          elevation="6"
          class="pwd-requirements">
    <v-container class="pa-5">
      <div class="title">Password must: </div>
      <v-progress-linear :value="progressValue.length * (100 / passwordRequirementsRules.length)" color="#4faa6e" class="progress-line"></v-progress-linear>
      <v-list class="pwd-list">
        <v-list-item v-for="(item, i) in passwordRequirementsRules"
                     :key="i">
          <v-list-item-icon class="pwd-icon">
            <v-icon v-text="item.icon" :disabled="!item.passedValidation" :class="{'color-green': item.passedValidation}"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="pwd-content">
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
import { MIN_PASS_LENGTH } from '@/constants/const'

export default {
  name: 'PasswordRequirements',
  props: {
    verifiablePassword: {
      type: String,
      default: '' || null
    },
    rules: {
      type: Array,
      default: null
    },
    minPwdLength: {
      type: Number,
      default: MIN_PASS_LENGTH
    }
  },
  data: () => ({
    defaultRules: [
      {
        text: 'Be at least 8 characters long',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one uppercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one lowercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one number',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one special characters (!@#)',
        icon: 'mdi-check-circle',
        passedValidation: false
      }
    ]
  }),
  computed: {
    progressValue () {
      return this.passwordRequirementsRules.filter(item => {
        return item.passedValidation
      })
    },
    passwordRequirementsRules () {
      if (this.rules && this.rules.length > 0) {
        return this.rules
      } else {
        return this.defaultRules
      }
    }
  },
  watch: {
    verifiablePassword (val) {
      if (val && val.length >= this.minPwdLength) {
        this.passwordRequirementsRules[0].passedValidation = true
      } else {
        this.passwordRequirementsRules[0].passedValidation = false
      }
      if (val && /[A-Z]/.test(val)) {
        this.passwordRequirementsRules[1].passedValidation = true
      } else {
        this.passwordRequirementsRules[1].passedValidation = false
      }
      if (val && /[a-z]/.test(val)) {
        this.passwordRequirementsRules[2].passedValidation = true
      } else {
        this.passwordRequirementsRules[2].passedValidation = false
      }
      if (val && /[0-9]/.test(val)) {
        this.passwordRequirementsRules[3].passedValidation = true
      } else {
        this.passwordRequirementsRules[3].passedValidation = false
      }
      if (val && /[!|@|#]/.test(val)) {
        this.passwordRequirementsRules[4].passedValidation = true
      } else {
        this.passwordRequirementsRules[4].passedValidation = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pwd-requirements {
  border-radius: 16px;
  .title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .progress-line {
    margin: 10px 0;
  }
  .pwd-list {
    padding: 0 !important;
    .v-list-item {
      min-height: 32px !important;
    }
    .pwd-icon {
      margin: 0 14px 0 0 !important;
      align-self: center;
    }
    .pwd-content {
      padding: 0 !important;
    }
  }
}
.color-green {
  color: #4faa6e;
}
</style>
