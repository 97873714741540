<template>
<div class="protocols">
  <template v-if="$route.name === 'Protocols'">
    <div class="main-row">
      <div class="protocols__total d-flex align-center mr-10"><span class="num mr-2">{{total}}</span> Protocols</div>
      <div class="d-flex align-center flex-grow-1 justify-end">
        <Input class="mr-2"
               style="margin-top: 17px"
               outlined
               radius="8"
               appendIcon="mdi-magnify"
               placeholder="Search"
               @input="inputSearch"
               :value="searchedText"
               @enter="getProtocols(query)"
        />
        <Button btn-bg="dark-green"
                class="mr-2"
                @click.native="refresh"
                :styles="{'border-radius': '8px'}"
        >
                <span style="color: #FFFFFF">
                  Refresh
                </span>
        </Button>
        <Button btn-bg="dark-green" :styles="{'border-radius': '8px'}"
                @click.native="createNewProtocol">
                      <span style="color: #FFFFFF">
                        <v-icon>mdi-plus</v-icon>
                        Add protocol
                      </span>
        </Button>
      </div>
    </div>
    <div class="main-row" style="position:relative;">
      <Table
        :headers="headers"
        :items="protocolItems"
        class="protocol-table"
        @update:option="optionTableData($event, 'asc')"
        @filterBox="filterBox"
        @resetFilter="resetFilter"
        hideDefaultFooter>

        <template v-slot:body>
          <template v-for="(item, index) in protocolItems">
            <tr
              :key="item.id"
              class="protocol-table__row"
              @mouseover="rowIsHovered = item.id"
              @mouseleave="rowIsHovered = false"
              :class="{ 'expand-row': expandedRows.includes(item.id), 'inactive-protocol': !item.active }"
              @click="toggleRow(item.id)"
            >
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <div class="d-flex align-center width100 height100 justify-space-between">
                  <div class="name d-flex align-center">
                    <span class="font-weight-bold" v-if="!searchedText">{{item.name }}</span>
                    <span class="font-weight-bold" v-else v-html="highlightSearchedText(item.name, searchedText)"></span>
                    <v-tooltip top v-if="item.organizationId">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue" v-bind="attrs" v-on="on">mdi-account-check</v-icon>
                      </template>
                      <span>This is my custom protocol</span>
                    </v-tooltip>
                  </div>
                  <div v-show="rowIsHovered === item.id"><v-icon>mdi-chevron-down</v-icon></div>
                </div>
              </td>
              <!--              <td class="notes px-3 pt-1 pb-1">
                              <div class="d-flex align-center width100 height100">
                                <v-icon :color="item.active ? '#008C89' : '#F16063'">{{item.active ? 'mdi-check' : 'mdi-close'}}</v-icon>
                              </div>
                            </td>-->
              <td class="notes px-3 pt-1 pb-1">
                <div class="d-flex align-center width100 height100">
                  <span class="font-weight-bold">{{item.notes }}</span>
                </div>
              </td>
              <td class="notes px-3 pt-1 pb-1" @click.stop>
                <div class="d-flex align-center width100 height100">
                  <actions :items="actions"
                           @action="runAction"
                           :item="item"
                  />
                </div>
              </td>
            </tr>
            <td colspan="100%"
                class="product-supplement"
                :key="index + item.id"
                v-if="expandedRows.includes(item.id)">
              <v-card elevation="2">
                <Table
                  :headers="headersSupplement"
                  hideDefaultFooter>

                  <template v-slot:body>
                    <template v-for="sup in item.supplements.$values">
                      <tr
                        :key="sup.id"
                        class="custom-result-table__dynamic"
                        :class="{'selected-row': sup.selected}"
                      >
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center selected">
                            <v-checkbox
                              style="margin-top: 0;padding-top: 0"
                              color="#008C89"
                              @change="handlerUpdateProtocolSelect(sup.id, $event)"
                              v-model="sup.selected"
                            ></v-checkbox>
                          </div>
                        </td>
                        <td class="pa-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                          <div class="d-flex align-center justify-space-between">
                            <div class="d-flex">
                              {{sup.supplementName}}
                            </div>
                            <div class="d-flex">
                              <v-tooltip top color="#75DC98" v-if="item.manuallyEntered">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot light-green" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Manually entered</span>
                              </v-tooltip>
                              <v-tooltip top color="#F7936F" v-if="sup.fromProtocol">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot light-orange" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Protocol</span>
                              </v-tooltip>
                              <v-tooltip top color="#28605A" v-if="sup.fromBlood">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot dark-green" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Blood</span>
                              </v-tooltip>
                              <v-tooltip top color="#499D66" v-if="sup.fromBloodPattern">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot green" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Blood pattern</span>
                              </v-tooltip>
                              <v-tooltip top color="#F16063" v-if="sup.fromBloodMarkerLab">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot dark-orange" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Blood marker lab</span>
                              </v-tooltip>
                              <v-tooltip top color="#00E0FF" v-if="sup.fromBloodMarkerOptimal">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot light-blue" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>Blood marker optimal</span>
                              </v-tooltip>
                              <v-tooltip top color="#537CFF" v-if="sup.onlyOneBottle">
                                <template v-slot:activator="{ on, attrs }">
                                  <div class="circle-dot blue" v-bind="attrs" v-on="on"></div>
                                </template>
                                <span>One bottle</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.vegetarian">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="success" v-bind="attrs" v-on="on">mdi-leaf</v-icon>
                                </template>
                                <span>Is Vegetarian</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.glutenFree" >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="warning" v-bind="attrs" v-on="on">mdi-barley</v-icon>
                                </template>
                                <span>Is Gluten Free Dietary</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.discontinued">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs"
                                          v-on="on" color="#F16063">mdi-alert-circle</v-icon>
                                </template>
                                <span>Supplements is discontinued</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.warningLowBP" >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" style="padding-left: 3px">LBP</span>
                                </template>
                                <span>Warning Low BP</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.warningHighBP" >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" style="padding-left: 3px">HBP</span>
                                </template>
                                <span>Warning High BP</span>
                              </v-tooltip>
                              <v-tooltip top v-if="sup.warningBP" >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on" style="padding-left: 3px">BP</span>
                                </template>
                                <span>Warning BP</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageUponArising || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageBreakfast || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosage10am || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageLunch || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosage3pm || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageDinner || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosage7pm || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageBeforeSleep || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.daysPerWeek || ''}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1" @click.stop>
                          <div class="d-flex align-center width100 height100">
                            <actions :items="actionsSupplement"
                                     @action="runAction"
                                     :item="sup"
                            />
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td colspan="11" style="text-align: end;padding: 10px;">
                        <Button btn-bg="dark-green" :styles="{'border-radius': '8px'}"
                                @click.native="addSupplement(item)">
                          <span style="color: #FFFFFF">
                            <v-icon>mdi-plus</v-icon>
                            Add supplement
                          </span>
                        </Button>
                      </td>
                    </tr>
                  </template>

                </Table>
              </v-card>
            </td>
          </template>
        </template>
        <template v-slot:footer>
          <div class="footer pl-5 d-flex align-center">
            <div class="row-per-page d-flex align-center flex-grow-1">
              <span class="row-per-page__lable mr-4">Rows per page: </span>
              <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[2]" v-model="currentPageSize" @change="changeItermPerPage" />
            </div>
            <div class="flex-grow-2">
              <pagination v-if="pageCountCustom" :value="page" :length="pageCountCustom" :total-visible="7" @input="changePagination" />
            </div>
            <div class="d-flex align-center justify-end flex-grow-1 mr-6" style="column-gap: 1rem">
              <v-switch class="py-0 my-0" color="#008C89" inset label="Show inactive" @change="inactiveProtocol" />
            </div>
          </div>
        </template>
      </Table>
      <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </div>
    <confirm v-if="dialogs.confirm.isOpen" :query="query"/>
    <add-supplement-dialog :componentFrom="'protocol'" v-if="dialogs.addSupplementDialog.isOpen || dialogs.editSupplementDialog.isOpen" :query="query" />
    <edit-protocol-dialog v-if="dialogs.addProtocolDialog.isOpen || dialogs.editProtocolDialog.isOpen" />
  </template>
  <template v-else>
    <router-view></router-view>
  </template>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Pagination from '@/mixins/Pagination'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import Table from '@/components/elements/Table'
import pagination from '@/components/layouts/pagination'
import Select from '@/components/elements/Select'
import actions from '@/components/layouts/actions'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import EditProtocolDialog from '@/components/layouts/dialogs/edit-protocol-dialog'
import AddSupplementDialog from '@/components/layouts/dialogs/add-supplement-dialog'

const GET_ALL_PROTOCOLS = 'getProtocols'
const IS_SLOTABLE = ['name', 'notes', 'active']

export default {
  name: 'protocols',
  components: { Input, Button, Table, pagination, Select, actions, confirm, EditProtocolDialog, AddSupplementDialog },
  mixins: [Pagination],
  data: () => ({
    rowIsHovered: false,
    searchedText: '',
    itemsPerPage: 15,
    page: 1,
    expandedRows: [],
    supplementDosages: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 0,
        text: 'x'
      }
    ],
    supplementDosagesDaysPerWeek: [
      {
        id: 1,
        active: false,
        value: 1,
        text: '1'
      },
      {
        id: 2,
        active: false,
        value: 2,
        text: '2'
      },
      {
        id: 3,
        active: true,
        value: 3,
        text: '3'
      },
      {
        id: 4,
        active: false,
        value: 4,
        text: '4'
      },
      {
        id: 5,
        active: false,
        value: 5,
        text: '5'
      },
      {
        id: 6,
        active: false,
        value: 6,
        text: '6'
      },
      {
        id: 7,
        active: false,
        value: 7,
        text: '7'
      }
    ],
    isStartCreateNewSupProduct: false,
    loading: true,
    currentPageSize: 15,
  }),
  mounted () {
    // this.getProtocols(this.query)
    this.loading = true
    this.appSettings()
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('protocols', ['SET_DATA']),
    ...mapActions(['appSettings']),
    ...mapActions('protocols', ['getProtocols', 'updateProtocolSelect']),
    handlerUpdateProtocolSelect (id, value) {
      this.updateProtocolSelect({ id, value })
    },
    filterBox ($event) {
      const columns = []
      columns.push($event)
      this.addUrlParam({ columns })
      this.getProtocols(this.query)
    },
    resetFilter ($event) {
      const query = Object.assign({}, this.query)
      const columns = query.columns.filter(el => el.name !== $event)
      this.addUrlParam({ columns })
      this.getProtocols(this.query)
    },
    refresh () {
      this.searchedText = ''
      // const query = Object.assign({}, this.query)
      // delete query.search
      // delete query.columns
      delete this.query.search
      delete this.query.columns
      this.page = 1
      this.query.PageSize = this.currentPageSize
      this.query.PageIndex = 0
      this.addUrlParam({ ...this.query })
      this.$nextTick(() => {
        this.getProtocols(this.query)
      })
    },
    createNewProtocol () {
      this.TOGGLE_DIALOG({
        id: 'add-protocol',
        name: 'addProtocolDialog',
        isOpen: true,
        title: 'Create protocol',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        }
      })
    },
    inputSearch ($event) {
      this.searchedText = $event
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ search: { searchType: 1, value: $event } })
      this.page = 1
    },
    highlightSearchedText (currentText, searchedText) {
      if (currentText.toLowerCase().includes(searchedText.toLowerCase())) {
        const newStr = currentText.toLowerCase().replace(searchedText.toLowerCase(), `<span style="color: #7cdbc1;">${searchedText}</span>`)
        return newStr
      }
      return currentText
    },
    dynamicSupplementNameMaxWidth (array) {
      let countPixel = 0
      for (let i = 0; i < array.length; i++) {
        countPixel += array[i] ? 13 : 0
      }
      return countPixel
    },
    toggleRow (id) {
      const index = this.expandedRows.indexOf(id)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = []
        this.expandedRows.push(id)
      }
    },
    async updateSupplementDosage ({ data, keyProp }) {
      const result = await this.updateResult(data)
      this.updateDataStore(result, keyProp)
    },
    runAction (val) {
      val.params.action(val.id, val)
      // this.editResult(val.id)
    },
    getQuantityBySupplementProductId (resultProducts, productId) {
      const product = resultProducts.find(item => item.supplementProductId === productId)
      return product && product.quantity ? product.quantity : '0'
    },
    changePagination ($event) {
      this.page = $event
      this.addUrlParam({
        PageIndex: $event === 0 ? 0 : $event - 1,
        PageSize: this.itemsPerPage
      })
      this.getProtocols(this.query)
    },
    changeItermPerPage ($event) {
      this.itemsPerPage = $event
      this.currentPageSize = $event
      if ($event !== this.query.PageSize) {
        this.addUrlParam({
          PageIndex: 0, // this.page,
          PageSize: $event === 'All' ? '-1' : $event
        })
        this.query.PageIndex = 0
        this.getProtocols(this.query)
        this.page = 1
      }
    },
    inactiveProtocol ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ showInactive: $event })
      this.page = 1
      this.getProtocols(this.query)
    },
    async deleteProtocol (id) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        description: 'Are you sure you want to delete the protocol?',
        action: 'protocols/deleteProtocol',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        },
        title: 'Confirm deleting the Protocol'
      })
    },
    editProtocolDialog (id, data) {
      this.TOGGLE_DIALOG({
        id,
        name: 'editProtocolDialog',
        isOpen: true,
        title: 'Update protocol',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        },
        data: data.data
      })
    },
    addSupplement (data) {
      console.log('addSupplement', data)
      this.TOGGLE_DIALOG({
        protocolId: data.id,
        name: 'addSupplementDialog',
        isOpen: true,
        title: 'Add supplement',
        type: 'protocol',
        data: data,
        action: 'protocols/addProduct',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        }
      })
    },
    editSupplement (id, data) {
      console.log(id, data)
      this.TOGGLE_DIALOG({
        id,
        name: 'editSupplementDialog',
        isOpen: true,
        title: 'Edit supplement',
        type: 'protocol',
        data: data,
        action: 'protocols/updateProtocolSupplement',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        }
      })
    },
    async deleteSupplement (id, data) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        description: 'Are you sure you want to delete the supplement?',
        action: 'protocols/deleteSupplement',
        afterAction: {
          name: 'protocols/getProtocols',
          protocol: this.query
        },
        title: 'Confirm deleting the supplement'
      })
    }
  },
  computed: {
    ...mapState('protocols', {
      headers (state) {
        const headers = Object.assign([], state.protocols?.headers?.$values)
        headers.push({ text: 'Actions', value: 'actions', isSlotable: true, width: '1%', sortable: false })
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id']),
          isSlotable: IS_SLOTABLE.includes(el.value),
          width: (this._.isNumber(el.width)) ? el.width + '%' : el.width
        })).filter(item => item.value !== 'active')
      },
      protocolItems (state) {
        const items = Object.assign([], state.protocols?.data?.$values)
        return items
      }
    }),
    organization () {
      return this.$store.state.organizations.organization
    },
    headersSupplement () {
      return [
        {
          text: 'Selected',
          value: 'selected',
          isSlotable: true,
          isReplacingSpace: 0,
          width: '1%'
        },
        {
          text: 'Supplement Name',
          value: 'supplementName',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Upon arising',
          value: 'uponArising',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Break fast',
          value: 'breakfast',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: '10 am',
          value: '10am',
          isSlotable: true,
          sortable: false,
          isReplacingSpace: 0
        },
        {
          text: 'Lunch',
          value: 'lunch',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: '3 pm',
          value: '3pm',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Dinner',
          value: 'dinner',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: '7 pm',
          value: '7pm',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Before sleep',
          value: 'beforeSleep',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Days WK',
          value: 'daysWk',
          sortable: false,
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Actions',
          value: 'actions',
          isSlotable: true,
          width: '1%',
          sortable: false
        }
      ]
    },
    pageCountCustom () {
      // const division = this.total > 100 ? 100 : this.itemsPerPage
      // return (this.itemsPerPage) ? Math.ceil(this.total / division) : 1
      return (this.itemsPerPage) ? Math.ceil(this.total / this.itemsPerPage) : 1
    },
    rowPerPageItems: () => ([5, 10, 15, 20]),
    actions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editProtocolDialog },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteProtocol }
      ]
    },
    actionsSupplement () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editSupplement },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteSupplement }
      ]
    },
    results () {
      return this.$store.state?.results?.results
    },
    protocols () {
      return this.$store.state?.protocols?.protocols
    },
    getAllFunc: () => GET_ALL_PROTOCOLS,
    dialogs () {
      return this.$store.state.dialogs
    },
    total (state) {
      return state.protocols?.pagination?.total
    },
    protocolListIsLoaded () {
      return this.$store.state.protocols?.protocolListIsLoaded
    }
  },
  beforeDestroy () {
    this.SET_DATA(['protocolListIsLoaded', false])
  },
  watch: {
    protocolListIsLoaded (val) {
      if (val) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.protocols {
  background-color: #F7FAFC;
  height: 100vh;
  padding: 16px;
  min-height: 100px;
  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 16px;
  }
  ::v-deep .v-dialog {
    overflow: hidden;
    height: 460px!important;
    .v-card {
      padding: 0!important;
      height: 80vh;
      &__text {
        padding: 0 0 0!important;
      }
      &__actions {
        padding-top: 0!important;
      }
      .v-input {
        &__control {
          .v-messages {
            display: none!important;
          }
        }
      }
    }
  }
  ::v-deep .add-supplement-dialog {
    .v-dialog {
      height: 90%!important;
      .v-card {
        height: 100% !important;
      }
    }
  }
  ::v-deep .confirm-dialog {
    .v-dialog {
      overflow: hidden;
      height: 220px!important;
      .v-card {
        &__actions {
          div {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .protocols {
    &__total {
      font-size: 20px;
      font-weight: 600;
      span.num {
        color: $dark-green;
      }
    }
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
  .protocol-table {
    width: 100%;
    .inactive-protocol {
      background-color: #e7e7e7;
    }
    ::v-deep .v-card {
      height: auto!important;
    }
    ::v-deep .v-data-table-header th + th {
      border-left:thin solid #0000001f !important;
    }
    ::v-deep th {
      height: 38px!important;
    }
    ::v-deep td {
      border-bottom:thin solid #0000001f !important;
    }
    ::v-deep td + td {
      border-left:thin solid #0000001f !important;
    }
    ::v-deep tr:last-child {
      border-bottom:thin solid #0000001f !important;
    }
    &__row {
      cursor: pointer;
      .name, .notes {}
    }
    &__row:hover {
      background-color: #008c8921!important;
    }
    &__row:last-child {
      td {
        border-bottom:thin solid #0000001f
      }
    }
    .product-supplement {
      padding: 10px 0;
      ::v-deep .v-card {
        background: #EDF2F7;
        padding: 16px;
        .table {
          padding: 0px!important;
          .v-data-table .v-data-table__wrapper {
            table {
              td + td {
                border-left:thin solid #0000001f;
              }
              td {
                border-bottom:thin solid #0000001f;
              }
              tr:last-child {
                td {
                  border-bottom:unset!important;
                }
              }
              .sub-row {
                .table {
                  padding: 0!important;
                }
                th {
                  padding-top: 15px;
                  padding-bottom: 15px;
                }
                tr:last-child {
                  td {
                    border-bottom: unset;
                  }
                }
                td {
                  //border-bottom: unset;
                }
              }
            }
          }
        }
      }
      .circle-dot {
        height: 8px;
        width: 8px;
        background-color: grey;
        border-radius: 50%;
        display: inline-block;
        margin: 0 2px;
      }
      .circle-dot.light-green {
        background-color: #75DC98!important;
      }
      .circle-dot.light-orange {
        background-color: #F7936F!important;
      }
      .circle-dot:first-child {
        margin-left: 10px;
      }
      .circle-dot:last-child {
        margin: 0 0 0 2px;
      }
      .circle-dot.dark-green {
        background-color: #28605A!important;
      }
      .circle-dot.green {
        background-color: #499D66!important;
      }
      .circle-dot.dark-orange {
        background-color: #F16063!important;
      }
      .circle-dot.light-blue {
        background-color: #00E0FF!important;
      }
      .circle-dot.blue {
        background-color: #537CFF!important;
      }
    }
    .custom-result-table__dynamic.selected-row {
      background: #E9FFFB;
    }
    .custom-result-table__dynamic {
      .selected {
        margin: 0;
        padding: 0;
        ::v-deep .v-input {
          .v-input__slot {
            margin: 0;
          }
          .v-messages {
            display: none;
          }
        }
      }
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        //display: none;
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        padding-top: 0px!important;
        margin-top: 0px!important;
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
      .select {
        ::v-deep .v-input {
          &__control {
            .v-text-field__details {
              display: none!important;
            }
          }
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
}
</style>
