<template>
  <div class="message-component">
    <div class="wrap">
      <div
        class="system-message"
        :class="getClassByMessageType"
        v-if="!owner && messageData.messageType !== 0">
        {{getTextByMessageType}}
      </div>
      <div class="message-wrap" :class="{'owner-line-msg': owner}">
        <div class="avatar" v-if="!owner">
          <v-avatar>
            <v-img :src="getAvatarById(messageData.srcMemberId)"></v-img>
          </v-avatar>
        </div>
        <div class="message" :class="{owner}">
          <small><strong>{{messageData.srcMemberName}}</strong></small>
          <p :class="{ 'owner-text': owner }">{{messageData.message}}</p>
          <div class="time" :class="{ 'time-owner': owner, 'time-no-owner': !owner }">
            {{messageTime}}
          </div>
          <div class="is-read-msg" v-show="owner && messageData.isRead">
            <v-icon>mdi-check-bold</v-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Image from '@/mixins/Image'
export default {
  name: 'Message',
  mixins: [Image],
  props: {
    messageData: { type: Object },
    owner: { type: Boolean, default: false },
    avatarsAndLogos: { type: Object }
  },
  methods: {
    getAvatarById (id) {
      return this.avatarsAndLogos[id] || this.getImgWithExt(['icons', 'avatar-default', 'svg'])
    }
  },
  computed: {
    getClassByMessageType () {
      return {
        'survey-sent': this.messageData.messageType === 1,
        'in-protocol': this.messageData.messageType === 2
      }
    },
    getTextByMessageType () {
      switch (this.messageData.messageType) {
        case 1:
          return 'Survey sent'
        case 2:
          return 'In protocol'
        default:
          return ''
      }
    },
    messageTime () {
      const diffHour = new Date().getUTCDay()
      return moment(new Date(this.messageData.createdOn).setHours(diffHour + new Date(this.messageData.createdOn).getHours())).format('LT')
    }
  }
}
</script>

<style scoped lang="scss">
.message-component {
  .system {
    margin-bottom: 1rem;
    p {
      margin-bottom: 1rem;
    }
  }
  .wrap {
    display: flex;
    flex-direction: column;
    .message-wrap {
      display: flex;
      align-items: center;
    }
   .owner-line-msg {
     align-self: end;
     justify-content: end;
     width: 100%;
   }
    .system-message {
      padding: 1rem;
      border-radius: 18px;
      width: fit-content;
      margin: 0 4rem;
      box-shadow: 0 1px 0 0 rgb(50, 50, 50, 0.3);
      margin-bottom: 1rem;
      font-weight: 500;
      font-size: 12px;
    }
    .survey-sent {
      background: rgba(208, 220, 255, 0.5);
      color: #668BFF;
    }
    .in-protocol {
      background: rgba(115, 217, 149, 0.2);
      color: #4FAA6E;
    }
    .message {
      padding: 1rem;
      border-radius: 18px;
      width: 60%;
      margin: 0 1rem;
      box-shadow: 0 1px 0 0 rgb(50, 50, 50, 0.3);
      background: #EDF2F7;
      position: relative;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
        width: 95%;
      }
      .time {
        position: absolute;
        right: 10px;
        bottom: 5px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 10px;
      }
      .time-owner {
        color: #EDF2F7;
      }
      .time-no-owner {
        color: $dark-green;
      }
      .is-read-msg {
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        border: 3px solid $dark-green;
        right: -21px;
        bottom: 0;
        ::v-deep .v-icon {
          position: inherit;
          font-size: 13px;
          color: $dark-green;
        }
      }
      .owner-text {
        color: #EDF2F7;
      }
    }
    .owner {
      background: $dark-green;
      color: #000000;
      align-self: flex-end;
    }
  }
}
</style>
