import { render, staticRenderFns } from "./payments.vue?vue&type=template&id=31e56adb&scoped=true&"
import script from "./payments.vue?vue&type=script&lang=js&"
export * from "./payments.vue?vue&type=script&lang=js&"
import style0 from "./payments.vue?vue&type=style&index=0&id=31e56adb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e56adb",
  null
  
)

export default component.exports