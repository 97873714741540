<template>
  <div class="view-visit">
    <v-container fluid class="wrap-container justify-space-between d-flex" v-if="visit">
      <v-col cols="9">
        <!--<v-row>
        <v-col cols="4">
          <div class="d-flex">
            <div class="mr-2">
               <v-avatar size="84" v-if="visit.patientAvatar">
                 <v-img :src="'data:image/png;base64,'+visit.patientAvatar" />
               </v-avatar>
            </div>
            <div class="d-flex flex-column font-weight-bold">
              <div>Visit {{$moment(visit.date).format('MM/DD/YYYY')}}</div>
              <div>
                <Link @click.native="edit" to="">Reschedule</Link>
              </div>
              <div>
                <v-chip color="primary" >
                  <v-icon left>
                    mdi-account-circle-outline
                  </v-icon>
                  {{status || 'N/A'}}
                </v-chip>
              </div>
            </div>
          </div>
          &lt;!&ndash;<div class="d-flex pt-2">
            <btn-group :btns="btns" @update:btn="changeStatus" />
          </div>&ndash;&gt;
        </v-col>
        <v-col cols="4">
          <div>
            <div>14 yers old</div>
            <div>
              <span class="mr-2 font-weight-bold">Male</span>
              <span>{{visit.weight}} kg</span>
            </div>
            <div>{{visit.height}} cm / 22.3</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="font-weight-bold">Rerports of VISIT:</div>
          <Link to="#">Survey from A</Link>
          <Link to="#">Survey from B</Link>
        </v-col>
      </v-row>-->
        <!--new block header-->
        <v-row class="align-center">
          <v-col cols="12" class="pb-1">
            <div class="view-visit__header">
              <div class="view-visit__header-title">
                <span>Evaluation</span>
              </div>
<!--              <div class="view-visit__header-status">
                <v-tooltip right :open-on-hover="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on">
                      <div class="custom-btn-status" :class="getStatusCodeName[visit.status].class">
                        <span>{{getStatusCodeName[visit.status].name}}</span>
                      </div>
                    </div>
                  </template>
                  <div style="display: block;max-width: 300px;">{{getStatusCodeName[visit.status].description}}</div>
                </v-tooltip>
              </div>-->
              <div class="time-line-wrap">
                <div
                  v-for="statusBtn in visit.timeLines.$values"
                  :key="statusBtn.name"
                  class="status-btn"
                  @click="runMethod(statusBtn.name)"
                  :class="{'light-grey-bg': statusBtn.past || statusBtn.future, 'green-light-bg': statusBtn.now, 'green-bg': statusBtn.isApprove || statusBtn.isComplete}"
                  style="height: 40px;flex-shrink: 0;"><!--:style="{'background-color': statusBtn.bgColor}"-->
                  <div
                    class="current-status triangle"
                    v-if="statusBtn.now"
                    :class="{'border-left-light-grey border-top-light-grey': statusBtn.past || statusBtn.future, 'border-left-light-green border-top-light-green': statusBtn.now, 'border-left-green border-top-green': statusBtn.isApprove || statusBtn.isComplete}"
                  ></div><!--:style="{'border-left': `solid 5px ${statusBtn.bgColor}`, 'border-top': `solid 5px ${statusBtn.bgColor}`}"-->
                  <div class="triangle-left"></div>
                  <div class="triangle"
                       :class="{'border-left-light-grey border-top-light-grey': statusBtn.past || statusBtn.future, 'border-left-light-green border-top-light-green': statusBtn.now, 'border-left-green border-top-green': statusBtn.isApprove || statusBtn.isComplete}"><!-- :style="{'border-left': `solid 10px ${statusBtn.bgColor}`, 'border-top': `solid 10px ${statusBtn.bgColor}`}"--><!--v-if="!statusBtn.isApprove && !statusBtn.isComplete"-->
                  </div>
                  <div class="status-name"
                       :class="{'light-grey-text': statusBtn.past, 'white-text': statusBtn.isApprove || statusBtn.isComplete, 'green-text': statusBtn.now}"><!--:style="{'color': statusBtn.colorText}"-->
                    {{statusBtn.name}}
                  </div>
                  <div class="status-date"
                       v-if="statusBtn.info"
                       :class="{'light-grey-text': statusBtn.past, 'white-text': statusBtn.isApprove || statusBtn.isComplete, 'green-text': statusBtn.now}"><!--:style="{'color': statusBtn.colorText}"-->
                    {{statusBtn.info}}
                  </div>
                  <div v-if="statusBtn.now" style="position: absolute;bottom: -19px;font-size: 13px;font-weight: bold;color: #008c89;">now</div>
                </div>
              </div>
            </div>
          </v-col>
<!--          <v-col cols="6" class="d-flex justify-end" v-if="visit.status === 'NeedReview' || visit.status === 'Reviewed'">
            <Button
              dark
              width="150"
              color="#4FAA6E"
              @click.native="openModalCompleteVisit"
              :styles="{ 'border-radius': '8px' }"
            >
              <span style="color: #FFFFFF">Complete</span>
            </Button>
          </v-col>-->
        </v-row>
        <v-row class="mt-1">
          <v-col cols="12" style="padding: 4px 0;">
            <v-card elevation="2" class="visit-card-main-data">
              <v-card-text>
                <div class="main-row">
                  <div class="full-name-avatar">
                    <div class="full-name-avatar__avatar">
                      <v-avatar size="70">
                        <img v-if="visit.patientAvatar" :src="visit.patientAvatar" :alt="visit.patientName" />
                      </v-avatar>
                    </div>
                    <div class="full-name-avatar__full-name">
                      <span class="fio">{{visit.patientName}}</span>
                    </div>
                  </div>
                  <div class="view-visit__header-status" style="width: 108px">
                    <v-tooltip right :open-on-hover="false">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs"
                             v-on="on">
                          <div class="custom-btn-status" :class="getStatusCodeName[visit.status].class">
                            <span>{{getStatusCodeName[visit.status].name}}</span>
                          </div>
                        </div>
                      </template>
                      <div style="display: block;max-width: 300px;">{{getStatusCodeName[visit.status].description(visit.statusDays) || '-'}}</div>
                    </v-tooltip>
                  </div>
                  <div class="last-visit">
                    <div class="last-visit__title">
                      <span class="title-text">Evaluation Date</span>
                    </div>
                    <div class="last-visit__date">
                      <div class="btn-date">
                        <v-icon color="#008C89">mdi-calendar-blank</v-icon>
                        <span class="btn-date__text">{{$moment(visitDate).format('LL')}}</span>
                        <datepicker
                          style="position: absolute;top: 0;left: 0;opacity: 0;"
                          @date:update="updateVisitDate"
                          :value="visitDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="patient-parameters">
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Gender</div>
                      <div class="patient-parameters__value">Male</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Weight</div>
                      <div class="patient-parameters__value">{{visit.weight}} kg</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Growth</div>
                      <div class="patient-parameters__value">{{visit.height}} cm</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Age</div>
                      <div class="patient-parameters__value">30</div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!--new block header-->
        <v-row>
          <v-col class="px-0 py-0">
<!--            <v-card elevation="2" style="border-radius: 16px;">&lt;!&ndash;outlined&ndash;&gt;
              &lt;!&ndash;<v-card-title>{{visit.doctorFullName}}</v-card-title>&ndash;&gt;
              <v-card-text class="flex-start">
                <Tabs :items="tabs" @update:tab="tab" :tab-index="tabIndex" left>
                  <template v-slot:sliderColor>
                    <v-tabs-slider color="#4FAA6E"></v-tabs-slider>
                  </template>
                  <template v-slot:buttonControlsForResults v-if="tabs[componentInd] === 'results'">
                    <div class="d-flex flex-grow-1 justify-end">
                      <Button outlined
                              :styles="{ 'border-radius': '8px', 'border': '1px solid #A0AEC0' }"
                              class="mr-2">
                        <v-icon class="gray-btn-color-text">mdi-refresh</v-icon>
                        <span class="gray-btn-color-text">Refresh</span>
                      </Button>
                      <Button outlined
                              :styles="{ 'border-radius': '8px', 'border': '1px solid #4FAA6E' }"
                              class="mr-2">
                        <v-icon class="green-btn-color-text">mdi-plus</v-icon>
                        <span class="green-btn-color-text">Protocol</span>
                      </Button>
                      <Button outlined
                              :styles="{ 'border-radius': '8px', 'border': '1px solid #4FAA6E' }"
                              class="mr-2">
                        <span class="green-btn-color-text">Change Bottles</span>
                      </Button>
                      <Button color="#4FAA6E"
                              dark
                              :styles="{ 'border-radius': '8px' }"
                              @click.native="TOGGLE_DIALOG({ name: 'addResult', isOpen: true, title: 'Add supplement' })">
                        <v-icon>mdi-plus</v-icon>
                        <span>Supplement</span>
                      </Button>
                    </div>
                  </template>
                  <template v-slot:[`${tabs[componentInd]}`]>
                    <component :is="tabs[componentInd]"></component>
                  </template>
                </Tabs>
              </v-card-text>
            </v-card>-->
            <Tabs :items="tabs" @update:tab="tab" :tab-index="tabIndex" left>
              <template v-slot:sliderColor>
                <v-tabs-slider color="#4FAA6E"></v-tabs-slider>
              </template>
              <template v-slot:buttonControlsForAllTabs>
                <div class="d-flex flex-grow-1 justify-end align-center">
<!--                  <Button outlined
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #A0AEC0' }"
                          class="mr-2">
                    <v-icon class="gray-btn-color-text">mdi-refresh</v-icon>
                    <span class="gray-btn-color-text">Refresh</span>
                  </Button>-->
                  <Button outlinedr
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89', 'min-width': '45px' }"
                          @click.native="openGroupSupplementTotals('Group')"
                          class="mr-2">
                    <span class="green-btn-color-text">G</span>
                  </Button>
                  <Button outlined
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89', 'min-width': '45px' }"
                          @click.native="openGroupSupplementTotals('Supplement')"
                          class="mr-2">
                    <span class="green-btn-color-text">S</span>
                  </Button>
                  <Button outlined
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
                          class="mr-2">
                    <v-icon class="green-btn-color-text">mdi-plus</v-icon>
                    <span class="green-btn-color-text">Protocol</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          v-if="tabs[componentInd].component === 'results'"
                          dark
                          :styles="{ 'border-radius': '8px' }"
                          @click.native="TOGGLE_DIALOG({ name: 'addResult', isOpen: true, title: 'Add supplement' })">
                    <v-icon>mdi-plus</v-icon>
                    <span>Supplement</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          v-if="tabs[componentInd].component === 'survey'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
                          @click.native="openQuickEntry('quickEntry_1')"
                          class="mr-2">
                    <span style="color: white">Quick entry 1</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          v-if="tabs[componentInd].component === 'survey'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
                          @click.native="openQuickEntry('quickEntry_2')"
                          class="mr-2">
                    <span style="color: white">Quick entry 2</span>
                  </Button>
                  <div class="btn-cost" @click="openChangeDaysSupplModal">
                    <Img :src="getImgWithExt(['icons', 'purse', 'svg'])" width="20px"/>
                    <span style="padding: 0 5px;">Cost</span>
                    <span class="days-60" v-if="result">{{result.daysSupply}} days</span>
                  </div>
                  <v-icon style="cursor:pointer;margin-left: 10px" @click="refreshData">mdi-refresh</v-icon>
                </div>
              </template>
              <template v-slot:[`${tabs[componentInd].component}`]>
                <component :elevation="2" :is="tabs[componentInd].component" @changeTab="changeTab"></component>
              </template>
              <template v-slot:totalResult v-if="tabs[componentInd].component === 'results'">
                <results-total/>
              </template>
            </Tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" style="margin-top: 79px">
        <v-row v-for="(item, index) in notes" :key="item.id" class="mb-2">
          <simple-info-block
            :info="item"
            :index="index"
            @updateNote="updateNote"
          />
        </v-row>
      </v-col>
    </v-container>
    <add-visit v-if="dialogs.addVisit.isOpen || dialogs.editVisit.isOpen" />
    <complete-visit v-if="dialogs.completeVisit.isOpen" @complete="completeSurvey" />
    <days-supply-dialog v-if="dialogs.daysSupplyDialog.isOpen" @showPopup="showPopup"/>
    <quick-entry-form v-if="dialogs.quickEntryDialog.isOpen"/>
    <result-group-supplement-totals v-if="dialogs.resultGroupSupplementTotals.isOpen"/>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{message || 'Visit was completed. Please check the available Reports'}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import Tabs from '@/components/layouts/Tabs'
import Link from '@/components/elements/Link'
import results from '@/views/visits/tabs/results'
import reports from '@/views/visits/tabs/reports'
import ResultsTotal from '@/views/visits/tabs/results-total'
import blood from '@/views/visits/tabs/blood'
import survey from '@/views/visits/tabs/survey'
import BtnGroup from '@/components/layouts/btn-group'

import addVisit from '@/components/layouts/dialogs/add-visit'
import Button from '@/components/elements/Button'
import SimpleInfoBlock from '@/components/elements/simple-info-block'
import Mapping from '@/mixins/Mapping'
import datepicker from '@/components/elements/datepicker'
import CompleteVisit from '@/components/layouts/dialogs/complete-visit'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'
import DaysSupplyDialog from '@/components/layouts/dialogs/days-supply-dialog'

export default {
  name: 'view-visit',
  mixins: [Mapping, Image],
  components: {
    Tabs,
    Link,
    results,
    reports,
    blood,
    survey,
    addVisit,
    BtnGroup,
    Button,
    SimpleInfoBlock,
    ResultsTotal,
    datepicker,
    CompleteVisit,
    Img,
    DaysSupplyDialog,
    QuickEntryForm: () => import('@/components/layouts/dialogs/quick-entry-form.vue'),
    ResultGroupSupplementTotals: () => import('@/components/layouts/dialogs/result-group-supplement-totals.vue')
  },
  data: () => ({
    componentInd: 0,
    tabIndex: 0,
    mockData: [
      {
        id: 1,
        title: 'Five main complaints',
        data: '- klfjkdls\n- fjdkfd'
      },
      {
        id: 2,
        title: 'Notes for Patient',
        data: '- klfjkdls\n- fjdkfd\n- fjdkfd'
      },
      {
        id: 3,
        title: 'Notes for Doctor',
        data: '- klfjkdls\n- fjdkfd\n- fjdkfd\n- fjdkfd'
      }
    ],
    visitDate: null,
    alertIsOpen: false,
    message: null,
    timeLinesData: [
      {
        name: 'Survey Sent',
        date: '04/18/2022',
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      },
      {
        name: 'Wait Patient for Submit',
        date: null,
        colorText: '#008C89',
        bgColor: '#79d6d4',
        isCurrentStatus: true
      },
      {
        name: 'Approve',
        date: null,
        colorText: '#FFFFFF',
        bgColor: '#008C89',
        isCurrentStatus: false
      },
      {
        name: 'Complete1',
        date: null,
        colorText: '#FFFFFF',
        bgColor: '#008C89',
        isCurrentStatus: false
      },
      {
        name: 'In Protocol',
        date: null,
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      },
      {
        name: 'Complete',
        date: null,
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      }
    ]
  }),
  methods: {
    ...mapActions('visits', ['getVisit', 'updateReschedule']),
    ...mapActions('results', ['getSupplements', 'getResults', 'updateNotes']),
    ...mapActions('surveys', ['updateStatus', 'finishSurvey', 'approveSurvey', 'getSurveyGroups', 'getDetailsTestComplaints']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('surveys', ['UPDATE_FIELD_DATA']),
    async loadData (id) {
      await this.getVisit(id).then(res => {
        if (res) {
          this.$route.meta.headerTitle = `Visit details - ${this.$moment(this.visit.date).format('MM/DD/YYYY')} - ${this.visit.patientName}`
          this.visitDate = this.visit.date
        }
      })
    },
    runMethod (name) {
      if (name === 'Approve') {
        this.sendSurvey(this.visit)
        this.loadData(this.$route.params.id)
      }
      if (name === 'Complete') {
        this.openModalCompleteVisit()
      }
    },
    async sendSurvey (visit) {
      if (visit.status === 'NeedReview' || visit.status === 'SurveySent' || visit.status === 'SurveyDue') {
        const res = await this.approveSurvey({ surveyId: this.visit.surveyId, visitId: this.visit.id })
        this.alertIsOpen = true
        this.message = 'Survey was approved. Survey Maestro calculating results based on the Survey data..'
        const self = this
        if (res) {
          setTimeout(function () {
            self.$emit('changeTab', { title: 'results', component: 'results' })
          }, 1300)
        }
      }
    },
    tab ($event) {
      console.log('tab', $event)
      this.componentInd = $event
      this.tabIndex = $event
    },
    edit ($event) {
      $event.preventDefault()
      const { patientId } = this.visit
      if (patientId) {
        this.TOGGLE_DIALOG({ id: patientId, name: 'editVisit', title: 'Reschedule Evalutation', isOpen: true })
      }
    },
    async changeStatus ($event) {
      const { id } = this.$route.params
      await this.updateStatus({ type: this.btns[$event].name, visitId: this.visit.id, surveyId: this.visit.surveyId })
      await this.getResults(id)
    },
    updateNote ({ index, notes, data }) {
      // this.notes[index].data = notes
      console.log(this.result)
      const prepareData = {
        apiUrl: data.apiUrl,
        field: data.field,
        dataForSend: {
          resultId: this.result.id,
          notes: notes
        }
      }
      this.updateNotes(prepareData)
    },
    async completeSurvey (data) {
      const res = await this.finishSurvey({ visitId: this.$route.params.id, surveyId: this.visit.surveyId, ...data })
      if (res) {
        this.TOGGLE_DIALOG({ name: 'completeVisit', isOpen: false })
        this.loadData(this.$route.params.id)
        this.alertIsOpen = true
        const self = this
        setTimeout(function () {
          self.tab(2)
        }, 1300)
      }
    },
    openModalCompleteVisit () {
      this.TOGGLE_DIALOG({ name: 'completeVisit', title: 'Complete visit', isOpen: true })
    },
    async updateVisitDate (val) {
      if (val) {
        this.visitDate = val
        const data = {
          id: this.$route.params.id,
          data: {
            id: this.$route.params.id,
            date: val,
            doctorId: this.visit.doctor.id,
            patientId: this.visit.patientId
          }
        }
        const res = await this.updateReschedule(data)
        this.UPDATE_FIELD_DATA({ key: 'dueDate', value: res.date })
      }
    },
    changeTab (tab) {
      console.log('changeTab', tab)
      this.tabIndex = this.tabs.findIndex(item => item.component === tab.component)
      console.log('this.tabIndex', this.tabIndex)
      this.tab(this.tabIndex)
    },
    showPopup () {
      this.alertIsOpen = true
      this.message = 'Days Supply was changed!'
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 1300)
      this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', isOpen: false })
    },
    openChangeDaysSupplModal () {
      this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', title: 'Change Evaluation Supply Days', isOpen: true, data: this.result })
    },
    refreshData () {
      console.log(this.tabs[this.componentInd])
      switch (this.tabs[this.componentInd].component) {
        case 'survey':
          this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' })
          this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
          break
        case 'results':
          this.getResults(this.$route.params)
          break
        case 'reports':
          console.log('rep')
          break
      }
    },
    openQuickEntry (type) {
      this.TOGGLE_DIALOG({ name: 'quickEntryDialog', title: 'Quick Entry Form', isOpen: true, data: { type } })
    },
    openGroupSupplementTotals (type) {
      this.TOGGLE_DIALOG({ name: 'resultGroupSupplementTotals', title: `${type} Totals`, isOpen: true, data: { type } })
    }
  },
  destroyed () {
    this.tab(0)
  },
  mounted () {
    const { params, query } = this.$route
    if (params?.id) {
      this.loadData(params?.id)
      this.getResults(params.id)
    }
    if (query?.tabIndex) {
      this.tabIndex = query.tabIndex
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit,
      status: state => (state.surveys?.status?.visit?.status) ? state.surveys?.status?.visit?.status : state.surveys?.status?.status
    }),
    ...mapState('results', {
      result (state) {
        return state?.results?.record
      },
      notes (state) {
        const notes = [
          { id: 1, title: 'Notes for Practitioner', data: state?.results?.record?.doctorNotes, apiUrl: 'DoctorNotes', field: 'doctorNotes' },
          { id: 2, title: 'Notes for Patient', data: state?.results?.record?.patientReportNotes, apiUrl: 'PatientReportNotes', field: 'patientReportNotes' },
          { id: 3, title: 'Evaluation Notes', data: state?.results?.record?.visitNotes, apiUrl: 'VisitNotes', field: 'visitNotes' },
          { id: 4, title: 'Five main complaints', data: state?.results?.record?.surveyComplaints, apiUrl: 'SurveyComplaintsNotes', field: 'surveyComplaints' }
        ]
        return notes
      }
    }),
    tabs: () => ([{ title: 'survey', component: 'survey' }, { title: 'results', component: 'results' }, { title: 'reports', component: 'reports' }]), // { title: 'blood', component: 'blood' },
    dialogs () {
      return this.$store.state.dialogs
    },
    btns: () => ([{ name: 'Update' }, { name: 'Submit' }, { name: 'Approve' }, { name: 'Finish' }])
  },
  watch: {
    visit (val) {
      if (val) {
        console.log('visit', val)
        this.$route.meta.headerTitle = `Visit details - ${this.$moment(val.date).format('MM/DD/YYYY')} - ${val.patientName}`
        this.visitDate = val.date
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .view-visit {
    ::v-deep .v-card {
      .v-card__text {
        padding: 0!important;
      }
    }
    background: #F7FAFC;
    .btn-cost {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      color: #425466;
      .days-60 {
        color: $dark-green;
        text-decoration: underline;
      }
    }
    ::v-deep .tabs .v-tabs .v-item-group {
      background: transparent!important;
    }
    ::v-deep .tabs .v-tabs-items {
      background: transparent!important;
    }
    ::v-deep .tabs .v-window .v-window-item .v-card {
      border-radius: 16px!important;
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0;
      }
      .v-messages {
        display: none;
      }
    }
    .v-icon {
      cursor: pointer;
    }
    .wrap-container {
      column-gap: 1rem;
    }
    .green-btn-color-text {
      color: $dark-green;
    }
    .gray-btn-color-text {
      color: #A0AEC0;
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 0.5rem;
      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #27272E;
        padding-left: 10px;
        margin-top: -20px;
      }
      .time-line-wrap {
        position: relative;
        height: 85px; // 75px;
        padding-top: 14px;
        margin-top: -14px;
        display: flex;
        column-gap: 1rem;
        max-width: calc(100% - 90px);
        width: 87%;
        overflow-x: auto;
        overflow-y: hidden;
        .status-btn {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          position: relative;
          width: 140px; // 112px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          cursor: pointer;
          text-align: center;
          padding: 5px 2px;
          font-family: 'Poppins';
          .status-name {
            font-weight: 500;
            font-size: 12px;
            width: 100%;
          }
          .status-date {
            font-size: 10px;
          }
          .current-status.triangle {
            position: absolute;
            transform: rotate(225deg);
            top: -15px;
            left: 50%;
            box-sizing: border-box;
            width: 10px;
            height: 10px;
            border-top-left-radius: 1px;
            border-right: solid 5px transparent;
            border-bottom: solid 5px transparent;
            border-left: solid 5px $dark-green;
            border-top: solid 5px $dark-green;
          }
          .triangle {
            position: absolute;
            right: -8px;
            transform: rotate(135deg);
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border-top-left-radius: 5px;
            border-right: solid 10px transparent;
            border-bottom: solid 10px transparent;
          }
          .triangle-left {
            position: absolute;
            left: -20px;
            width: 0;
            height: 0;
            border-top: 31px solid #f7fafc;
            border-right: 31px solid transparent;
            transform: rotate(135deg);
          }
        }
        //.status-btn:before {
        //  content: '';
        //  position: absolute;
        //  right: -25px;
        //  width: 0;
        //  height: 0;
        //  transform: rotate(90deg);
        //  border-bottom: 12px solid #000;
        //  border-left: 20px solid transparent;
        //  border-right: 20px solid transparent;
        //}
        .light-grey-bg {
          background-color: #EDF2F7;
        }
        .light-grey-text {
          color: #A0AEC0;
        }
        .green-light-bg {
          background-color: #79d6d4;
        }
        .green-text {
          color: $dark-green;
        }
        .white-text {
          color: #FFFFFF;
        }
        .green-bg {
          background-color: $dark-green;
        }
        .border-left-light-grey {
          border-left: solid 10px #EDF2F7;
        }
        .border-left-light-green {
          border-left: solid 10px #79d6d4;
        }
        .border-left-green {
          border-left: solid 10px $dark-green;
        }
        .border-top-light-grey {
          border-top: solid 10px #EDF2F7;
        }
        .border-top-light-green {
          border-top: solid 10px #79d6d4;
        }
        .border-top-green {
          border-top: solid 10px $dark-green;
        }
      }
      ::-webkit-scrollbar:horizontal {
        height: 15px;
      }
      ::-webkit-scrollbar-track:horizontal {
        background: #F7FAFC;
      }
      ::-webkit-scrollbar-thumb:horizontal {
        //background: #d1eadd;
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #d1eadd;
      }
    }
    .custom-btn-status {
      background-color: #E4EBFE;
      border-radius: 17px;
      padding: 10px 8px;
      cursor: pointer;
    }

    .visit-card-main-data {
      padding: 8px!important;
      border-radius: 16px !important;

      .main-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 0.5rem;
        row-gap: 2rem;

        .full-name-avatar {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.5rem;
          //width: 400px;
          &__full-name {
            width: 180px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #27272E;
          }
        }
        .last-visit {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.2rem;
          width: 295px;
          &__title {
            font-style: normal;
            font-size: 16px;
            color: #718096;
          }
          &__date {
            .btn-date {
              position: relative;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              border: 1px solid $dark-green;
              border-radius: 8px;
              padding: 6px 10px;
              &__text {
                color: #27272E;
                font-weight: 600;
                font-size: 16px;
              }
              .datepicker {
                ::v-deep .v-input {
                  input {
                    user-select: none;
                    cursor: pointer;
                  }
                  }
                }
              }
          }
        }
        .patient-parameters {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0rem;
          &__block {
            width: 70px;
          }
          &__title {
            font-style: normal;
            font-size: 16px;
            color: #718096;
          }
          &__value {
            font-weight: 600;
            color: #27272E;
          }
        }
      }
    }

    .need-product {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .in-active {
      background: #EDF2F7!important;
      color: #A0AEC0!important;
      text-align: center!important;
    }
    .need-survey {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .need-reeval {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .survey-sent {
      background: rgba(208, 220, 255, 0.5);
      color: #668BFF;
      text-align: center!important;
    }
    .survey-due {
      background: rgba(255, 230, 228, 0.7)!important;
      color: #F16063!important;
      text-align: center!important;
    }
    .need-review {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .reviewed {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
      text-align: center!important;
    }
    .in-protocol {
      background: rgba(115, 217, 149, 0.2)!important;
      color: #4FAA6E!important;
      text-align: center!important;
    }
    .completed {
      background:  rgba(115, 217, 149, 0.2)!important;
      color: #4FAA6E!important;
      text-align: center!important;
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
  }
</style>
