<template>
  <div class="results-total">
    <v-card flat v-if="results && results.record" class="mt-4 py-3 pl-3 pr-3">
      <Table hideDefaultFooter>
        <template v-slot:body>
          <tr>
            <td style="width: 300px">
              <div class="cost-title">
                <Img :src="getImgWithExt(['icons', 'purse', 'svg'])" />
                <span style="padding: 0 5px;">Cost</span>
                <span class="days-60">{{results.record.daysSupply}} days</span>
              </div>
            </td>
            <td>
              <span class="label">All Supplements:</span>
              <span class="value">{{results.record.totalPrice ? results.record.totalPrice.toFixed(2) : '0'}}$</span>
            </td>
            <td>
              <span class="label">Daily:</span>
              <span class="value">{{results.record.dailyPrice ? results.record.dailyPrice.toFixed(2) : '0'}}$</span>
            </td>
            <td>
              <span class="label">Weekly:</span>
              <span class="value">{{results.record.weeklyPrice ? results.record.weeklyPrice.toFixed(2) : '0'}}$</span>
            </td>
            <td>
              <span class="label">Monthly:</span>
              <span class="value">{{results.record.monthlyPrice ? results.record.monthlyPrice.toFixed(2) : '0'}}$</span>
            </td>
          </tr>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import Table from '@/components/elements/Table'
import Number from '@/mixins/Number'
import Image from '@/mixins/Image'

export default {
  name: 'results-total',
  components: { Table },
  mixins: [Number, Image],
  computed: {
    results () {
      return this.$store.state?.results?.results
    }
  }
}
</script>

<style scoped lang="scss">
.results-total {
  .cost-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #425466;
    .days-60 {
      color: $dark-green;
      text-decoration: underline;
    }
  }
  .label {
    font-weight: 600;
    font-size: 12px;
    color: #718096;
  }
  .value {
    font-weight: 600;
    font-size: 12px;
    color: #425466;
    padding-left: 5px;
  }
}
</style>
