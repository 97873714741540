import Vue from 'vue'

const state = () => ({
  auth: { isOpen: false, name: '' },
  forgot: { isOpen: false, name: '' },
  registration: { isOpen: false, name: '' },
  addPatient: { isOpen: false, name: '', title: 'Add Patient' },
  editPatient: { id: 0, isOpen: false, name: '', title: 'Edit patient' },
  addVisit: { isOpen: false, name: '', title: 'Add Evaluation' },
  editVisit: { isOpen: false, name: '', title: 'Edit Evaluation' },
  confirm: { isOpen: false, name: '', title: 'Confirm dialog', action: '' },
  modalAddNewBrand: { isOpen: false, name: '', title: '' },
  addSupplementDialog: { isOpen: false, name: '', title: '' },
  editSupplementDialog: { isOpen: false, name: '', title: '' },
  editProtocolDialog: { isOpen: false, name: '', title: 'Edit protocol' },
  addProtocolDialog: { isOpen: false, name: '', title: 'Add protocol' },
  editSupplementProduct: { isOpen: false, name: '', title: '' },
  addResult: { isOpen: false, name: '', title: 'Add result' },
  editResult: { isOpen: false, name: '', title: 'Edit result' },
  inviteDoctors: { isOpen: false, name: '', title: 'Invite Practitioner' },
  openReportPdfView: { isOpen: false, name: '' },
  invitePatient: { isOpen: false, name: '', title: 'Invite Patient to join Portal' },
  uploadAvatar: { isOpen: false, name: '' },
  completeVisit: { isOpen: false, name: '', title: 'Complete Evaluation' },
  notesDialog: { isOpen: false, name: '', title: 'Notes' },
  daysSupplyDialog: { isOpen: false, name: '', title: 'Change Evaluation Supply Days' },
  trialSubscriptionDialog: { isOpen: false, name: '', title: '' },
  quickEntryDialog: { isOpen: false, name: '', title: '' },
  resultGroupSupplementTotals: { isOpen: false, name: '', title: '' },
  operationsWithDoctorDialog: { isOpen: false, name: '', title: '' },
  addSupplementProduct: { isOpen: false, name: '', title: '' },
  modalDesktopDownloadLink: { isOpen: false, name: '', title: '' },
  modalConfirmEmail: { isOpen: false, name: '', title: '' },
  modalChangeEmail: { isOpen: false, name: '', title: '' },
})

const getters = {}

const actions = {}

const mutations = {
  TOGGLE_DIALOG: (state, payload) => {
    Vue.set(state, payload.name, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
