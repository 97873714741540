<template>
  <div class="payments">
    <div class="main-row justify-center">
      <h2 style="text-align: start;width: 100%;color: #27272E;margin-bottom: 10px">Payment History</h2>
      <Table
        :headers="paymentHeaders"
        :items="items"
        @update:option="actionTableData"
        hideDefaultFooter>
        <template v-slot:item:invoiceCreated={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{$moment(item.invoiceCreated).format('MM/DD/YYYY')}}
            </div>
          </div>
        </template>
        <template v-slot:item:paymentMethod={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{item.paymentMethod ? capitalizeFirstLetter(item.paymentMethod) : '-'}}
            </div>
          </div>
        </template>
        <template v-slot:item:status={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{item.status ? capitalizeFirstLetter(item.status) : '-'}}
            </div>
          </div>
        </template>
        <template v-slot:item:productName={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{item.subscription.productName || '-'}}
            </div>
          </div>
        </template>
        <template v-slot:item:invoiceUrl={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              <a style="text-decoration: none" :href="item.invoiceUrl" target="_blank">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="info">mdi-information</v-icon>
                  </template>
                  <span>Information link. Click to icon for view</span>
                </v-tooltip>
              </a>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click.stop="downloadFile(item.invoicePdf)">mdi-file-pdf-box</v-icon>
                </template>
                <span>Click for download pdf file information about invoice</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item:amountPaid={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{item.amountPaid ? `$${item.amountPaid}` : '-'}}
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="footer pl-5 d-flex align-center">
            <div class="row-per-page d-flex align-center flex-grow-1">
              <span class="row-per-page__lable mr-4">Rows per page: </span>
              <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[1]" @change="changeItermPerPage($event, paymentHistory)" />
            </div>
            <div class="flex-grow-2">
              <pagination v-model="page" :value="page" :length="pageCount" :totalVisible="7" @input="changePagination" />
            </div>
            <div class="d-flex align-center justify-end flex-grow-1 mr-6">
            </div>
          </div>
        </template>
<!--        <template v-slot:body>
          <template v-for="item in paymentHistory">
            <tr
              :key="item.id"
              class="custom-result-table__dynamic"
            >
              <td class="px-3 py-1">&lt;!&ndash;:style="{'width': (item.supplementName.length * 8) + 'px'}"&ndash;&gt;
                <div class="d-flex align-center justify-start">
                  <div class="d-flex">
                    {{$moment(item.invoiceCreated).format('MMMM Do YYYY, h:mm:ss a')}}
                  </div>
                </div>
              </td>
              <td class="px-3 py-1">
                <div class="d-flex align-center justify-start">
                  <div class="d-flex">
                    {{item.paymentMethod || '-'}}
                  </div>
                </div>
              </td>
              <td class="px-3 py-1">
                <div class="d-flex align-center justify-start">
                  <div class="d-flex">
                    &lt;!&ndash;                      <status v-if="getStatusCodeName[item.status]"
                                                  :class="getStatusCodeName[item.status].class"
                                                  radius="6">
                                            {{getStatusCodeName[item.status].name}}
                                          </status>&ndash;&gt;
                    {{item.status}}
                  </div>
                </div>
              </td>
              <td class="px-3 py-1">
                <div class="d-flex align-center justify-start">
                  <div class="d-flex">
                    {{item.amountPaid}}
                  </div>
                </div>
              </td>
              <td class="px-3 py-1">
                <div class="d-flex align-center justify-start">
                  <div class="d-flex">
                    {{item.amountDue}}
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>-->

      </Table>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import Table from '@/components/elements/Table'
import Pagination from '@/mixins/Pagination'
import Select from '@/components/elements/Select.vue'
import pagination from '@/components/layouts/pagination'

export default {
  name: 'payments',
  mixins: [Pagination],
  components: { Table, Select, pagination },
  async mounted () {
    const { id } = this.$route?.params
    const res = await this.getPaymentHistory({ id })
    if (res) {
      this.paymentsItems = this.paymentHistory
      this.setupPagination(this.paymentHistory)
    }
  },
  data: () => ({
    paymentsItems: null
  }),
  methods: {
    // ...mapActions('subscriptions', ['getPaymentHistory']),
    ...mapActions('customers', ['getPaymentHistory']),
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async downloadFile (urlFile) {
      const link = document.createElement('a')
      document.getElementsByTagName('body')[0].appendChild(link)
      link.href = urlFile
      link.download = 'Invoice.pdf'
      link.click()
      document.getElementsByTagName('body')[0].removeChild(link)
    },
    actionTableData ($event) {
      if ($event.sortBy.length) {
        this.items = this.items.sort((a, b) => {
          const key = $event.sortBy[0]
          if (key === 'name') {
            if ($event.sortDesc[0]) {
              if (a[key].toLowerCase() < b[key].toLowerCase()) {
                return -1
              }
            } else {
              if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return 1
              }
            }
          } else {
            return $event.sortDesc[0] ? b[key] - a[key] : a[key] - b[key]
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      // paymentHistory: state => state.subscriptions.paymentHistory
      paymentHistory: state => state.customers.paymentHistory
    }),
    paymentHeaders () {
      return [
        {
          text: 'Payment Date',
          value: 'invoiceCreated',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Payment Method',
          value: 'paymentMethod',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Status',
          value: 'status',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Product name',
          value: 'productName',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Invoice',
          value: 'invoiceUrl',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Amount',
          value: 'amountPaid',
          isSlotable: true,
          width: '16.6%',
          isReplacingSpace: 0
        }
      ]
    },
    userProfile () {
      return this.$store.state.userProfile
    },
    rowPerPageItems: () => ([5, 10, 15, 20])
  }
}
</script>

<style scoped lang="scss">
.payments {
  margin-top: 15px;
  padding-bottom: 180px;
  ::v-deep .v-data-table {
    background-color: #f7fafc;
    td {
      color: #425466;
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        //display: none;
      }
      &__control {
        .v-text-field__details {
          display: none!important;
        }
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
}
</style>
