import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import router from '@/router/index'

import dialogs from '@/store/modules/dialogs'
import organizations from '@/store/modules/organizations'
import customers from '@/store/modules/customers'
import visits from '@/store/modules/visits'
import results from '@/store/modules/results'
import supplements from '@/store/modules/supplements'
import surveys from '@/store/modules/surveys'
import chat from '@/store/modules/chat'
import reports from '@/store/modules/reports'
import protocols from '@/store/modules/protocols'
import imports from '@/store/modules/import'
import subscriptions from '@/store/modules/subscriptions'
import brands from '@/store/modules/brands'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messages: null,
    settings: null,
    pageSettings: null,
    userProfile: null,
    organization: null,
    subscription: null,
    isAuth: localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).isAuth : false,
    token: localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).token : '',
    permissions: ['ClinicAdmin', 'Doctor', 'Patient', 'SystemAdmin']
  },
  getters: {
    BUILD_URL_PARAMS: () => (params, isQmark = true) => {
      let paramsStr = (isQmark) ? '?' : ''
      if (params) {
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            let k = key
            if (key === 'limit' || key === 'page' || key === 'offset') {
              k = `pagination[${key}]`
            }
            paramsStr += `${k}=${params[key]}&`
          }
        }
      } else {
        paramsStr = ''
      }

      return paramsStr.substring(paramsStr.length - 1, -1)
    }
  },
  mutations: {
    SAVE_TOKEN: (state, payload) => {
      Vue.set(state, 'token', payload)
    },
    SAVE_PROFILE_DATA: (state, payload) => {
      Vue.set(state, 'userProfile', payload)
    },
    SAVE_ORGANIZATION_DATA: (state, payload) => {
      Vue.set(state, 'organization', payload)
    },
    SAVE_SUBSCRIPTION_DATA: (state, payload) => {
      Vue.set(state, 'subscription', payload)
    },
    UPDATE_DATA: (state, payload) => {
      state[payload.state][payload.key] = payload.value
    },
    SET_DATA: (state, payload) => {
      console.log('MUTATION SET_DATA', payload)
      const [property, value] = payload
      Vue.set(state, property, value)
    },
    SET_PAGE_SETTINGS: (state, payload) => {
      Vue.set(state, 'pageSettings', payload)
    },
    SET_MESSAGE: (state, payload) => {
      Vue.set(state, 'messages', payload)
      setTimeout(() => {
        Vue.set(state, 'messages', null)
      }, 5000)
    }
  },
  actions: {
    async registration ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/Register', { ...params })
      if (result?.status === 200) {
        console.log(`Respons: The user is registerd; Status: ${result?.status}`)
        // router.push('/').catch(() => {})
      }
      return result?.data
    },
    async login ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/Login', { ...params, source: 'ap' })
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        console.log(`Response: The user is authenticated; Status: ${result?.status}`)
        router.push('/').catch(() => {})
      } else {
        return result?.data
      }
    },
    async confirmEmail ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/ConfirmEmail', { ...params })
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result?.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        console.log(`Respons: The email is confirmed; Status: ${result?.status}`)
        router.push('/settings').catch(() => {})
      }
    },
    async logout ({ commit }) {
      const result = await Vue.$http.post('/Auth/Logout')
      if (result?.status === 200) {
        console.log(`Respons: You are logout; Status: ${result?.status}`)
        router.push('/sign-in').catch(() => {})
      }
      commit('SAVE_TOKEN', '')
    },
    async appSettings ({ commit }) {
      const result = await Vue.$http.get('/Helper/AppSettings')
      if (result?.status === 200) {
        commit('SET_DATA', ['settings', result?.data?.record])
      }
    },
    async forgotPassword ({ _ }, email) {
      return new Promise((resolve) => {
        Vue.$http.put('/Auth/ForgotPassword?email=' + email).then((result) => {
          if (result?.status === 200) {
            resolve(result?.status === 200)
            console.log(`Respons: Recovery password request is created: ${result?.status}`)
          }
        })
      })
    },
    async passwordUpdate ({ commit }, params) {
      console.log('passwordUpdate', params)
      const result = await Vue.$http.put('/Auth/PasswordUpdate', params)
      if (result?.status === 200) {
        const { token, userProfile } = result.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        console.log(`Respons: Password was updated: ${result?.status}`)
        router.push('/').catch(() => {})
      }
    },
    async passwordConfirmation ({ _ }, params) {
      const result = await Vue.$http.get('/Auth/PasswordConfirmation?userId=' + params.id + '&token=' + params.token + '&type=' + params.type) // params.type
      if (result?.status === 200) {
        console.log(`Respons: Password was confirmed: ${result?.status}`)
      }
      return result?.data
    }
  },
  modules: {
    organizations,
    supplements,
    customers,
    results,
    surveys,
    visits,
    dialogs,
    chat,
    reports,
    protocols,
    imports,
    subscriptions,
    brands
  },
  plugins: [createPersistedState({ paths: ['token', 'isAuth', 'userProfile', 'organization', 'subscription'] })]
})
