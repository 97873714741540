<template>
  <div class="survey">
    <v-row no-gutters class="d-flex justify-space-between align-center">
      <v-col md5 sm12 style="text-align: right">
        <span class="survey__title" v-if="surveyGroups && selectedGroup !== 'test&complaints'">{{ surveyGroups[selectedGroup].name }}</span>
      </v-col>
      <v-col md7 sm12 style="text-align: right">
        <span class="survey__due-date">Due Date </span>
        <span class="survey__date">{{$moment(dueDate).format('MM/DD/YYYY') || '07/08/2022'}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4" v-if="surveyGroups">
      <v-col cols="12">
        <v-slide-group
          v-model="selectedGroup"
          mandatory
          :show-arrows="false"
        >
          <v-slide-item
            v-for="(group, index) in surveyGroups"
            :key="group.id"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-1 group-button"
              :input-value="active"
              active-class="green-bg white--text"
              depressed
              rounded
              :class="{
                'green-border': group.isCompleted && group.maxAnswerValue === 0,
                'red-border': group.isCompleted && group.maxAnswerValue > 0
              }"
              style="font-weight: 600;font-size: 18px"
              @click="toggle"
            >
              <span :class="{
                'green-text': group.isCompleted && group.maxAnswerValue === 0 && selectedGroup !== index,
                'red-text': group.isCompleted && group.maxAnswerValue > 0 && selectedGroup !== index
              }">
                {{ group.groupNumber }}
              </span>
              <v-icon
                v-if="group.isCompleted && selectedGroup !== index"
                :color="group.maxAnswerValue === 0 ? '#008C89' : group.maxAnswerValue > 0 ? '#F23A4E' : ''"
              >
                mdi-check
              </v-icon>
            </v-btn>
          </v-slide-item>
          <v-slide-item
            v-slot="{ active, toggle }"
            :value="'test&complaints'"
          >
            <v-btn
              class="mx-1 group-button"
              :input-value="active"
              active-class="green-bg white--text"
              depressed
              rounded
              width="250px"
              style="font-weight: 600;font-size: 18px"
              @click="toggle"
            >
              <span style="text-transform: capitalize"><!--:class="{ 'green-text': selectedGroup !== 'test&complaints' }"-->
                Test & Complaints
              </span>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row no-gutters class="align-center mt-4 progress-block justify-center"  v-if="surveyGroups">
      <v-col cols="12" sm="7">
        <span class="mobile-percent">{{ Math.ceil(progress) }}%</span>
        <div class="progress">
          <v-progress-linear
            :value="progress"
            color="#008C89"
            height="6"
            :style="{'border-radius': '10px'}"
          >
          </v-progress-linear>
        </div>
      </v-col>
      <v-col cols="12" sm="2" class="desktop-percent">
        <span>{{ Math.ceil(progress)}}%</span>
      </v-col>
      <v-col cols="12" sm="3" class="submit-btn">
        <Button
          dark
          height="45"
          width="80%"
          btn-bg="dark-green"
          :style="surveyReadOnly ? 'width: 100%' : 'width: 80%'"
          :disabled="!canApprove"
          @click.native="sendSurvey(visit)"
          :styles="{ 'border-radius': '8px' }"
        >
          <span v-if="canApprove">Approve</span>
          <span v-else style="color: grey">View only</span>
        </Button>
        <v-icon style="cursor:pointer;" v-if="!surveyReadOnly" @click.native="loadData">mdi-refresh</v-icon>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <div class="survey-table-desktop-mobile" v-if="surveyGroups && selectedGroup !== 'test&complaints'">
        <v-simple-table>
          <template v-slot:default>
            <thead class="header">
            <tr>
              <th>NONE</th>
              <th>MILD</th>
              <th>MOD</th>
              <th>SEV</th>
              <th>SYMPTOMS</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in surveyQuestions" :key="item.id">
              <td class="dynamic-device">
                <div class="small-device-version mobile-version">NONE</div>
                <div class="survey-value">
                  <v-radio-group
                    :disabled="surveyReadOnly"
                    v-model="item.value"
                    column
                    @change="changeQuestionAnswer(item)"
                  >
                    <v-radio
                      label="0"
                      :value="0"
                    >
                      <template v-slot:label>
                        <div class="circle" :class="{'active-0': item.value === 0}">
                          0
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td class="dynamic-device">
                <div class="small-device-version">MILD</div>
                <div class="survey-value">
                  <v-radio-group
                    :disabled="surveyReadOnly"
                    v-model="item.value"
                    column
                    @change="changeQuestionAnswer(item)"
                  >
                    <v-radio
                      label="1"
                      :value="1"
                    >
                      <template v-slot:label>
                        <div class="circle" :class="{'active-1': item.value === 1}">
                          1
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td class="dynamic-device">
                <div class="small-device-version">MODE</div>
                <div class="survey-value">
                  <v-radio-group
                    :disabled="surveyReadOnly"
                    v-model="item.value"
                    column
                    @change="changeQuestionAnswer(item)"
                  >
                    <v-radio
                      label="2"
                      :value="2"
                    >
                      <template v-slot:label>
                        <div class="circle" :class="{'active-2': item.value === 2}">
                          2
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td class="dynamic-device">
                <div class="small-device-version">SEV</div>
                <div class="survey-value">
                  <v-radio-group
                    :disabled="surveyReadOnly"
                    v-model="item.value"
                    column
                    @change="changeQuestionAnswer(item)"
                  >
                    <v-radio
                      label="3"
                      :value="3"
                    >
                      <template v-slot:label>
                        <div class="circle" :class="{'active-3': item.value === 3}">
                          3
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </div>
              </td>
              <td class="dynamic-device">
                <div class="small-device-version">SYMPTOMS</div>
                <div class="symptoms">
                  {{item.text}}
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="test-complaints-form" v-if="selectedGroup === 'test&complaints'">
        <v-col cols="12">
          <v-form ref="form" lazy-validation class="test-complaints__form mt-3">
            <div class="main-row">
              <div class="test-complaints-column">
                <v-row class="align-center" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                  <v-col cols="10" class="py-0">
                    <div class="label" style="margin-top: 14px;padding-bottom: 19px">5 Main Complaints</div>
                    <!--<Input
                      v-for="(complaint, index) in testComplaints.mainComplaints"
                      :key="index + complaint"
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      :placeholder="index + 1 + '.Complaint'"
                      @input="complaint.value = $event"
                      :value="complaint.value"
                    />-->
<!--   :key="index + complaint"                 -->
                    <Input
                      v-for="(complaint, index) in testComplaints.complaints"
                      :key="index"
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      :placeholder="index + 1 + '.Complaint'"
                      @input="complaint.value = $event"
                      :value="complaint.value"
                    />
                  </v-col>
                  <v-col cols="2" class="align-self-end mb-2">
                    <Button
                      btn-bg="dark-green"
                      width="56"
                      height="56"
                      @click.native="addComplaint"
                      :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                      darkheight="56">
                      <v-icon color="#ffff">mdi-plus</v-icon>
                    </Button>
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                  <v-col cols="12" class="py-0">
                    <div class="label">Blood Pressure</div>
                    <v-radio-group v-model="testComplaints.bloodPressureValue">
                      <v-radio
                        v-for="blood in testComplaints.bloodPressure.values"
                        :key="blood.name"
                        :label="blood.name"
                        :value="blood.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                  <v-col cols="10" class="py-0">
                    <div class="label">Barnes Thyroid test</div>
                    <v-row v-for="(barnesThyroid, index) in testComplaints.barnesThyroid" :key="index">
                      <v-col cols="6">
                        <Datepicker
                          placeholder="MM/DD/YY"
                          height="56"
                          @date:update="barnesThyroid.date = $event;"/>
                      </v-col>
                      <v-col cols="6">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          placeholder="Temperature"
                          background-color="#EDF2F7"
                          @input="barnesThyroid.value = +$event"
                          :value="+barnesThyroid.value"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
<!--                  <v-col cols="2" class="align-self-end mb-2">
                    <Button
                      color="#4FAA6E"
                      width="56"
                      height="56"
                      @click.native="addBarnesThyroidTest"
                      :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                      darkheight="56">
                      <v-icon color="#ffff">mdi-plus</v-icon>
                    </Button>
                  </v-col>-->
                </v-row>
              </div>
              <div class="test-complaints-column">
                <v-row class="align-center pt-3">
                  <v-col cols="12" class="py-0">
                    <span class="other-test-title">Other tests</span>
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Blood pressure: <span class="sub-title">Recumbent</span>
                    </div>
                    <v-row class="align-center">
                      <v-col cols="5">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          background-color="#EDF2F7"
                          @input="testComplaints.posturalBpRecumbent.systolic = $event"
                          :value="testComplaints.posturalBpRecumbent.systolic"
                        />
                      </v-col>
                      <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                        <span class="divider-element">/</span>
                      </v-col>
                      <v-col cols="5">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          background-color="#EDF2F7"
                          @input="testComplaints.posturalBpRecumbent.diastolic = $event"
                          :value="testComplaints.posturalBpRecumbent.diastolic"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Blood pressure: <span class="sub-title">Standing</span>
                    </div>
                    <v-row class="align-center">
                      <v-col cols="5">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          background-color="#EDF2F7"
                          @input="testComplaints.posturalBpStanding.systolic = $event"
                          :value="testComplaints.posturalBpStanding.systolic"
                        />
                      </v-col>
                      <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                        <span class="divider-element">/</span>
                      </v-col>
                      <v-col cols="5">
                        <Input
                          outlined
                          height="56"
                          radius="16"
                          tabindex="1"
                          background-color="#EDF2F7"
                          @input="testComplaints.posturalBpStanding.diastolic = $event"
                          :value="testComplaints.posturalBpStanding.diastolic"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Pulse: <span class="sub-title">Recumbent</span>
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.posturalPulseRecumbent = $event"
                      :value="testComplaints.posturalPulseRecumbent"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Pulse: <span class="sub-title">Standing</span>
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.posturalPulseStanding = $event"
                      :value="testComplaints.posturalPulseStanding"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      H-C Urine readings - pH
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.hemaCombistixUrinePH = $event"
                      :value="testComplaints.hemaCombistixUrinePH"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Albumin %
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.hemaCombistixUrineAlbuminPct = $event"
                      :value="testComplaints.hemaCombistixUrineAlbuminPct"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Glucose %
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.hemaCombistixUrineGlucosePct = $event"
                      :value="testComplaints.hemaCombistixUrineGlucosePct"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Occult blood
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.occultBlood = $event"
                      :value="testComplaints.occultBlood"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      pH of saliva
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.salivaPH = $event"
                      :value="testComplaints.salivaPH"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
              </div>
              <div class="test-complaints-column">
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title" style="margin-top: 35px;">
                      pH of stool
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.stoolPH = $event"
                      :value="testComplaints.stoolPH"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Blood clotting time
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.bloodClottingTime = $event"
                      :value="testComplaints.bloodClottingTime"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Hemoglobin
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.hemoglobin = $event"
                      :value="testComplaints.hemoglobin"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0 blood-type">
                    <div class="title">
                      Blood type
                    </div>
                    <v-slide-group
                      v-model="testComplaints.bloodType"
                      mandatory
                      :show-arrows="false"
                    >
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'A+'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        A+
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'A-'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        A-
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'B+'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        B+
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'B-'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        B-
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'AB+'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        AB+
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'AB-'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        AB-
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'O+'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        O+
                      </span>
                        </v-btn>
                      </v-slide-item>
                      <v-slide-item
                        v-slot="{ active, toggle }"
                        :value="'O-'"
                      >
                        <v-btn
                          class="mx-1 group-button"
                          :input-value="active"
                          active-class="green-bg white--text"
                          depressed
                          rounded
                          style="font-weight: 600;font-size: 18px"
                          @click="toggle"
                        >
                      <span>
                        O-
                      </span>
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="10" class="py-0">
                    <div class="title">
                      Weight
                    </div>
                    <Input
                      outlined
                      height="56"
                      radius="16"
                      tabindex="1"
                      background-color="#EDF2F7"
                      @input="testComplaints.weight = +$event"
                      :value="+testComplaints.weight"
                    />
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="testComplaints.raglanTestPositive"
                      color="#008C89"
                      label="Reglands Test in Positive (drop in excess of 9mm)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="align-center pt-3">
                  <v-col cols="12" class="py-0">
                    <Button
                      dark
                      btn-bg="dark-green"
                      height="55"
                      @click.native="saveTestsComplaints"
                      width="100%">
                  <span style="font-weight: 600;font-size: 12px;line-height: 12px;">
                    Save
                  </span>
                    </Button>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-form>
        </v-col>
      </div>
    </v-row>
    <div class="custom-alert">
      <v-alert
        color="#64bb7a"
        dismissible
        type="success"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">Survey was approved. Survey Maestro calculating results based on the Survey data..</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import Datepicker from '@/components/elements/datepicker'

export default {
  name: 'survey',
  components: { Button, Input, Datepicker },
  data: () => ({
    selectedGroup: 0,
    testComplaints: {
      complaints: [{ value: '' }],
      bloodPressure: {
        values: [{ name: 'Unknown', value: 0 }, { name: 'Below normal for age', value: 1 }, { name: 'Normal for age', value: 2 }, { name: 'Above normal for age', value: 3 }]
      },
      bloodPressureValue: 0,
      barnesThyroid: [
        {
          date: '',
          value: ''
        }
      ],
      posturalBpRecumbent: {
        systolic: '',
        diastolic: ''
      },
      posturalBpStanding: {
        systolic: '',
        diastolic: ''
      },
      posturalPulseRecumbent: '',
      posturalPulseStanding: '',
      hemaCombistixUrinePH: '',
      // exist
      hemaCombistixUrineAlbuminPct: '',
      hemaCombistixUrineGlucosePct: '',
      occultBlood: '',
      salivaPH: '', // salivaPH
      stoolPH: '', // stoolPH
      bloodClottingTime: '',
      bloodType: '',
      hemoglobin: '',
      weight: 0,
      raglanTestPositive: false // raglanTestPositive
      // exist
    },
    alertIsOpen: false
  }),
  mounted () {
    this.loadData()
  },
  methods: {
    loadDataQuestionsByGroupID (index) {
      this.getSurveyGroupByID({ surveyID: this.visit.surveyId, groupID: '?groupId=' + this.surveyGroups[index].id })
    },
    updateUserAnswerInSurvey (surveyGroupID, guestionID, questionValue) {
      const surveyID = this.visit.surveyId
      const data = {
        surveyId: surveyID,
        surveyGroupId: surveyGroupID,
        answers: [
          {
            questionId: guestionID,
            value: questionValue
          }
        ]
      }
      this.updateSurveyAnswer({ surveyID, ...data })
    },
    ...mapActions('surveys', ['getSurveyGroups', 'updateSurveyAnswer', 'getSurveyGroupByID', 'approveSurvey', 'getDetailsTestComplaints', 'updateTestComplaints']),
    async loadData () {
      this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' }).then(() => {
        this.selectedGroup = 0
      })
      const res = await this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
      delete res.complaints.$id
      res.complaints = res.complaints.$values.map(item => {
        return { value: item }
      })
      delete res.complaints.$values
      this.testComplaints = { ...res }
    },
    async sendSurvey (visit) {
      if (visit.status === 'NeedReview' || visit.status === 'SurveySent' || visit.status === 'SurveyDue') {
        const res = await this.approveSurvey({ surveyId: this.visit.surveyId, visitId: this.visit.id })
        this.alertIsOpen = true
        const self = this
        if (res) {
          setTimeout(function () {
            self.$emit('changeTab', { title: 'results', component: 'results' })
          }, 1300)
        }
      }
    },
    changeQuestionAnswer (item) {
      if (!this.surveyReadOnly) {
        this.updateUserAnswerInSurvey(this.surveyGroups[this.selectedGroup].id, item.id, item.value)
      }
    },
    saveTestsComplaints () {
      const data = { ...this.testComplaints }
      data.complaints = data.complaints.map(item => {
        return item.value
      })
      data.bloodPressure = this.testComplaints.bloodPressureValue
      delete data.bloodPressureValue
      data.weight = +data.weight
      this.updateTestComplaints({ surveyId: this.visit.surveyId, data })
    },
    addComplaint () {
      this.testComplaints.complaints.push({ value: '' })
    }
  },
  watch: {
    selectedGroup (val) {
      if (val !== 'test&complaints') {
        this.loadDataQuestionsByGroupID(val)
      }
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits.visit
    }),
    progress () {
      return this.$store.state.surveys.surveyGroups?.progress
    },
    surveyGroups () {
      return this.$store.state.surveys.surveyGroups?.groups?.$values
    },
    surveyReadOnly () {
      return this.$store.state.surveys.surveyGroups?.readonly
    },
    dueDate () {
      return this.$store.state.surveys.surveyGroups?.dueDate
    },
    canApprove () {
      return this.$store.state.surveys.surveyGroups?.canApprove
    },
    currentSelectedGroup () {
      return this.surveyGroups[this.selectedGroup]
    },
    surveyQuestions () {
      return this.currentSelectedGroup.questions?.$values
    },
    detailsComplaints () {
      return this.$store.state.surveys.detailsComplaints
    },
    submitSurveyDisabled () {
      return this.surveyGroups?.every(item => {
        return item.isCompleted === true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.survey {
  padding: 16px!important;
  position: relative;
  &__title {
      font-weight: 600;
      font-size: 22px;
      color: #1A202C;
    }
  &__due-date {
      font-weight: normal;
      font-size: 14px;
      color: #A0AEC0;
    }
  &__date {
      font-weight: 600;
      font-size: 14px;
      color: #1A202C;
    }
  ::v-deep .v-btn.group-button {
    width: 50px;
    height: 50px;
    min-width: 50px!important;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    background-color: transparent !important;
  }
  ::v-deep .v-btn.green-bg {
    background-color: $dark-green !important;
    border: 0px !important;
  }
  ::v-deep .v-slide-group__content .v-icon {
    font-size: 20px !important;
  }
  .progress-block {
    .mobile-percent {
      display: none;
    }
    .desktop-percent {
      text-align: center;
    }
    .submit-btn {
      //text-align: right;
      margin-left: 10px;
      display: flex;

      ::v-deep .v-btn.v-btn--disabled {
        background-color: #EDF2F7 !important;
        cursor: not-allowed !important;
      }
    }
  }
  .survey-table-head-desktop {
    width: 100%;
    position: relative;
    display: block;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .survey-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    //background-color: #F7FAFC;
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        font-weight: 600;
        font-size: 12px;
        color: #CBD5E0;
      }
    }
    .dynamic-device {
      padding: 0 4px !important;
      .small-device-version {
        display: none;
      }
      .symptoms {
        padding-left: 10px;
        color: #1A202C;
        font-weight: 500;
        font-size: 14px;
      }
      .survey-value {
        ::v-deep .v-input--selection-controls__input {
          position: absolute !important;
          visibility: hidden;
        }
        ::v-deep .v-input__control {
          height: 30px !important;
          .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        ::v-deep .v-input--selection-controls {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
        .circle {
          width: 40px;
          height: 40px;
          border: 2px solid #CBD5E0;
          background: rgba(203, 213, 224, 0.2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #CBD5E0;
        }
        .circle.active-0 {
          border-color: $dark-green;
          background-color: $light-dark-green;
          color: #4FAA6E;
        }
        .circle.active-1 {
          border-color: #D4CE15;
          background-color: rgba(212, 206, 21, 0.2);
          color: #D4CE15;
        }
        .circle.active-2 {
          border-color: #FF7A4A;
          background-color: rgba(255, 122, 74, 0.2);
          color: #FF7A4A;
        }
        .circle.active-3 {
          border-color: #F23A4E;
          background-color: rgba(242, 58, 78, 0.2);
          color: #F23A4E;
        }
      }
    }
  }
  .survey-table-mobile {
    display: none;
    width: 100%;
    margin-top: 20px;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  ::v-deep .theme--light.v-data-table {
    background-color: transparent !important;
  }
  ::v-deep .v-data-table .v-data-table__wrapper {
    tr {
      background-color: transparent !important;
    }
    td {
      border-bottom: 0px !important;
    }
    overflow: unset !important;
  }
  .green-text {
    color: $dark-green;
  }
  .red-text {
    color: #F23A4E;
  }
  .green-bg {
    background: $dark-green !important;
  }
  .green-border {
    border: 1px solid $dark-green !important;
  }
  .red-border {
    border: 1px solid #F23A4E !important;
  }

  .test-complaints-form {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    //background-color: #F7FAFC;
    .test-complaints__form {
      .label {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .other-test-title {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        color: #425466;
        .sub-title {
          font-weight: 600;
          font-size: 17px;
        }
      }
      .blood-type {
        ::v-deep .v-slide-group__content {
          display: unset !important;
          flex: unset !important;
          white-space: pre-wrap !important;
          .v-btn {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
          }
        }
      }

      @media screen and (min-width: 1000px) {
        .main-row {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          justify-content: space-between;
          .test-complaints-column {
            width: calc(92% / 3);
          }
          .test-complaints-column:first-child {
            .row:last-child {
              border-bottom: unset!important;
            }
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .main-row {
          .test-complaints-column:nth-child(1) {
            .row:first-child {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 610px){
    .col-sm-7 {
      flex: 0 0 58.6666666667% !important;
      max-width: 58.6666666667% !important;
    }
  }
  @media (min-width: 610px) {
    .col-sm-2 {
      flex: 0 0 8.3333333333% !important;
      max-width: 8.3333333333% !important;
    }
  }
  @media (min-width: 610px) {
    .col-sm-3 {
      flex: 0 0 28.3333333333% !important;
      max-width: 28.3333333333% !important;
    }
  }
  @media (max-width: 610px) {
    .progress-block {
      .submit-btn {
        text-align: center!important;
        margin-left: 0 !important;
        margin-top: 6px;
      }
    }
  }
  @media (max-width: 610px) {
    .desktop-percent {
      display: none !important;
    }
    .progress-block {
      .mobile-percent {
        display: block;
        text-align: center;
      }
    }
  }

  @media (max-width: 590px) {
    .survey-table-desktop-mobile {
      .header {
        display: none !important;
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .small-device-version {
          display: block;
        }
      }
    }
  }
  @media (min-width: 530px) {
    .survey-table-desktop-mobile {
      .header {
        th {
          width: 60px;
        }
        th:nth-child(4) {
          width: 100px;
        }
        th:last-child {
          width: auto;
        }
      }
    }
  }
  .custom-alert {
    position: fixed;
    top: 50%;
    width: 400px;
    margin-left: -200px;
    left: 50%;
  }
}
</style>
