<template>
  <div class="tabs" :class="withoutPadding">
    <v-tabs v-model="tab" v-bind="$props" :arrow-show="false" :class="{'is-disabled': isDisabled}">
      <slot name="sliderColor"/>
      <v-tab v-for="(item, i) in items" :key="i">{{item.title}}</v-tab>
      <slot name="buttonControlsForAllTabs"></slot>
    </v-tabs>
<!--    <br>-->
    <div class="divider" :style="{'height': dividerHeight}"></div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) in items" :key="i">
        <v-card flat :class="{'is-disabled': isDisabled, 'hidden-bg': isHiddenBg}">
          <v-card-text>
            <slot :name="_.toLower(item.component)" />
          </v-card-text>
        </v-card>
        <slot name="totalResult"></slot>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'tabs',
  props: {
    items: { type: Array },
    tabIndex: {
      type: [Number, String]
    },
    isDisabled: [Boolean],
    isHiddenBg: [Boolean],
    fixedTabs: Boolean,
    color: String,
    centered: Boolean,
    withoutPadding: String,
    dividerHeight: String
  },
  mounted () {
    this.tab = parseFloat(this.tabIndex) || 0
  },
  data: () => ({
    tab: null
  }),
  watch: {
    tab (val) {
      this.$emit('update:tab', val)
    },
    tabIndex (val) {
      this.tab = val
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    ::v-deep .v-tab {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #CBD5E0 !important;
      text-transform: capitalize;
      ::v-deep .v-window-item {
        background: red!important;
      }
      &--active {
        color: #27272E !important;
      }
      &::before {
        opacity: 0 !important;
      }
    }
    ::v-deep .v-slide-group {
      &__content {
        text-transform: capitalize;
      }
    }
    .v-ripple {
      &__container {
        display: none !important;
      }
    }
  }
  ::v-deep .v-card.is-disabled {
    background: #f7fafc;
  }
  ::v-deep .v-card.hidden-bg {
    background: transparent!important;
  }
  ::v-deep .v-tabs.is-disabled {
    .v-tabs-bar {
      background: #f7fafc!important;
    }
  }
  .chat-without-padding {
    ::v-deep .v-window {
      .v-card {
        padding: 0;
        &__text {
          padding: 0;
        }
      }
    }
  }
</style>
