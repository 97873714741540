<template>
  <div class="login-form">
    <div class="logo flex-grow-1 d-flex align-center mt-4" style="column-gap: 1rem;">
      <img :src="responseResult.logoFull" width="100" style="border-radius: 50%;" />
      <div class="login-form__practice-name-title" v-if="responseResult">
        <h2>{{responseResult.practiceName}}</h2>
      </div>
    </div>
    <br>
    <div class="flex-grow-1 d-flex align-center">
      <p class="login-form__dynamic-text" v-if="responseResult">
        <span>{{responseResult.message}}</span>
      </p>
    </div>
    <br>
    <v-form ref="form" v-model="valid" lazy-validation id="authForm">
      <v-row>
        <v-col class="py-0" style="position: relative">
          <div class="label">Password</div>
          <Input input-class="registration"
                 outlined
                 height="56"
                 radius="16"
                 tabindex="2"
                 background-color="#EDF2F7"
                 placeholder="Password"
                 type="password"
                 :rules="confirmFormPwdRules"
                 @input="form.password=$event"
                 @focus="showPasswordRequirements = true"
                 @blur="showPasswordRequirements = false"
                 @enter="preOnSubmit"
          />
          <div class="pwd-requirements-prompt">
            <password-requirements
              v-show="showPasswordRequirements"
              :verifiablePassword="form.password"
              :min-pwd-length="8"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="label">Confirm Password</div>
          <Input input-class="registration"
                 outlined
                 height="56"
                 radius="16"
                 tabindex="3"
                 background-color="#EDF2F7"
                 placeholder="Confirm password"
                 type="password"
                 :rules="confirmFormCfmPwdRules"
                 @input="form.confirmPassword=$event"
                 @enter="preOnSubmit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="">
            <Button block dark btn-bg="dark-green" tabindex="3" height="56" @click.native="preOnSubmit">Submit</Button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex justify-center">Already have an account? <Link class="ml-2" to="/sign-in">Sign in</Link></div>
        </v-col>
      </v-row>
    </v-form>
    <br>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Validation from '@/mixins/Validation'

import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'

import { MIN_PASS_LENGTH } from '@/constants/const'
import Image from '@/mixins/Image'
import Link from '@/components/elements/Link'
import PasswordRequirements from '@/components/elements/PasswordRequirements'

export default {
  props: {
    responseResult: {
      type: Object,
      default: null
    }
  },
  name: 'confirm-form-from-email',
  components: { Button, Input, Link, PasswordRequirements },
  mixins: [Validation, Image],
  data: () => ({
    form: {
      password: '',
      confirmPassword: ''
    },
    showPasswordRequirements: false
  }),
  methods: {
    ...mapActions(['passwordUpdate']),
    preOnSubmit () {
      this.setDynamicValidationRules({
        confirmFormPwdRules: [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`],
        confirmFormCfmPwdRules: [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      })
      this.$nextTick(() => {
        this.onSubmit()
      }, 200)
    },
    onSubmit () {
      console.log('onSubmit')
      const { id, token, type } = this.$route.params // , type
      const data = {
        userId: id,
        token: token,
        type: type,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword
      }
      this.passwordUpdate(data)
    }
  },
  watch: {
    'form.password' (val) {
      if (!val) this.confirmFormPwdRules = []
      if (val) {
        this.confirmFormPwdRules = [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
        ]
      }
    },
    'form.confirmPassword' (val) {
      if (!val) this.confirmFormCfmPwdRules = []
      if (val) {
        this.confirmFormCfmPwdRules = [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  font-size: 14px;
  font-weight: 500;
  color: #425466
}
.login-form {
  width: 100%;
  &__practice-name-title {
    font-weight: 500;
    font-size: 21px;
  }
  &__dynamic-text {
    font-weight: 500;
    font-size: 21px;
  }
  &__sign-up-link,
  &__forgot-link {
    font-weight: 400;
    font-size: 16px;
    color: #72767F;
    white-space: nowrap;
  }
  .pwd-requirements-prompt {
    position: absolute;
    z-index: 4;
    right: -458px;
    top: -75px;
  }
}
::v-deep .v-text-field__details {
  padding-top: 5px !important;
}
</style>
