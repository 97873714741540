<template>
  <div class="quick-filter d-flex justify-center">
    <div class="qickfilter__btns d-flex">
      <btn-group :btns="items" @update:btn="index=$event" group />
    </div>
  </div>
</template>

<script>
import BtnGroup from '@/components/layouts/btn-group'

export default {
  name: 'quick-filter',
  props: {
    items: {
      type: Array
    }
  },
  components: { BtnGroup },
  data: () => ({
    index: 0
  })
}
</script>

<style lang="scss" scoped>
  .quick-filter {
    ::v-deep .btn-group {
      .v-btn {
        &__content {
          text-transform: none;
        }
        &::before {
          background-color: #fff;
          opacity: 1;
        }
      }
      .v-item {
        font-weight: 600;
        font-size: 14px;
        color: #718096;
        &--active {
          color: #27272E;
          font-weight: 600;
          &::before {
            background-color: #fff;
            border-bottom: 1px solid #4FAA6E;
            position: absolute;
            top: 40px;
          }
        }
      }
    }
  }
</style>
