<template>
  <div class="tab-settings">
    <div class="wrapper" v-if="settingFilters && settingFilters.length > 0 && Object.keys(form).length">
      <div>
        <template v-for="setting in settingFilters">
          <div class="item-setting" :key="setting.id"><!--style="width: 49%;height: fit-content"-->
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="1"
            >
              <v-card-text>
                <div class="main-row">
                  <div class="setting-title">{{setting.settingName}}</div>
                </div>
                <div v-for="filter in setting.filters" :key="filter.componentName">
                  <component
                    :is="filter.componentName"
                    :title="filter.name"
                    :form="form"
                    v-model="loadParams"></component>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <v-row>
      <v-col class="d-flex flex-row justify-center" :class="{'justify-center': settingFilters.length === 1, 'justify-end': settingFilters.length > 1 }" :style="{ 'margin-left': settingFilters.length === 1 ? ' -50px': ''}">
        <Button height="45"
                width="100"
                btn-bg="dark-green"
                dark
                :styles="{ 'border-radius': '8px' }"
                @click.native="update">Update</Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '@/components/elements/Button'

export default {
  name: 'tabSettings',
  components: {
    Button,
    patientReceipt: () => import('@/views/setup/tabSettingFilters/patientReceipt.vue')
  },
  async mounted () {
    this.appSettings()
    const res = await this.getSettingFilters()
    if (res) {
      const { doctorSettings, organizationSettings } = this.settingFiltersStore
      this.form = Object.assign({}, { doctorSettings, organizationSettings })
    }
  },
  data: () => ({
    settingFilters: [
      {
        id: 1,
        settingName: 'Patient receipt',
        filters: [
          {
            componentName: 'patientReceipt',
            name: ''
          }
        ]
      }
    ],
    loadParams: {},
    form: {}
  }),
  methods: {
    ...mapActions(['appSettings']),
    ...mapActions('organizations', ['getSettingFilters', 'updateSettingFilters']),
    update () {
      this.updateSettingFilters(this.form)
    }
  },
  computed: {
    ...mapState({
      settingFiltersStore: state => state.organizations.settingFilters?.record
    })
  },
  watch: {
    loadParams (val) {
      this.form = Object.assign({ ...val })
    }
  }
}
</script>

<style scoped lang="scss">
.tab-settings {
  padding-bottom: 35px;
  background-color: #F7FAFC;
  height: 100vh;
  width: 100%;
  .item-setting {
    ::v-deep .v-card > .v-card__text {
        padding: 16px!important;
    }
  }
  .wrapper {
    /*display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    height: auto;*/
    column-count: 2;
    column-gap: 10px;
    .item-setting {
      margin: 10px;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
    }
  }
  .loading {
    min-height: 65vh;
    ::v-deep .v-progress-circular {
      position: absolute;
      top: 50%;
    }
  }
  ::v-deep .v-card {
    background: white!important;
    flex-wrap: wrap!important;
    width: 100%!important;
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
    .setting-title {
      font-weight: 600;
      font-size: 20px;
      color: #16192C;
    }
  }
}
</style>
