<template>
  <div class="blood">
  </div>
</template>

<script>
export default {
  name: 'blood'
}
</script>

<style lang="scss" scoped>
  .blood {
    // ..
  }
</style>
