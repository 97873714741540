<template>
  <div class="customers">
    <v-container fluid>
      <v-row>
        <v-col class="px-10 pb-0" cols="12">
          <div class="d-flex">
            <div class="customers__total d-flex align-center mr-2">
              <Button class="mr-6"
                      btn-bg="dark-green"
              >
                <span style="color: #FFFFFF">
                  Upload CSV
                </span>
              </Button>
              <input type="file"
                     accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                     class="upload-file"
                     @change="uploadCsvFile"
                     ref="fileCSV">
              <template v-if="customersItems">
                <span class="num mr-2">{{customersItems.length}}</span> Customers
              </template>
            </div>
            <div class="d-flex align-center flex-grow-1 justify-end" v-if="customersItems">
              <Input class="mr-2"
                     style="margin-top: 17px"
                     outlined
                     radius="8"
                     appendIcon="mdi-magnify"
                     placeholder="Search"
                     @input="inputSearch"
                     :value="searchedText"
              />
            </div>
            <div class="d-flex align-center mr-2">
              <Button class="mr-6"
                      btn-bg="dark-green"
                      @click.native="openConfirmEmail"
              >
                <span style="color: #FFFFFF">
                  Confirm Email
                </span>
              </Button>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-10 pb-10 pr-10">
          <template v-if="!startLoading && !customersItems">
            <div class="d-flex justify-center align-center" style="height: 300px;font-size: 17px;font-weight: 600;">No Data</div>
          </template>
          <template v-else>
            <Table v-if="customersItems" class="patient-table"
                   hide-default-footer
                   :headers="headerCustomers"
                   :items="items"
                   @update:option="actionTableData"
                   @click:action="openDialog($event)">

              <template v-slot:item:status={item}>
                <div class="d-flex">
                  <v-icon color="#008C89" v-if="item.status === 'Success'">mdi-check-circle</v-icon>
                  <v-icon color="#CF4655" v-if="item.status === 'Error'">mdi-close-circle</v-icon>
                  <v-icon color="#c5ba59" v-if="item.status === 'Warning'">mdi-alert-circle</v-icon>
                </div>
              </template>

              <template v-slot:item:statusMessage={item}>
                <div class="d-flex">
                  <span>{{item.statusMessage}}</span>
                </div>
              </template>

              <template v-slot:item:organizationName={item}>
                <div class="d-flex" @click.stop="openPatientDetails(item.id)">
                  <v-avatar class="patient-table__icon mr-2" size="40">
                    <img v-if="item.icon" :src="item.icon" :alt="item.name" />
                  </v-avatar>
                  <div class="patient-table__name d-flex align-center">
                    <span class="font-weight-bold" v-if="!searchedText">{{item.organizationName }}</span>
                    <span class="font-weight-bold" v-else v-html="highlightSearchedText(item.organizationName, searchedText)"></span>
                  </div>
                </div>
              </template>

              <template v-slot:item:isCustomerAdded={item}>
                <div class="d-flex">
                  <v-icon color="#008C89" v-if="item.isCustomerAdded">mdi-check-circle</v-icon>
                  <v-icon color="#CF4655" v-else>mdi-close-circle</v-icon>
                </div>
              </template>

              <template v-slot:item:isOrganizationAdded={item}>
                <div class="d-flex">
                  <v-icon color="#008C89" v-if="item.isOrganizationAdded">mdi-check-circle</v-icon>
                  <v-icon color="#CF4655" v-else>mdi-close-circle</v-icon>
                </div>
              </template>

              <template v-slot:item:subscriptionPeriodEnd={item}>
                <div class="d-flex">
                  <span>{{item.subscriptionPeriodEnd ? $moment(item.subscriptionPeriodEnd).format('MM/DD/YYYY') : '-'}}</span>
                </div>
              </template>

              <template v-slot:item:organizationSerialNumber={item}>
                <div class="d-flex">
                  <span>{{item.organizationSerialNumber}}</span>
                </div>
              </template>

              <template v-slot:item:stripeCustomerId={item}>
                <div class="d-flex">
                  <span>{{item.stripeCustomerId}}</span>
                </div>
              </template>

              <template v-slot:footer>
                <div class="footer pl-5 d-flex align-center">
                  <div class="row-per-page d-flex align-center flex-grow-1">
                    <span class="row-per-page__lable mr-4">Rows per page: </span>
                    <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[1]" @change="changeItermPerPage($event, customersItems)" />
                  </div>
                  <div class="flex-grow-2">
                    <pagination v-model="page" :value="page" :length="pageCount" :totalVisible="7" @input="changePagination" />
                  </div>
                  <div class="d-flex align-center justify-end flex-grow-1 mr-6">
                  </div>
                </div>
              </template>
            </Table>
            <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
              <v-progress-circular
                indeterminate
                color="#008C89"
              ></v-progress-circular>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <confirm-new-customer-email v-if="dialogs.modalConfirmEmail.isOpen" @showPopup="showPopup"/>
    <confirm :query="query" v-if="dialogs.confirm.isOpen" />
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import Table from '@/components/elements/Table'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import pagination from '@/components/layouts/pagination'

import confirm from '@/components/layouts/dialogs/confirm-dialog'

import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
import Select from '@/components/elements/Select.vue'
import ConfirmNewCustomerEmail from "@/components/layouts/dialogs/confirm-new-customer-email.vue";

export default {
  name: 'Customers',
  mixins: [Pagination, Mapping],
  components: {ConfirmNewCustomerEmail, Table, Button, Input, confirm, Select, pagination },
  data: () => ({
    startLoading: false,
    customersItems: null,
    searchedText: '',
    popupMessage: null,
    newPatientLink: null,
    alertIsOpen: false
  }),
  mounted () {
    this.appSettings()
    this.pageSize = 10
  },
  watch: {
    customers (val) {
      if (val) {
        this.query.followUpDateFrom = val.followUpDateFrom
        this.query.followUpDateTo = val.followUpDateTo
      }
    },
    '$route.name' (val) {
      if (val === 'Customers') {
        this.$forceUpdate()
      }
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('imports', ['importCSV']),
    ...mapActions(['appSettings']),
    async uploadCsvFile (event) {
      this.startLoading = true
      const file = event.target.files[0]
      if (file.name.split('.').pop() === 'csv') {
        const formData = new FormData()
        formData.append('file', file)
        const result = await this.importCSV({ data: formData, url: 'organization/import-customers-from-csv' })
        if (result.data.$values) {
          this.customersItems = result.data.$values
          this.setupPagination(this.customersItems)
          this.startLoading = false
          this.$refs.fileCSV.value = null
        }
      } else {
        const errorMsg = {
          type: 'error',
          errorMessage: 'The uploaded file must be of the CSV type.'
        }
        this.startLoading = false
        this.showPopup(errorMsg)
        this.$refs.fileCSV.value = null
      }
    },
    actionTableData ($event) {
      if ($event.sortBy.length) {
        this.items = this.items.sort((a, b) => {
          const key = $event.sortBy[0]
          if (key === 'name') {
            if ($event.sortDesc[0]) {
              if (a[key].toLowerCase() < b[key].toLowerCase()) {
                return -1
              }
            } else {
              if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return 1
              }
            }
          } else {
            return $event.sortDesc[0] ? b[key] - a[key] : a[key] - b[key]
          }
        })
      }
    },
    showPopup (res) {
      this.alertIsOpen = true
      switch (res.type) {
        case 'add-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was created  successfully. You can see at `
          this.newPatientLink = res.data
          break
        case 'edit-patient':
          this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
          break
        case 'add-visit':
          this.popupMessage = 'Evaluation date was added. The invitation was sent to Patient Email.'
          break
        case 'invite-patient':
          this.popupMessage = 'The Invitiation Email was sent to the Patient. If Patient will confirm the registration he will be able to join the Patient Portal and fill in the Survey(s).'
          break
        case 'confirm-email':
          this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: false })
          this.popupMessage = 'Email was confirmed successfully.'
          break
        case 'error':
          this.popupMessage = res.errorMessage || 'Occurred some error.'
          break
      }
      if (res.type === 'edit-patient') {
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
      }
      if (res.type === 'invite-patient') {
        this.TOGGLE_DIALOG({ name: 'invitePatient', isOpen: false })
      }
    },
    inputSearch ($event) {
      this.searchedText = $event
      if (this.searchedText) {
        this.items = this.items.filter(item => {
          return item.organizationName.includes(this.searchedText)
        })
      } else if (this.searchedText === '') {
        this.items = this.copiedItems
      }
    },
    highlightSearchedText (currentText, searchedText) {
      if (currentText.toLowerCase().includes(searchedText.toLowerCase())) {
        const newStr = currentText.toLowerCase().replace(searchedText.toLowerCase(), `<span style="color: #7cdbc1;">${searchedText}</span>`)
        return newStr
      }
      return currentText
    },
    openPatientDetails (id) {
      this.$router.push({ name: 'View customer', params: { id } })
    },
    openDialog ($event) {
      switch ($event.action.type) {
        case 0:
          this.$router.push({ path: `customers/${$event.id}` })
          break
        case 1:
          this.TOGGLE_DIALOG({ id: $event.id, name: 'editPatient', isOpen: true, title: $event.action.name })
          break
        case 2:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'customers/deactivatePatient',
            afterAction: { name: 'customers/getCustomers' },
            title: 'Confirm deactivating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> deactivation. If Patient will be marked as Inactive you will not see him in Recent or Follow Up areas and patient will not be able to access the Patient Portal if registered.<br>Are you sure want to deactivate the Patient?`
          })
          break
        case 3:
          this.TOGGLE_DIALOG({ id: $event.id, name: 'invitePatient', isOpen: true, title: $event.action.name, data: $event.data })
          break
        case 4:
          this.TOGGLE_DIALOG({
            id: $event.id,
            name: 'confirm',
            isOpen: true,
            action: 'customers/activatePatient',
            afterAction: { name: 'customers/getCustomers' },
            title: 'Confirm activating the Patient',
            description: `Please confirm Patient <b>${$event.data.firstName} ${$event.data.lastName}</b> activation.<br>Are you sure want to activate the Patient?`
          })
          break
      }
    },
    openConfirmEmail () {
      this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: true, title: 'Confirm email manually', data: 'Customer email', type: 'confirmEmail' })
    }
  },
  computed: {
    headerCustomers () {
      return [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          isSlotable: true
        },
        {
          text: 'Status Message',
          value: 'statusMessage',
          sortable: false,
          isSlotable: true
        },
        {
          text: 'Organization Name',
          value: 'organizationName',
          isSlotable: true,
          sortable: true
        },
        {
          text: 'Customer Added',
          value: 'isCustomerAdded',
          isSlotable: true
        },
        {
          text: 'Organization Added',
          value: 'isOrganizationAdded',
          sortable: false,
          isSlotable: true
        },
        {
          text: 'Subscription Period End',
          value: 'subscriptionPeriodEnd',
          sortable: false,
          isSlotable: true
        },
        {
          text: 'Organization Serial Number',
          value: 'organizationSerialNumber',
          sortable: true,
          isSlotable: true
        },
        {
          text: 'Stripe Customer Id',
          value: 'stripeCustomerId',
          sortable: false,
          isSlotable: true
        }
      ]
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    rowPerPageItems: () => ([5, 10, 15, 20])
  }
}
</script>

<style lang="scss" scoped>
.customers {
  background-color: #F7FAFC;
  height: 100vh;
  .upload-file {
    position: absolute;
    opacity: 0;
    width: 113px;
  }
  input[type='file'] {
    font-size: 0;
    cursor: pointer!important;
  }
  ::v-deep .v-btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
  .next-visit {
    &__add {
      ::v-deep .v-btn {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: $dark-green;
        border: 1px solid $dark-green !important;
      }
    }
    &__exist {
      max-width: 145px;
      .link {
        width: auto;
        padding-left: 14px;
        font-weight: 400;
      }
      &:hover {
        background: #F7FAFC;
        border-radius: 8px;
        padding: 4px 0;
      }
    }
  }
  &__quick-filter {
    background-color: #FFF;
    border-bottom: 1px solid #EDF2F7;
  }
  &__total {
    font-size: 20px;
    font-weight: 600;
    span.num {
      color: $dark-green;
    }
  }
  .v-icon {
    cursor: pointer;
  }
  ::v-deep .v-btn {
    border-radius: 8px;
    &__content {
      font-weight: 600;
    }
  }
  ::v-deep .v-text-field {
    margin: 0;
    padding: 0;
    &__details {
      //display: none;
      margin-bottom: 4px;
    }
  }
  .patient-table {
    &__name {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        //display: none;
      }
      &__control {
        .v-text-field__details {
          display: none!important;
        }
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
  .custom-alert {
    max-width: 350px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
  .patient-is-registered {
    border-radius: 50%;
    border: 2px solid green;
  }

  ::v-deep .status {
    .v-chip {
      cursor: pointer!important;
      background: unset!important;
      color: unset!important;
      width: -webkit-fill-available!important;
      justify-content: center!important;
    }
  }
  .need-product {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .in-active {
    background: #EDF2F7!important;
    color: #A0AEC0!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-survey {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-reeval {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .survey-sent {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .survey-due {
    background: rgba(255, 230, 228, 0.7)!important;
    color: #F16063!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-review {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .reviewed {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .in-protocol {
    background: rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .completed {
    background:  rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
}
</style>
