<template>
  <div class="confirmation-email">
    <Dialog width="500px">
      <template slot="title">
        <div class="title">
          {{modalConfirmEmail.title}}
        </div>
      </template>

      <template slot="body">
        <div class="description" v-html="modalConfirmEmail.data"></div>
        <Input outlined
               v-if="modalConfirmEmail.type === 'confirmEmail'"
               radius="8"
               appendIcon="mdi-email-outline"
               placeholder="Email"
               v-model="value"
        />
        <Input outlined
               v-if="modalConfirmEmail.type === 'updatePassword'"
               radius="8"
               type="password"
               appendIcon="mdi-lock-outline"
               placeholder="Password"
               v-model="value"
        />
        <Input outlined
               v-if="modalConfirmEmail.type === 'changeEmail'"
               radius="8"
               appendIcon="mdi-email-outline"
               placeholder="Email"
               v-model="value"
        />
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Close</span>
          </Button>
          <Button
            dark
            color="#008C89"
            @click.native="confirm"
            :styles="{ 'border-radius': '8px' }"
          >
            <span>Confirm</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'

export default {
  name: 'confirm-new-customer-email',
  components: { Dialog, Button, Input }, // confirm
  data: () => ({
    value: null,
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('organizations', ['sendConfirmEmail', 'sendPasswordUpdate', 'sendChangeEmail']),
    close () {
      this.TOGGLE_DIALOG({ name: 'modalConfirmEmail', isOpen: false })
    },
    async confirm () {
      let res
      switch (this.modalConfirmEmail.type) {
        case 'confirmEmail':
          res = await this.sendConfirmEmail({ email: this.value })
          if (res) {
            this.$emit('showPopup', { type: 'confirm-email', data: res })
          } else {
            this.$emit('showPopup', { type: 'error', data: res })
          }
          break
        case 'updatePassword':
          res = await this.sendPasswordUpdate({ organizationid: this.modalConfirmEmail.organizationid, password: this.value })
          if (res) {
            this.$emit('showPopup', { type: 'update-password', data: res })
          } else {
            this.$emit('showPopup', { type: 'error', data: res })
          }
          break
        case 'changeEmail':
          res = await this.sendChangeEmail({ organizationid: this.modalConfirmEmail.organizationid, email: this.value })
          if (res) {
            this.$emit('showPopup', { type: 'change-email', data: res })
          } else {
            this.$emit('showPopup', { type: 'error', data: res })
          }
          break
      }
      this.close()
    }
  },
  computed: {
    ...mapState({
      modalConfirmEmail: state => state.dialogs.modalConfirmEmail
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.confirmation-email {
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .input {
          padding: 0 24px 10px!important;
        }
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
      }
    }
  }
}
</style>
